import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { ISubscription } from 'models/suscriptions.model'
import { useSelector } from 'react-redux'
import { selectCurrencySign } from 'root-redux/payment/payment-selects'

interface IProps {
  selectedSubscription: ISubscription
}

export const FooterPriceText: React.FC<IProps> = ({ selectedSubscription }) => {
  const currency = useSelector(selectCurrencySign)

  const periodQuantity = useMemo(
    () =>
      selectedSubscription?.trialPrices.durationDays === 7
        ? 1
        : selectedSubscription?.trialPrices.durationDays / 30,
    [selectedSubscription],
  )

  const formattedPeriodName = useMemo(
    () => (periodQuantity > 1 ? 'months' : 'month'),
    [periodQuantity],
  )

  return (
    <Trans
      i18nKey={
        selectedSubscription?.trialPrices.durationDays <= 7
          ? 'subscription.clearDescription.withoutCancellationTrialMonth'
          : 'subscription.clearDescription.withoutCancellationMonth'
      }
      values={{
        formattedPeriod: periodQuantity,
        formattedPeriodName,
        currency,
        fullPrice: selectedSubscription?.mainPrices.fullPrice,
      }}
      components={[<strong />]}
    />
  )
}
