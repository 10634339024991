import React from 'react'
import guaranteeImage from 'assets/images/cat-guarantee.png'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { PRIVACY_POLICY_LINKS, TERMS_OF_USE_LINKS } from 'root-constants'
import { selectCurrentYear } from 'root-redux/common/common-selects'
import { useSelector } from 'react-redux'
import { StyledGuarantee as S } from './Guarantee.styles'

export const GuaranteeCs: React.FC = () => {
  const env = getCurrentEnv()
  const currentYear = useSelector(selectCurrentYear)

  return (
    <S.Wrapper>
      <S.CatImage src={guaranteeImage} />
      <S.Title>
        100% money-back
        <br /> guarantee
      </S.Title>
      <S.Text2>
        We are confident in the quality of our goods — many <br />
        clients around the world have already made their <br />
        feline friends happy. If you have any concerns, we are <br />
        prepared to offer a refund.
      </S.Text2>
      <S.PrivacyNote>
        By continuing you agree to&nbsp;
        <S.InnerLink2 target="_blank" to={TERMS_OF_USE_LINKS[env]}>
          Terms of Use
        </S.InnerLink2>
        &nbsp;and&nbsp;
        <S.InnerLink2 target="_blank" to={PRIVACY_POLICY_LINKS[env]}>
          Privacy Policy
        </S.InnerLink2>
        &nbsp;
        <br />
        {currentYear} © All rights reserved.
      </S.PrivacyNote>
    </S.Wrapper>
  )
}
