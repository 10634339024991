import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledComment as S } from './Comment.styles'

interface ICpmmentProps {
  title: string
  autor: string
  image: string
  children: React.ReactNode
}

export const CommentVariant2: React.FC<
  React.PropsWithChildren<ICpmmentProps>
> = ({ image, title, autor, children }) => {
  const { t } = useTranslation()

  return (
    <S.CommentWrapper2>
      <S.HeaderWrapper2>
        <S.FlagWrapper>
          <S.Avatar src={image} />
        </S.FlagWrapper>
        <S.TitleWrapper>
          <S.Title2>{t(title)}</S.Title2>
          <S.DetailsWrapper>
            <S.Rating />
            <S.Autor>{t(autor)}</S.Autor>
          </S.DetailsWrapper>
        </S.TitleWrapper>
      </S.HeaderWrapper2>
      <S.Text2>{children}</S.Text2>
    </S.CommentWrapper2>
  )
}
