import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GENERAL_QUESTIONS_OPTIONS_VALUES } from 'root-constants'
import catAttack from 'assets/images/cat-playtime-attack.png'
import { PageId } from 'constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'How often does your cat attack you during playtime?'

const OPTION_ANSWERS = [
  {
    answer: 'Often aggressive, draws blood',
    value: GENERAL_QUESTIONS_OPTIONS_VALUES.OFTEN,
  },
  {
    answer: 'Sometimes bites or scratches',
    value: GENERAL_QUESTIONS_OPTIONS_VALUES.SOMETIMES,
  },
  {
    answer: 'Never, plays gently',
    value: GENERAL_QUESTIONS_OPTIONS_VALUES.NEVER,
  },
]

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId],
  )

  return (
    <S.Wrapper4>
      <S.Content4>
        <S.Title7>{QUESTION}</S.Title7>
        <S.QuestionImage src={catAttack} alt="agressive cat" />
        <S.OptionsContainer4 data-is-multiple>
          {OPTION_ANSWERS.map(({ answer, value }) => (
            <S.Option3 key={value} {...optionProps} value={value}>
              <S.QuestionButton3>{answer}</S.QuestionButton3>
            </S.Option3>
          ))}
        </S.OptionsContainer4>
      </S.Content4>
    </S.Wrapper4>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.PLAYTIME_ATTACK

export const PlaytimeAttack = Component
