import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledComponents as S } from './styles'

export const PrivacyNoteVariant2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Note2>
      <S.LockImg2 />
      <S.Description2>{t`commonComponents.privacyProtect`}</S.Description2>
    </S.Note2>
  )
}
