import React from 'react'
import Arrow from 'assets/images/sprite/white-right-arrow.svg'
import { useTranslation } from 'react-i18next'
import { StyledComponents as S } from './ArrowButton.styles'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'

type TProps = {
  onClick: () => void
  isButtonDisabled?: boolean
  text?: string
}

export const GreenArrowButton: React.FC<React.PropsWithChildren<TProps>> = ({
  onClick,
  isButtonDisabled = false,
  text,
}) => {
  const { t } = useTranslation()
  return (
    <S.Button
      disabled={isButtonDisabled}
      onClick={onClick}
      id={SUBSCRIPTION_SECTION_IDS.startMyPlanButton}
    >
      <S.ButtonText>{text || t`actions.continue`}</S.ButtonText>
      <Arrow width={24} height={24} />
    </S.Button>
  )
}
