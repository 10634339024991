import catImg from 'assets/images/cat-cancel.jpg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { PLAN_TYPES } from 'root-constants'
import Discount from 'assets/images/sprite/discount-label-71.svg'
import { ScreenName } from 'services/eventLogger-service'
import { PrivacyPolicyLink } from 'modules/subscriptions/components/privacy-policy/PrivacyPolicyLink'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { GuaranteeVariant3 } from 'modules/subscriptions/components/guarantee/GuaranteeVariant3'
import { StickyHeader } from 'modules/subscriptions/components/sticky-header/StickyHeader'
import { SelectPlanBlockVariant3 } from 'modules/subscriptions/components/plan-blocks/SelectPlanBlockVariant3'
import { PlanItemVariant3 } from 'modules/subscriptions/components/plan-blocks/PlanItemVariant3'
import { useSelector } from 'react-redux'
import { selectCurrency } from 'root-redux/payment/payment-selects'
import { PlanItemVariant3Currency } from 'modules/subscriptions/components/plan-blocks/PlanItemVariant3Currency'
import { PageId } from 'constants/pages'
import { selectCurrentYear } from 'root-redux/common/common-selects'
import { FooterPriceTextWeeks } from 'modules/subscriptions/components/footer-price/FooterPriceTextWeeks'
import { StyledSubscriptions as S } from '../Subscriptions.styles'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
  selectedSubscription,
}) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const currentYear = useSelector(selectCurrentYear)
  const isPlanVisible = useMemo(() => window.screen.height > 390, [])
  const isDynamicPrices = useMemo(() => currency !== 'USD', [currency])

  return (
    <S.Wrapper>
      <S.Content2 isPlanVisible={isPlanVisible}>
        <StickyHeader isHide={isPlanVisible} screenName={ScreenName.CANCEL} />
        <S.TitleVariant3 isPlanVisible={isPlanVisible}>
          <strong>Enjoy Happy Relationship</strong>
          <br />
          And Fun Trainings With
          <br /> Your Cat!
        </S.TitleVariant3>
        <S.CatImage src={catImg} />
        <SelectPlanBlockVariant3
          onButtonClick={onButtonClick}
          onSelect={onSubscriptionSelect}
          SelectPlanItem={
            isDynamicPrices ? PlanItemVariant3Currency : PlanItemVariant3
          }
          availableSubscriptions={availableSubscriptions}
          isNonPayerFlow={isNonPayerFlow}
        />
        <GuaranteeVariant3 />
        <S.FinalBlockWrapper id={SUBSCRIPTION_SECTION_IDS.startMyPlanButton}>
          <S.PlanButtonWrapper>
            <S.DiscountImage>
              <Discount />
            </S.DiscountImage>
            <GreenArrowButton onClick={onButtonClick} text="Get My Plan" />
          </S.PlanButtonWrapper>
          <S.AgreementWithDescription>
            <FooterPriceTextWeeks selectedSubscription={selectedSubscription} />
            <br />
            {t`footer.disclaimer`}&nbsp;
            <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>
            &nbsp;|&nbsp;
            <PrivacyPolicyLink />
            <br />
            &nbsp;
            <Trans
              values={{ year: currentYear }}
              i18nKey="footer.allRightsReserved"
            />
          </S.AgreementWithDescription>
        </S.FinalBlockWrapper>
      </S.Content2>
    </S.Wrapper>
  )
}

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.CANCEL}
    planType={PLAN_TYPES.PURCHASE}
    isNonPayerFlow
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.CANCEL_OFFER_3

export const CancelOfferVariant3 = Component
