import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { Button } from 'components/buttons/Button'
import {
  baseColumnStyles,
  baseColumnStylesVariant2,
  baseMediaStylesVariant2,
  basePageStyles,
  baseTextStyles,
  baseTitleStyles,
  fullViewHeight,
} from 'pages/styles'

export const StyledUrge = {
  UrgeWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: #854eb3;
    padding-top: 36px;
  `,
  UrgeWrapper2: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.MEDIUM_GREEN};
    padding-top: 36px;
  `,
  UrgeWrapper3: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    padding-top: 88px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      padding-top: 98px;
    }
  `,
  UrgeWrapper4: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    padding-top: 72px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      padding-top: 88px;
    }
  `,
  UrgeContent: styled.div`
    ${baseColumnStyles};
  `,
  UrgeContent2: styled.div`
    ${baseColumnStylesVariant2};
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    line-height: normal;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 8px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  Title2: styled.h2`
    line-height: 140%;
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 8px;
    text-align: center;
    color: ${Color.BLACK};

    span {
      color: ${Color.SECONDARY_PURPLE};
    }
  `,
  Title3: styled.h2`
    line-height: 120%;
    font-size: 24px;
    font-weight: 800;
    margin-bottom: 24px;
    text-align: center;
    color: ${Color.BLACK};

    span {
      color: ${Color.SECONDARY_PURPLE};
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-bottom: 32px;
    }
  `,
  Subtitle: styled.h4`
    ${baseTextStyles};
    margin-bottom: 16px;
    color: ${Color.WHITE};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
  `,
  Subtitle2: styled.h4`
    margin-bottom: 96px;
    color: ${Color.BLACK};
    opacity: 0.8;
    font-size: 17px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-bottom: 112px;
    }
  `,
  Subtitle3: styled.h4`
    margin-bottom: 16px;
    color: ${Color.BLACK};
    opacity: 0.8;
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    text-align: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-bottom: 24px;
    }
  `,
  UrgeImage: styled.img`
    margin-bottom: 30px;
  `,
  WhiteButton: styled(Button)`
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    margin: 4px 16px 20px;
    box-shadow: none;
    max-width: 328px;

    display: flex;
    align-items: center;
    justify-content: center;
  `,
  ButtonText: styled.div`
    width: 100%;
  `,
  CommentWrapper: styled.div`
    position: relative;
  `,
  AnnotationTop: styled.img`
    position: absolute;
    width: 64px;
    height: 64px;
    top: -44px;
  `,
  AnnotationBottom: styled.img`
    position: absolute;
    transform: rotate(180deg);
    width: 64px;
    height: 64px;
    bottom: -42px;
    right: 0;
    z-index: -1;
  `,
  CatInfoContainer: styled.div`
    padding: 32px 20px 20px;
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    position: relative;
    width: 100%;
    margin-bottom: 120px;
  `,
  CatInfoTitle: styled.h4`
    position: absolute;
    top: -20px;
    left: calc(50% - 90px);
    font-size: 15px;
    line-height: 140%;
    font-weight: 700;
    color: #295e47;
    padding: 8px;
    background: ${Color.WHITE};
  `,
  CatInfoItem: styled.div<{ isHideBorder?: boolean }>`
    display: flex;
    align-items: flex-start;
    padding-bottom: ${({ isHideBorder }) => isHideBorder ? '0' : '8px'};
    border-bottom: ${({ isHideBorder }) => isHideBorder ? 'none' : '1px solid #d9d9d9'};
    font-size: 13px;
    line-height: 150%;
    margin-bottom: ${({ isHideBorder }) => isHideBorder ? '0' : '16px'};
    color: ${Color.BLACK};
    text-transform: capitalize;

    img {
      width: 24px;
      margin-right: 8px;
      margin-top: -4px;
    }

    strong {
      font-weight: 800;
    }
  `,
  StickyContainer: styled.div<{ shouldShowShadow?: boolean }>`
    width: 100vw;
    position: fixed;
    z-index: 1;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Color.WHITE};
    padding: 8px 16px 56px;

    ${({ shouldShowShadow }) => shouldShowShadow && 'box-shadow: 0px -6px 20px 0 rgba(0, 0, 0, 0.08)'};
  `,
  ButtonWrapper: styled.div`
    ${baseMediaStylesVariant2};
  `,
  UsersInfoBlock: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    background: #EBE3FF;
    border-radius: 16px;
    padding: 12px;
    max-width: 328px;
    margin-bottom: 120px;
  `,
  InfoCircle: styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${Color.WHITE};
  `,
  InfoText: styled.p`
    font-size: 13px;
    line-height: 150%;
    font-weight: 600;
    color: ${Color.BLACK};
    flex: 1;

    strong {
      font-weight: 800;
    }
  `,
  LessonsCounter: styled.div`
    width: 152px;
    height: 152px;
    margin-bottom: 32px;
  `,
  ChartAnimation: styled.div`
    width: 328px;
    height: 276px;
    margin-bottom: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    box-shadow: 0 6px 23px 0 rgba(0, 0, 0, 0.08);
    position: relative;
  `,
  ChartTitle: styled.h4`
    font-weight: 700;
    font-size: 13px;
    line-height: 150%;
    color: ${Color.BLACK};
    position: absolute;
    top: 16px;
    left: 20%;
  `,
}
