import { IRemoteConfigFile, IRemoteConfigFileRaw } from 'models/config.model'

export const getWebFunnelsConfigFromRawData = (
  config: IRemoteConfigFileRaw,
): IRemoteConfigFile => ({
  signUpMethods: {
    chooseOtherMethodsShown: config.sign_up_methods.choose_other_methods_shown,
    otherMethodsShown: {
      facebook: config.sign_up_methods.other_methods_shown.facebook,
      apple: config.sign_up_methods.other_methods_shown.apple,
      google: config.sign_up_methods.other_methods_shown.google,
    },
  },
  paypalMethodShown: config.paypal_method_shown,
  allRightsReservedYear: config.all_rights_reserved_year,
})
