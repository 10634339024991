import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'constants/pages'

import branchLeft from 'assets/images/left-branch-green.svg'
import branchRight from 'assets/images/right-branch-green.svg'
import { useProgressAnimationData } from 'hooks/useProgressAnimationData'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { useTranslation } from 'react-i18next'
import { StyledPrepareFlow as S } from './PrepareFlow.styles'
import { COMMENTS_V3 } from './components/constants'
import { ProgressBarVariant2 } from './components/ProgressBarVariant2'
import { CommentVariant2 } from './components/CommentVariant2'
import { AnalyzingAnswersModalVariant2 } from './components/AnalyzingAnswersModalVariant2'

export const QUESTION = 'How does your cat feel about being petted?'
export const STOP_COUNTER_AT = 99

const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [answer, setAnswer] = useState('')
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers: answer,
        pageNumber: currentPageNumber,
        pageName: currentPageId,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep, answer])

  const handleContinueAnimation = useCallback((playback: () => void) => {
    const timerId = setTimeout(() => {
      playback && playback()
      clearTimeout(timerId)
    }, 500)
  }, [])

  const handleChange = useCallback(
    (animationPlayback) => {
      handleContinueAnimation(animationPlayback)
    },
    [handleContinueAnimation],
  )

  const {
    firstCounter,
    isFirstCounterFinished,
    secondCounter,
    isSecondCounterFinished,
    secondProgressAnimationPlayback,
    thirdCounter,
    isThirdCounterFinished,
    fourthCounter,
    isFourthCounterFinished,
  } = useProgressAnimationData({
    onNextStep: handleContinue,
    stopCounterAt: STOP_COUNTER_AT,
    durations: [6000, 6000, 4000, 4000],
  })

  const handleModalChange = useCallback(
    (value) => {
      if (answer) return

      setAnswer(value)
      handleChange(secondProgressAnimationPlayback)
    },
    [answer, handleChange, secondProgressAnimationPlayback],
  )

  return (
    <S.Wrapper2>
      <S.Content2>
        <S.PrepareFlowTitle2>
          Creating personal program <br />
          for your feline friend
          <S.Dots>...</S.Dots>
        </S.PrepareFlowTitle2>
        <ProgressBarVariant2
          value={firstCounter}
          isInProgress={!!firstCounter}
          isFinished={isFirstCounterFinished}
        >
          {t`onboarding.prepareFlow.item1`}
        </ProgressBarVariant2>
        <ProgressBarVariant2
          value={secondCounter}
          isInProgress={!!secondCounter}
          isFinished={isSecondCounterFinished}
        >
          {t`onboarding.prepareFlow.item2`}
        </ProgressBarVariant2>
        <ProgressBarVariant2
          value={thirdCounter}
          isInProgress={!!thirdCounter}
          isFinished={isThirdCounterFinished}
        >
          {t`onboarding.prepareFlow.item3`}
        </ProgressBarVariant2>
        <ProgressBarVariant2
          value={fourthCounter}
          isInProgress={!!fourthCounter}
          isFinished={isFourthCounterFinished}
        >
          {t`onboarding.prepareFlow.item4`}
        </ProgressBarVariant2>
        <S.CommentsTitleWrapper2>
          <S.BrunchImg src={branchLeft} />
          <S.CommentsTitle2>{t`onboarding.prepareFlow.item5`}</S.CommentsTitle2>
          <S.BrunchImg src={branchRight} />
        </S.CommentsTitleWrapper2>
        <S.SliderWrapper2>
          <Carousel
            className="commentsCarousel"
            slidesPerView={1}
            spaceBetween={8}
            centeredSlides
            pagination={false}
            loop
            modules={[Autoplay, Navigation]}
            speed={1000}
            autoplay={{ disableOnInteraction: false }}
          >
            {COMMENTS_V3.map(
              ({ title, avatar, text, autor, autoplayDelay }) => (
                <SwiperSlide key={title} data-swiper-autoplay={autoplayDelay}>
                  <CommentVariant2 image={avatar} title={title} autor={autor}>
                    {t(text)}
                  </CommentVariant2>
                </SwiperSlide>
              ),
            )}
          </Carousel>
        </S.SliderWrapper2>

        <AnalyzingAnswersModalVariant2
          isModalVisible={secondCounter === STOP_COUNTER_AT}
          onChange={(value) => handleModalChange(value)}
        >
          How does your cat feel <br />
          about being petted?
        </AnalyzingAnswersModalVariant2>
      </S.Content2>
    </S.Wrapper2>
  )
}

Component.hasHeader = true
Component.id = PageId.PREPARE_FLOW_2

export const PrepareFlowVariant2 = Component
