import styled from 'styled-components'
import { MediaBreakpoint } from 'root-constants'
import PromptSvg from 'assets/images/sprite/prompt.svg'
import PromptPointer from 'assets/images/sprite/prompt-pointer.svg'
import { fullViewHeight } from 'pages/styles'

type TStyledModalProps = {
  isShown: boolean
}

export const StyledModal = {
  Wrapper: styled.div<TStyledModalProps>`
    ${fullViewHeight};
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: ${({ isShown }) => (isShown ? 10 : -1)};
    width: max(100%, var(--min-screen-width));
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    transition: opacity 0.2 ease-out;
  `,
  Content: styled.div<{ topModalPosition?: string }>`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: ${({ topModalPosition }) => topModalPosition || '340px'};
    border-radius: 12px;
    padding: 24px 16px;
    background-color: #ffffff;
  `,
  PromptImage: styled(PromptSvg)`
    width: 256px;
    height: 72px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 288px;
      height: auto;
    }
  `,
  Pointer: styled(PromptPointer)`
    position: absolute;
    top: -12px;
    right: 24px;
    width: 32px;
    height: 12px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      right: 40px;
    }
  `,
}
