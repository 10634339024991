import payPalLogo from 'assets/images/paypal-logo.png'
import payPalLogoInactive from 'assets/images/paypal-logo-inactive.png'
import cardLogo from 'assets/images/credit-card-logo.png'
import cardLogoInactive from 'assets/images/credit-card-logo-inactive.png'
import CheckPoint from 'assets/images/sprite/paypal-check-icon.svg'
import { FlowContext } from 'components/FlowContext'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { resetErrorAction } from 'root-redux/common/common-actions'
import {
  selectVariantCohort,
  selectCurrentVariantSteps,
  selectIsCancelOfferApplied,
  selectFirebaseConfigFile,
} from 'root-redux/common/common-selects'
import { selectEmail } from 'root-redux/user/user-selects'
import {
  selectIsPaymentFlowsShown,
  selectIsStoreButtonsShown,
} from 'root-redux/payment/payment-selects'
import { ScreenName, eventLogger } from 'services/eventLogger-service'
import { googleAnalyticsLogger } from 'services/googleAnalytics-service'
import { PageId } from 'constants/pages'
import { useTranslation } from 'react-i18next'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { PlatformOS } from 'root-constants'

import { StyledPaymentFlows as S } from './PaymentFlows.styles'
import { PayPalContainer } from './paypal/PayPalContainer'
import { PaymentMethod } from '../constants'
import { OptionType } from 'components/option/Option'
import { PaymentRequestOptionVariant3 } from './payment-request-options/PaymentRequestOptionVariant3'
import { StripePaymentFormVariant2 } from './stripe-payment-form/StripePaymentFormVariant2'
import { TotalAmountVariant4 } from './total-amount/TotalAmountVariant4'
import { BackModal } from './back-modal/BackModal'

interface IProps {
  productId: string
  productName: string
}

export const payPalAdvantages = [
  'Fast and secure payments with your PayPal account',
  'Your financial info stays private with end-to-end encryption',
  'Extra protection with PayPal’s Buyer Protection program',
]

export const PaymentFlowsVariant4: React.FC<IProps> = ({
  productId,
  productName,
}) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { onPreviousStep } = useContext(FlowContext)
  const cohort = useSelector(selectVariantCohort)
  const currentVariantSteps = useSelector(selectCurrentVariantSteps)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const isStoreButtonsShown = useSelector(selectIsStoreButtonsShown)
  const email = useSelector(selectEmail)
  const { paypalMethodShown } = useSelector(selectFirebaseConfigFile)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const [activePaymentMethod, setActivePaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const screenName = useMemo(
    () => (isCancelOfferApplied ? ScreenName.CANCEL : ScreenName.SALES),
    [isCancelOfferApplied],
  )

  const handleBack = useCallback(() => {
    eventLogger.logPaymentMethodPageClose({
      screenName,
      email,
      productId,
      productName,
    })
    const isFlowIncludesPagesForNonPayerUsers = currentVariantSteps.some(
      (page) => page.id === PageId.CANCEL_OFFER_4,
    )

    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)

    if (isFlowIncludesPagesForNonPayerUsers && isCancelOfferApplied) {
      onPreviousStep()
      return
    }
    !isCancelOfferApplied && setIsModalOpen(true)
  }, [
    cohort,
    email,
    productId,
    screenName,
    currentVariantSteps,
    dispatch,
    pathname,
    onPreviousStep,
    isCancelOfferApplied,
  ])

  const optionProps = useMemo(
    () => ({
      name: 'payment',
      type: OptionType.RADIO,
      onChange: (value) => setActivePaymentMethod(value),
    }),
    [],
  )

  useEffect(() => {
    const isIOS = getMobileOperatingSystem() === PlatformOS.IOS

    if (isPaymentFlowsShown) {
      setActivePaymentMethod(
        isStoreButtonsShown && isIOS
          ? PaymentMethod.APPLE_PAY
          : PaymentMethod.CREDIT_CARD,
      )
    }
  }, [isStoreButtonsShown, isPaymentFlowsShown])

  useEffect(() => {
    const { height } = window.screen

    if (height < 700 && isPaymentFlowsShown) {
      const el = document.getElementById('money-back-guarantee')
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isPaymentFlowsShown])

  return (
    <S.Content>
      <S.BackButton3 onClick={handleBack} />
      <S.TitleWrapper3>
        <S.Title2>{t`payment.checkout`}</S.Title2>
      </S.TitleWrapper3>
      <TotalAmountVariant4 />
      <S.PaymentTitle2>
        <span>{t`payment.paymentInfo`}</span>
        <S.PaymentTitleProtection>{t`payment.protected`}</S.PaymentTitleProtection>
      </S.PaymentTitle2>
      <S.OptionsContainerVariant2>
        <S.Option
          {...optionProps}
          value={PaymentMethod.CREDIT_CARD}
          checked={activePaymentMethod === PaymentMethod.CREDIT_CARD}
        >
          <S.PaymentButton3>
            <S.ButtonLabel
              img={
                activePaymentMethod === PaymentMethod.CREDIT_CARD
                  ? cardLogo
                  : cardLogoInactive
              }
            >
              Credit card
            </S.ButtonLabel>
          </S.PaymentButton3>
          {activePaymentMethod === PaymentMethod.CREDIT_CARD && (
            <>
              <S.LineDivider2 />
              <S.PaymentMethodWrapper>
                <StripePaymentFormVariant2
                  screenName={screenName}
                  topModalPosition="356px"
                />
              </S.PaymentMethodWrapper>
            </>
          )}
        </S.Option>
        <PaymentRequestOptionVariant3
          optionProps={optionProps}
          value={PaymentMethod.APPLE_PAY}
          checked={activePaymentMethod === PaymentMethod.APPLE_PAY}
          screenName={screenName}
        />
        {paypalMethodShown && (
          <>
            <S.Option
              {...optionProps}
              value={PaymentMethod.PAYPAL}
              checked={activePaymentMethod === PaymentMethod.PAYPAL}
            >
              <S.PaymentButton3>
                <S.ButtonLabel
                  img={
                    activePaymentMethod === PaymentMethod.PAYPAL
                      ? payPalLogo
                      : payPalLogoInactive
                  }
                >
                  PayPal
                </S.ButtonLabel>
              </S.PaymentButton3>
              {activePaymentMethod === PaymentMethod.PAYPAL && (
                <>
                  <S.LineDivider2 />
                  <S.CheckList>
                    {payPalAdvantages.map((advantage) => (
                      <S.CheckItem key={advantage}>
                        <CheckPoint width={24} height={24} />
                        {advantage}
                      </S.CheckItem>
                    ))}
                  </S.CheckList>
                  <S.PaymentMethodWrapperVariant3>
                    <PayPalContainer
                      customStyle={{
                        color: 'gold',
                      }}
                      screenName={screenName}
                    />
                  </S.PaymentMethodWrapperVariant3>
                </>
              )}
            </S.Option>
          </>
        )}
      </S.OptionsContainerVariant2>
      <S.Guarantee2 id="money-back-guarantee">
        Money-back Guarantee
      </S.Guarantee2>
      <S.Disclaimer>
        By continuing you sign up for a subscription that automatically renews
        at the end of selected period unless you cancel at least 24 hours before
        the end of then-current period. To avoid being charged cancel your
        subscription by contacting our support at least 24 hours before the end
        of then-current period. If you are unsure how to cancel, visit our{' '}
        <TermsOfUseLink>Terms of Use</TermsOfUseLink>. You may wish to make a
        screenshot of this information for your reference.
      </S.Disclaimer>
      {isModalOpen && <BackModal />}
    </S.Content>
  )
}
