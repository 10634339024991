import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GENERAL_QUESTIONS_OPTIONS_VALUES } from 'root-constants'
import catScratching from 'assets/images/scratching-cat.png'
import { PageId } from 'constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Does your cat scratch the furniture?'

const OPTION_ANSWERS = [
  { answer: 'Yes, regularly', value: GENERAL_QUESTIONS_OPTIONS_VALUES.YES },
  { answer: 'Sometimes', value: GENERAL_QUESTIONS_OPTIONS_VALUES.SOMETIMES },
  {
    answer: 'No, uses scratching post',
    value: GENERAL_QUESTIONS_OPTIONS_VALUES.NO,
  },
]

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId],
  )

  return (
    <S.Wrapper4>
      <S.Content4>
        <S.Title7>
          Does your cat scratch <br />
          the furniture?
        </S.Title7>
        <S.QuestionImage src={catScratching} alt="scratching cat" />
        <S.OptionsContainer4 data-is-multiple>
          {OPTION_ANSWERS.map(({ answer, value }) => (
            <S.Option3 key={value} {...optionProps} value={value}>
              <S.QuestionButton3>{answer}</S.QuestionButton3>
            </S.Option3>
          ))}
        </S.OptionsContainer4>
      </S.Content4>
    </S.Wrapper4>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CAT_SCRATCHING

export const CatScratching = Component
