import mixedBreedImg from 'assets/images/breeds/mixed-breed-cat-3.png'
import exoticShutterstockImg from 'assets/images/breeds/exotic-shutterstock-3.png'
import ragdollImg from 'assets/images/breeds/ragdoll-breed-3.png'
import persianImg from 'assets/images/breeds/persian-breed-3.png'
import abyssinianImg from 'assets/images/breeds/abyssian-breed-3.png'
import maineCoonImg from 'assets/images/breeds/maine-coon-breed-3.png'
import sphynxImg from 'assets/images/breeds/sphynx-breed-3.png'
import devonRexImg from 'assets/images/breeds/devon-rex-breed-3.png'
import scottishImg from 'assets/images/breeds/scottish-fold-breed-3.png'
import americanShorthairImg from 'assets/images/breeds/american-shorthair-breed-3.png'
import britishShorthairImg from 'assets/images/breeds/british-shorthair-breed-3.png'
import siameseImg from 'assets/images/breeds/siamese-breed-3.png'

import React, { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { StyledSubscriptions as S } from 'modules/subscriptions/pages/Subscriptions.styles'
import { getCatQuestionAnswers } from 'helpers/getCatQuestionAnswers'
import lottie from 'lottie-web/build/player/lottie_light'
import confetti from 'assets/animation/confetti.json'
import { getAnswer, getAnswersValues } from 'modules/subscriptions/helpers'
import { IDK } from 'root-constants'
import { selectAnswers } from 'root-redux/common/common-selects'
import {
  AnswerKey,
  BREED_OPTIONS,
  BREED_OPTIONS_VALUES,
} from 'modules/subscriptions/constants'
import { useTranslation } from 'react-i18next'

const CAT_BREED_TO_IMAGE_MAP = {
  [BREED_OPTIONS.MIXED_BREED]: mixedBreedImg,
  [BREED_OPTIONS.EXOTIC]: exoticShutterstockImg,
  [BREED_OPTIONS.RAGDOLL]: ragdollImg,
  [BREED_OPTIONS.PERSIAN]: persianImg,
  [BREED_OPTIONS.ABYSSINIAN]: abyssinianImg,
  [BREED_OPTIONS.MAINE_COON]: maineCoonImg,
  [BREED_OPTIONS.SPHYNX]: sphynxImg,
  [BREED_OPTIONS.DEVON_REX]: devonRexImg,
  [BREED_OPTIONS.SCOTTISH]: scottishImg,
  [BREED_OPTIONS.AMERICAN_SHORTHAIR]: americanShorthairImg,
  [BREED_OPTIONS.BRITISH_SHORTHAIR]: britishShorthairImg,
  [BREED_OPTIONS.SIAMESE]: siameseImg,
}

const FIELDS = [
  AnswerKey.CAT_BREED,
  AnswerKey.GOALS_QUESTION,
  AnswerKey.AGE,
  AnswerKey.TIME,
  AnswerKey.TRAINING,
]

export const CatCardVariant3: React.FC = () => {
  const { t } = useTranslation()
  const answers = useSelector(selectAnswers)
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const catCardValues = useMemo(
    () => getCatQuestionAnswers(answers, FIELDS) || {},
    [answers],
  )
  const catBreed = useMemo(
    () => getAnswer(catCardValues[AnswerKey.CAT_BREED]),
    [catCardValues],
  )
  const catGoals = useMemo(() => {
    if (
      catCardValues[AnswerKey.GOALS_QUESTION]?.[0] ===
        'onboarding.common.other' &&
      catCardValues[AnswerKey.GOALS_QUESTION].length === 1
    ) {
      return 'Improve cat behavior'
    }
    return getAnswersValues(
      catCardValues[AnswerKey.GOALS_QUESTION] as string[],
      t,
    )
  }, [catCardValues])

  useEffect(() => {
    if (!animationContainerRef || !animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: confetti,
      loop: true,
    })
  }, [])

  return (
    <S.WrapperWhite>
      <S.IntroImageWrapper>
        <S.AnimationWrapper ref={animationContainerRef} />
        <S.TitleVariant6>{t`subscription.planReady`}</S.TitleVariant6>
        <S.CatImageVariant3
          src={CAT_BREED_TO_IMAGE_MAP[t(catBreed)] || mixedBreedImg}
          alt="cat"
        />
      </S.IntroImageWrapper>
      <S.CardItemsWrapper2>
        {![IDK, BREED_OPTIONS.OTHER].includes(catBreed) && (
          <S.CardItem2>
            <S.LightGreenCheckImage />
            <S.Answer2>
              {t`onboarding.catCard.breed`}&nbsp;
              <strong>{t(catBreed)}</strong>
            </S.Answer2>
          </S.CardItem2>
        )}
        <S.CardItem2>
          <S.LightGreenCheckImage />
          <S.Answer2>
            {t`onboarding.catCard.age`}&nbsp;
            <strong>{t(getAnswer(catCardValues[AnswerKey.AGE]))}</strong>
          </S.Answer2>
        </S.CardItem2>
        <S.CardItem2>
          <S.LightGreenCheckImage />
          <S.Answer2>
            {t`onboarding.catCard.goal`}&nbsp;
            <strong>{catGoals}</strong>
          </S.Answer2>
        </S.CardItem2>
        <S.CardItem2 style={{ border: 'none', paddingBottom: 0 }}>
          <S.LightGreenCheckImage />
          <S.Answer2>
            {t`onboarding.catCard.trainingTime`}&nbsp;
            <strong>{t(getAnswer(catCardValues[AnswerKey.TIME]))}</strong>
          </S.Answer2>
        </S.CardItem2>
      </S.CardItemsWrapper2>
    </S.WrapperWhite>
  )
}
