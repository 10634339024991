import React, { useContext, useEffect, useRef } from 'react'
import lottie from 'lottie-web/build/player/lottie_light'
import meowzChart from 'assets/animation/meowzChart2.json'
import { StyledBackModal as S } from './BackModal.styles'
import { useTranslation } from 'react-i18next'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { FlowContext } from 'components/FlowContext'

export const BackModal: React.FC = () => {
  const { onPreviousStep } = useContext(FlowContext)
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: meowzChart,
      loop: true,
    })
  }, [])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`subscription.didYouKnow`}</S.Title>
        <S.Description>
          <strong>74% of users</strong> started Meowz training program <br />
          advanced in their goals <br />
          within the first month*
        </S.Description>
        <S.Chart ref={animationContainerRef} />
        <S.Comment>{t`subscription.remark`}</S.Comment>
        <S.InfoBlock>
          We want you to find success so we are offering <br />
          the <strong>additional discount</strong> to your&nbsp;
          <strong>Meowz Plan</strong>
        </S.InfoBlock>
        <GreenArrowButtonVariant2 onClick={onPreviousStep} text="Got it" />
      </S.Content>
    </S.Wrapper>
  )
}
