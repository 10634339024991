import { useStripe } from '@stripe/react-stripe-js'
import { PaymentRequest } from '@stripe/stripe-js'
import { FlowContext } from 'components/FlowContext'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { resetErrorAction } from 'root-redux/common/common-actions'
import applePayLogo from 'assets/images/apple-pay-logo.svg'
import googlePayLogo from 'assets/images/google-pay-logo.svg'
import applePayLogoInactive from 'assets/images/apple-pay-logo-inactive.svg'
import googlePayLogoInactive from 'assets/images/google-pay-logo-inactive.svg'
import applePayLogoWhite from 'assets/images/sprite/apple_pay_white.svg'
import googlePayLogoWhite from 'assets/images/sprite/google_pay_white.svg'
import { CENTS_IN_DOLLAR } from 'root-constants'
import { OptionType } from 'components/option/Option'
import { selectEmail } from 'root-redux/user/user-selects'
import CheckPoint from 'assets/images/sprite/paypal-check-icon.svg'
import sslEncryption from 'assets/images/ssl-secure.png'
import { PaymentMethod } from '../../constants'
import {
  purchaseAction,
  setIsPaymentFlowsShownAction,
  setIsStoreButtonsAction,
} from 'root-redux/payment/payment-actions'
import { TPaymentRequestButton } from '../../types'
import { StyledPaymentFlows as S } from '../PaymentFlows.styles'
import {
  selectCurrency,
  selectFullPrice,
  selectPeriodName,
  selectPeriodQuantity,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'

type TProps = {
  value: PaymentMethod
  checked: boolean
  optionProps: {
    name: string
    type: OptionType
    onChange: (value: any) => void
  }
  screenName: string
}

export const paymentAdvantages = {
  googlePay: [
    'Fast and secure payments with Google Pay',
    'Your financial information is safe with advanced encryption',
    'Protected by Google’s multi-layered security',
  ],
  applePay: [
    'Easy and private payments with Face/Touch ID',
    'Keeps your financial info safe with end-to-end encryption',
    `Protected by Apple Pay's unique Device Account Number`,
  ],
}

export const PaymentRequestOptionVariant3: React.FC<TProps> = ({
  value,
  checked,
  optionProps,
  screenName,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentPageId } = useContext(FlowContext)
  const stripe = useStripe()
  const trialCurrentPrice = useSelector(selectTrialFullPrice)
  const currency = useSelector(selectCurrency)
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const currentPrice = useSelector(selectFullPrice)
  const email = useSelector(selectEmail)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )
  const buttonIcon = useMemo(() => {
    if (!paymentRequest) return null

    if (buttonTypes?.applePay) {
      return checked ? applePayLogo : applePayLogoInactive
    }
    return checked ? googlePayLogo : googlePayLogoInactive
  }, [paymentRequest, buttonTypes, checked])

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || currentPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, currentPrice],
  )
  const StoreLogo = useMemo(
    () => (buttonTypes?.applePay ? applePayLogoWhite : googlePayLogoWhite),
    [buttonTypes?.applePay],
  )
  const periodNameFormatted = useMemo(() => {
    if (!periodName) return ''
    return t(`onboarding.planBlock.${periodName}`)
  }, [periodName])

  const paymentLabel = useMemo(
    () =>
      trialPeriodDays === 7
        ? t('payment.forTrial', { trialPeriodDays })
        : t('payment.forPriceTotal', {
            periodQuantity: trialPeriodDays / 30,
            periodNameFormatted,
          }),
    [periodQuantity, trialPeriodDays],
  )

  useEffect(() => {
    if (!stripe || !currentPageId) {
      return
    }

    const pr = stripe.paymentRequest({
      currency: currency.toLowerCase(),
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: paymentLabel,
        amount: calculatedPrice,
      },
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        setButtonTypes(result as TPaymentRequestButton)
        setPaymentRequest(pr)

        dispatch(setIsStoreButtonsAction(true))
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
          screenName,
          currency,
        }),
      )
    })
  }, [
    dispatch,
    currency,
    stripe,
    calculatedPrice,
    currentPageId,
    periodQuantity,
    periodName,
    screenName,
  ])

  const handleButtonClick = useCallback(() => {
    if (paymentRequest) {
      paymentRequest.show()
      window.ttq.identify({ email })
      window.ttq.track('AddPaymentInfo')
      window.snaptr('track', 'ADD_BILLING')
    }
  }, [paymentRequest, email])

  return paymentRequest ? (
    <>
      <S.Option {...optionProps} value={value} checked={checked}>
        <S.PaymentButton3>
          <S.ButtonLabel img={buttonIcon}>
            {buttonTypes?.applePay ? 'Apple pay' : 'Google Pay'}
          </S.ButtonLabel>
        </S.PaymentButton3>
        {checked && (
          <>
            <S.LineDivider2 />
            <S.CheckList>
              {paymentAdvantages[
                buttonTypes?.applePay ? 'applePay' : 'googlePay'
              ].map((advantage) => (
                <S.CheckItem key={advantage}>
                  <CheckPoint width={24} height={24} />
                  {advantage}
                </S.CheckItem>
              ))}
            </S.CheckList>
            <S.PaymentMethodWrapperVariant4>
              <S.CustomPaymentButton
                onClick={handleButtonClick}
                type="button"
                id="payment-request-button"
              >
                <StoreLogo width={58} height={24} />
              </S.CustomPaymentButton>

              <S.SecureImage src={sslEncryption} alt="discount dog" />
            </S.PaymentMethodWrapperVariant4>
          </>
        )}
      </S.Option>
    </>
  ) : null
}
