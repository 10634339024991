import React from 'react'
import circlePlan from 'assets/images/circle-plan.jpg'
import GreenCheck from 'assets/images/sprite/green-check-2.svg'

import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { StyledUrge as S } from './UrgeCircleProgress.styles'

interface IProps {
  onContinue: () => void
}

export const UrgeCircleProgressVariant: React.FC<IProps> = ({ onContinue }) => (
  <S.UrgeWrapper>
    <S.UrgeContent>
      <S.UrgeImage src={circlePlan} />
      <S.Title>
        Your plan will be reviewed by a practicing{' '}
        <span>feline behaviorist</span>
      </S.Title>
      <S.ExpertInfoWrapper>
        <S.BlockTitle>Content reviewed by an expert</S.BlockTitle>
        <S.ExpertDescription>
          <S.ExpertName>
            Natalia Grochal
            <GreenCheck />
          </S.ExpertName>
          Certified Cat Behavior Consultant, Former College Professor
        </S.ExpertDescription>
      </S.ExpertInfoWrapper>
      <S.StickyBlockWrapper>
        <GreenArrowButton onClick={onContinue} />
      </S.StickyBlockWrapper>
    </S.UrgeContent>
  </S.UrgeWrapper>
)
