import Discount from 'assets/images/sprite/discount-label.svg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'

import React, { useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { PLAN_TYPES } from 'root-constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import { Events, ScreenName } from 'services/eventLogger-service'
import { PrivacyPolicyLink } from 'modules/subscriptions/components/privacy-policy/PrivacyPolicyLink'
import { PageId } from 'constants/pages'
import { useSelector } from 'react-redux'
import { selectCurrentYear } from 'root-redux/common/common-selects'
import { StyledSubscriptions as S } from './Subscriptions.styles'
import { CatCardVariant3 } from '../components/cat-card/CatCardVariant3'
import { StickyHeaderVariant2 } from '../components/sticky-header/StickyHeaderVariant2'
import { DidYouKnowBlockVariant2 } from '../components/did-you-know/DidYouKnowBlockVariant2'
import { ObtainingListVariant3 } from '../components/obtaining-list/ObtainingListVariant3'
import { InterestingFactVariant2 } from '../components/interesting-fact/InterestingFactVariant2'
import { SelectPlanBlockVariant4 } from '../components/plan-blocks/SelectPlanBlockVariant4'
import { PlanItemVariant4 } from '../components/plan-blocks/PlanItemVariant4'
import { GuaranteeVariant4 } from '../components/guarantee/GuaranteeVariant4'
import { CommentsVariant2 } from '../components/comments/CommentsVariant2'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { selectCurrencySign } from 'root-redux/payment/payment-selects'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
  selectedSubscription,
}) => {
  const { t } = useTranslation()
  const currentYear = useSelector(selectCurrentYear)
  const currency = useSelector(selectCurrencySign)

  const footerQuantityText = useMemo(() => {
    if (selectedSubscription?.trialPrices.durationDays <= 7) {
      return 'Extended monthly after 1-week intro offer at the price of'
    }

    if (selectedSubscription?.trialPrices.durationDays <= 30) {
      return 'Extended monthly at the price of'
    }

    return `Extended every ${selectedSubscription?.mainPrices.periodQuantity} months at the price of`
  }, [selectedSubscription])

  useEffect(() => {
    sessionStorage.removeItem(Events.VIEW_PRICE)
  }, [])

  return (
    <S.Wrapper2>
      <S.ContentVariant3>
        <StickyHeaderVariant2 onButtonClick={onButtonClick} />
        <CatCardVariant3 />
        <DidYouKnowBlockVariant2 />
        <ObtainingListVariant3 />
        <InterestingFactVariant2 />
        <SelectPlanBlockVariant4
          onButtonClick={onButtonClick}
          onSelect={onSubscriptionSelect}
          SelectPlanItem={PlanItemVariant4}
          availableSubscriptions={availableSubscriptions}
          isNonPayerFlow={isNonPayerFlow}
        />
        <GuaranteeVariant4 />
        <CommentsVariant2 />
        <S.FinalBlockWrapper2>
          {availableSubscriptions.length > 0 && (
            <S.PlanButtonWrapper2>
              <S.DiscountImage2>
                <Discount />
              </S.DiscountImage2>
              <GreenArrowButtonVariant2
                onClick={onButtonClick}
                text="Get my plan"
              />
            </S.PlanButtonWrapper2>
          )}
          <S.AgreementWithDescription2>
            {footerQuantityText}&nbsp;
            {currency}
            {selectedSubscription?.mainPrices.fullPrice}
            <br />
            {t`footer.disclaimer`}&nbsp;
            <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>
            &nbsp;|&nbsp;
            <PrivacyPolicyLink />
            <br />
            &nbsp;
            <Trans
              values={{ year: currentYear }}
              i18nKey="footer.allRightsReserved"
            />
          </S.AgreementWithDescription2>
        </S.FinalBlockWrapper2>
      </S.ContentVariant3>
    </S.Wrapper2>
  )
}
const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.SALES}
    planType={PLAN_TYPES.PURCHASE}
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.SUBSCRIPTIONS_3

export const SubscriptionsVariant4 = Component
