import styled from 'styled-components'
import commentWithHeart from 'assets/images/comment-with-heart.svg'
import commentWithHeart2 from 'assets/images/heart-comment.png'
import { Color, MediaBreakpoint } from 'root-constants'
import { baseColumnStyles, baseColumnStylesVariant2 } from 'pages/styles'

export const StyledComments = {
  Wrapper: styled.div`
    ${baseColumnStyles};
    padding: 42px 0 0;

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 328px;
      height: 172px;
      background-color: #ffffff;
      border-radius: 16px;
    }

    .swiper-pagination {
      position: static;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 16px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      background-color: #d3b098;
      border-radius: 50%;
      margin: 0 8px 0 0 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: #35b43f;
    }
  `,
  Wrapper2: styled.div`
    ${baseColumnStylesVariant2};
    padding: 42px 0 0;

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 328px;
      border-radius: 16px;
    }

    .swiper-pagination {
      position: static;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 16px;
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      margin: 0 8px 0 0 !important;
    }

    .swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
      width: 8px;
      height: 8px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {

      .swiper-slide {
        width: 382px;
      }
    }
  `,
  CommentsWrapper: styled.div`
    background-color: ${Color.BEIGE};
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    padding-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 100%;
      overflow-x: hidden;
    }
  `,
  CommentsWrapper2: styled.div`
    background-color: #ebe3ff;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    padding: 40px 0 4px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      overflow-x: hidden;
    }
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Header2: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  Title: styled.h3`
    width: 60%;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;

    strong {
      font-weight: 800;
      color: #ff5454;
    }
  `,
  HeaderImage: styled.div`
    width: 104px;
    height: 96px;
    background-image: url("${commentWithHeart}");
    background-size: cover;
    margin-bottom: -11px;
  `,
  HeaderImage2: styled.div`
    width: 106px;
    height: 98px;
    background-image: url("${commentWithHeart2}");
    background-size: cover;
    margin-bottom: -11px;
  `,
}
