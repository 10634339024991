import React from 'react'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import cat from 'assets/images/hiding-subscription-cat.png'
import { Trans, useTranslation } from 'react-i18next'
import { StyledInterestingFact as S } from './InterestingFact.styles'

export const InterestingFactVariant2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper2 id={SUBSCRIPTION_SECTION_IDS.interestingFact}>
      <S.Label2>💡{t`onboarding.interestingFact.label`}</S.Label2>
      <S.Title2>
        <Trans
          i18nKey="onboarding.interestingFact.title"
          components={[<br />]}
        />
      </S.Title2>
      <S.Description2>
        {t`onboarding.interestingFact.description`} 😺🐾
      </S.Description2>
      <S.Image2 src={cat} alt="cat" />
    </S.Wrapper2>
  )
}
