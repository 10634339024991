import React, { ButtonHTMLAttributes } from 'react'
import { StyledBackButton as S } from './styles'

type TProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: () => void
}

export const BackButton: React.FC<TProps> = ({ onClick, ...props }) => (
  <S.Back onClick={onClick} role="button" aria-label="Back button" {...props}>
    <S.Image />
  </S.Back>
)
