import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { basePageStyles } from 'pages/styles'

export const StyledBlock = {
  Wrapper: styled.div`
    ${basePageStyles};
    overflow-x: hidden;
    background-color: ${Color.BEIGE};
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    width: 100vw;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  Wrapper2: styled.div`
    ${basePageStyles};
    overflow-x: hidden;
    background-color: ${Color.BEIGE};
    width: 100vw;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  Wrapper3: styled.div`
    ${basePageStyles};
    overflow-x: hidden;
    background-color: #ebe3ff;
    border-radius: 40px;
    width: 100%;
  `,
  Title: styled.h2`
    padding-top: 40px;
    font-size: 24px;
    font-weight: 800;
    line-height: 30px;
  `,
  Title2: styled.h2`
    padding-top: 40px;
    font-size: 24px;
    font-weight: 800;
    line-height: 120%;
    color: ${Color.BLACK};
  `,
  Description: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.PRIMARY_TEXT_SHADOW};
    text-align: center;
    padding: 16px 32px 24px;

    strong {
      font-weight: 800;
      color: ${Color.PURPLE};
    }
  `,
  Description2: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    color: ${Color.BLACK};
    text-align: center;
    padding: 8px 16px 16px;

    strong {
      font-weight: 800;
      color: #8d5fff;
    }
  `,
  Chart: styled.div`
    padding: 14px;
    background-color: ${Color.WHITE};
    border-radius: 14px;
  `,
  Chart2: styled.div`
    padding: 14px;
    background-color: ${Color.WHITE};
    border-radius: 14px;
    width: 328px;
  `,
  Comment: styled.div`
    font-size: 10px;
    font-weight: 600;
    line-height: 14px;
    opacity: 0.5;
    margin-top: 8px;
    margin-bottom: 40px;
  `,
  Comment2: styled.div`
    font-size: 9px;
    font-weight: 500;
    line-height: 150%;
    opacity: 0.5;
    color: ${Color.BLACK};
    margin: 16px 0;
  `,
  PhoneImg: styled.img`
    width: 288px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  PhoneImg2: styled.img`
    width: 304px;
  `,
  PhoneImg3: styled.img`
    width: 288px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      width: 304px;
    }
  `,
  PersonalPlanText: styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    color: ${Color.WHITE};
    font-weight: 800;
    font-size: 20px;
    margin-top: -120px;
    line-height: 24px;
    margin-bottom: 43px;

    strong {
      color: #ffcd29;
      font-size: 30px;
      line-height: 1;
    }
  `,
}
