import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TimeInterval } from 'root-constants'
import {
  DAYS_IN_PERIOD,
  PeriodCountByDays,
} from 'modules/subscriptions/constants'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export const PlanItemVariant4: React.FC<ITrialSelectPlanItemProps> = ({
  subscription: { durationDays, daily, fullPrice, oldPrices },
  currency,
  id,
  isDefault,
  isSelected,
  isNonPayerFlow,
}) => {
  const { t } = useTranslation()

  return (
    <S.PlanItemVariant4
      isSelected={isSelected}
      isDefault={isDefault}
      key={id}
      isNonPayerFlow={isNonPayerFlow}
    >
      {isDefault && (
        <S.MostPopularBadge2>{t`onboarding.planBlock.mostPopularBadge`}</S.MostPopularBadge2>
      )}
      <S.PlanTitle2>
        {durationDays === DAYS_IN_PERIOD[TimeInterval.WEEK] ? (
          `${durationDays}-day plan`
        ) : (
          <Trans
            i18nKey="onboarding.planBlock.planPeriod2"
            values={{ periodQuantity: PeriodCountByDays[durationDays] }}
          />
        )}
        <S.OldPricesWrapper>
          <S.StartOldPrice>
            {currency}
            {oldPrices.fullPrice}
          </S.StartOldPrice>
          <S.CurrentPrice>
            {currency}
            {fullPrice}
          </S.CurrentPrice>
        </S.OldPricesWrapper>
      </S.PlanTitle2>
      <S.PricesWrapper>
        {daily && (
          <S.PricePerPeriod2 isSelected={isSelected} isDefault={isDefault}>
            <S.OldIntervalPrice2>
              {currency}
              {oldPrices.daily}
            </S.OldIntervalPrice2>
            <strong>
              {currency}
              {daily}
            </strong>
            {t`onboarding.planBlock.per`}&nbsp;
            {t`onboarding.planBlock.day`}
          </S.PricePerPeriod2>
        )}
      </S.PricesWrapper>
    </S.PlanItemVariant4>
  )
}
