import { Button } from 'components/buttons/Button'
import Close from 'assets/images/sprite/close-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants'
import styled from 'styled-components'
import {
  basePageStyles,
  baseTextStyles,
  desktopMediaQuery,
  fullViewMinHeight,
} from 'pages/styles'

export const StyledLastChanceOfferPage = {
  Content: styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 16px;

    @media ${desktopMediaQuery} {
      width: 360px;
    }
  `,
  UrgeWrapper: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    background-color: ${Color.ERROR};
    height: 100%;
  `,
  UrgeWrapper2: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    background-color: ${Color.ERROR};
    height: 100%;

    @media (max-width: 430px) {
      justify-content: center;
    }
  `,
  UrgeContent2: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }

    @media (max-width: 430px) {
      justify-content: center;
    }
  `,
  UrgeContent: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  UrgeTitle: styled.h2`
    align-self: center;
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    color: ${Color.YELLOW_LIGHT};
  `,
  Subtitle: styled.h4`
    ${baseTextStyles};
    margin-bottom: 16px;
    align-self: center;
    color: ${Color.WHITE};

    strong {
      font-weight: 800;
    }
  `,
  Subtitle2: styled.h4`
    ${baseTextStyles};
    align-self: center;
    color: ${Color.WHITE};

    strong {
      font-weight: 800;
    }
  `,
  WhiteButton: styled(Button)`
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    margin-top: 26px;
    margin-bottom: 20px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    max-width: 328px;
  `,
  UrgeImage: styled.img`
    margin-bottom: 16px;
  `,
  CloseIcon: styled(Close)`
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;
  `,
  ButtonText: styled.span`
    width: 100%;
  `,
  SelectedText: styled.span`
    color: ${Color.YELLOW_ORANGE};
  `,
}
