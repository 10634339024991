import { TDynamicPageComponent } from 'models/common.model'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { PageId } from 'constants/pages'
import { Trans, useTranslation } from 'react-i18next'
import { IEmailPageProps } from 'modules/email/types'
import { getFilteredEmailDomains } from 'modules/email/helpers'
import {
  selectCurrentYear,
  selectIsFetching,
} from 'root-redux/common/common-selects'
import { StyledEmailVariant as S } from './Email.styles'
import { EmailWrapper } from '../EmailWrapper'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { InputEmailVariant2 } from './components/email-input/EmailInputVariant2'
import { PrivacyNoteVariant2 } from './components/PrivacyNoteVariant2'
import { useHandleClickOutside } from 'hooks/useHandleClickOutside'

const EmailComponent: React.FC<IEmailPageProps> = ({
  email,
  onChange,
  onBlur,
  onSubmit,
  onClearButtonClick,
}) => {
  const isFetching = useSelector(selectIsFetching)
  const currentYear = useSelector(selectCurrentYear)
  const { t } = useTranslation()
  const [emailHasFocus, setEmailHasFocus] = useState(false)
  const [isAutoCompleteShown, setIsAutoCompleteShown] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLDivElement | null>(null)
  const isButtonDisabled = useMemo(
    () => !email.value || !email.isValid || isFetching,
    [email.isValid, email.value, isFetching],
  )
  const placeholder = useMemo(
    () => (emailHasFocus || !!email.value ? 'meow@catmail.com' : 'Email'),
    [emailHasFocus, email.value],
  )

  const filteredEmailDomains = useMemo(
    () => getFilteredEmailDomains(email.value),
    [email.value],
  )

  useHandleClickOutside(dropdownRef, setEmailHasFocus, buttonRef, onSubmit)

  useEffect(() => {
    filteredEmailDomains.length && emailHasFocus
      ? setIsAutoCompleteShown(true)
      : setIsAutoCompleteShown(false)
  }, [email.isValid, email.value, emailHasFocus, filteredEmailDomains.length])

  return (
    <S.Wrapper2>
      <S.Content2>
        <S.Title2>
          <Trans i18nKey="onboarding.email.title" components={[<br />]} />
        </S.Title2>
        <S.Text2>{t`onboarding.email.enterEmail`}</S.Text2>
        <S.InputWrapper ref={dropdownRef}>
          <InputEmailVariant2
            labelName={t`onboarding.email.email`}
            placeholder={placeholder}
            value={email.value}
            isValid={email.isValid}
            validationText={email.validationText as string}
            moveLabelToTop={emailHasFocus || !!email.value}
            name="email"
            type="email"
            autoComplete="off"
            onChange={onChange}
            onFocus={() => setEmailHasFocus(true)}
            onBlur={(e) => {
              onBlur(e)
            }}
            handleClearButtonClick={onClearButtonClick}
          />
          {isAutoCompleteShown && (
            <S.AutoCompleteWrapper2>
              {filteredEmailDomains.map((value) => (
                <S.AutoCompleteButton
                  type="button"
                  key={value}
                  value={value}
                  onClick={(e) => {
                    onChange({
                      target: {
                        value: (e.currentTarget as HTMLButtonElement).value,
                      },
                    })
                    setEmailHasFocus(false)
                  }}
                >
                  <S.AutoCompleteButtonTextWrapper>
                    {value}
                  </S.AutoCompleteButtonTextWrapper>
                </S.AutoCompleteButton>
              ))}
            </S.AutoCompleteWrapper2>
          )}
        </S.InputWrapper>
        <PrivacyNoteVariant2 />
        <S.StickyBlockWrapper2
          data-is-virtual-keypad-active={emailHasFocus}
          ref={buttonRef}
        >
          <GreenArrowButtonVariant2
            isButtonDisabled={isButtonDisabled}
            onClick={onSubmit}
            text={t`onboarding.email.getMyPlan`}
          />
          <S.RightsReserved>
            <Trans
              values={{ year: currentYear }}
              i18nKey="footer.allRightsReserved"
            />
          </S.RightsReserved>
        </S.StickyBlockWrapper2>
      </S.Content2>
    </S.Wrapper2>
  )
}

const ComponentVariant5: TDynamicPageComponent = () => (
  <EmailWrapper>{(props) => <EmailComponent {...props} />}</EmailWrapper>
)

ComponentVariant5.hasHeader = true
ComponentVariant5.id = PageId.EMAIL_3

export const EmailVariant3 = ComponentVariant5
