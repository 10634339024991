import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { GENERAL_QUESTIONS_OPTIONS_VALUES } from 'root-constants'

import shelterCat from 'assets/images/shelter-cat.png'
import noShelterCat from 'assets/images/no-shelter-cat.png'
import { PageId } from 'constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { Trans, useTranslation } from 'react-i18next'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Did you adopt your cat from a shelter?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId],
  )

  return (
    <S.Wrapper4>
      <S.Content4>
        <S.Title5>
          <Trans i18nKey="onboarding.shelter.question" components={[<br />]} />
        </S.Title5>
        <S.OptionsContainer data-is-multiple>
          <S.Option
            {...optionProps}
            value={GENERAL_QUESTIONS_OPTIONS_VALUES.YES}
          >
            <S.QuestionButtonWithImg2>
              <S.OptionImage src={shelterCat} alt="shelter cat" />
              <S.AnswerText2>{t`onboarding.common.yes`}</S.AnswerText2>
            </S.QuestionButtonWithImg2>
          </S.Option>
          <S.Option
            {...optionProps}
            value={GENERAL_QUESTIONS_OPTIONS_VALUES.NO}
          >
            <S.QuestionButtonWithImg2>
              <S.OptionImage src={noShelterCat} alt="no shelter cat" />
              <S.AnswerText2>{t`onboarding.common.no`}</S.AnswerText2>
            </S.QuestionButtonWithImg2>
          </S.Option>
        </S.OptionsContainer>
      </S.Content4>
    </S.Wrapper4>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.SHELTER_CAT_2

export const ShelterQuestionVariant2 = Component
