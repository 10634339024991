import catImg from 'assets/images/cat-cancel-2.jpg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { PLAN_TYPES } from 'root-constants'
import Discount from 'assets/images/sprite/discount-label-65.svg'
import { ScreenName } from 'services/eventLogger-service'
import { PrivacyPolicyLink } from 'modules/subscriptions/components/privacy-policy/PrivacyPolicyLink'
import { StickyHeaderVariant2 } from 'modules/subscriptions/components/sticky-header/StickyHeaderVariant2'
import { PageId } from 'constants/pages'
import { useSelector } from 'react-redux'
import { selectCurrentYear } from 'root-redux/common/common-selects'
import { StyledSubscriptions as S } from '../Subscriptions.styles'
import { PlanItemVariant4 } from 'modules/subscriptions/components/plan-blocks/PlanItemVariant4'
import { SelectPlanBlockVariant5 } from 'modules/subscriptions/components/plan-blocks/SelectPlanBlockVariant5'
import { GuaranteeVariant5 } from 'modules/subscriptions/components/guarantee/GuaranteeVariant5'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { selectCurrencySign } from 'root-redux/payment/payment-selects'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
  selectedSubscription,
}) => {
  const { t } = useTranslation()
  const currentYear = useSelector(selectCurrentYear)
  const currency = useSelector(selectCurrencySign)

  const footerQuantityText = useMemo(() => {
    if (selectedSubscription?.trialPrices.durationDays <= 7) {
      return 'Extended monthly after 1-week intro offer at the price of'
    }

    if (selectedSubscription?.trialPrices.durationDays <= 30) {
      return 'Extended monthly at the price of'
    }

    return `Extended every ${selectedSubscription?.mainPrices.periodQuantity} months at the price of`
  }, [selectedSubscription])

  return (
    <S.Wrapper2>
      <S.ContentVariant3>
        <StickyHeaderVariant2
          onButtonClick={onButtonClick}
          screenName={ScreenName.CANCEL}
          title="Last offer expires in"
        />
        <S.TitleVariant5>
          <strong>Enjoy happy relationship</strong> <br />
          and fun trainings <br />
          with your cat!
        </S.TitleVariant5>
        <S.ImageWrapper>
          <S.CatImageVariant4 src={catImg} />
        </S.ImageWrapper>
        <SelectPlanBlockVariant5
          onButtonClick={onButtonClick}
          onSelect={onSubscriptionSelect}
          SelectPlanItem={PlanItemVariant4}
          availableSubscriptions={availableSubscriptions}
          isNonPayerFlow={isNonPayerFlow}
          isCancelOffer
        />
        <GuaranteeVariant5 />
        <S.FinalBlockWrapper3>
          <S.PlanButtonWrapper2>
            <S.DiscountImage2>
              <Discount />
            </S.DiscountImage2>
            <GreenArrowButtonVariant2
              onClick={onButtonClick}
              text={t`commonComponents.getMyPlan`}
            />
          </S.PlanButtonWrapper2>
          <S.AgreementWithDescription2>
            {footerQuantityText}&nbsp;
            {currency}
            {selectedSubscription?.mainPrices.fullPrice}
            <br />
            {t`footer.disclaimer`}&nbsp;
            <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>
            &nbsp;|&nbsp;
            <PrivacyPolicyLink />
            <br />
            &nbsp;
            <Trans
              values={{ year: currentYear }}
              i18nKey="footer.allRightsReserved"
            />
          </S.AgreementWithDescription2>
        </S.FinalBlockWrapper3>
      </S.ContentVariant3>
    </S.Wrapper2>
  )
}

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.CANCEL}
    planType={PLAN_TYPES.PURCHASE}
    isNonPayerFlow
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.CANCEL_OFFER_4

export const CancelOfferVariant4 = Component
