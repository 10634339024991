import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { StripeElementStyle } from '@stripe/stripe-js'
import { Color, MediaBreakpoint } from 'root-constants'
import styled, { css } from 'styled-components'
import warningIcon from 'assets/images/warning.svg'
import { baseMediaStyles } from 'pages/styles'
import { Button } from 'components/buttons/Button'

export const stripeElementStyle: StripeElementStyle = {
  base: {
    color: `${Color.PRIMARY_TEXT}`,
    fontFamily: 'Nunito, sans-serif',
    fontSize: '15px',
    fontWeight: 700,
    lineHeight: '48px',
    fontSmoothing: 'antialiased',
    '::placeholder': {
      color: 'rgba(176, 160, 153, 0.5)',
      fontWeight: 600,
    },
  },
  invalid: {
    color: Color.ERROR,
  },
}

const commonInputStyles = css`
  color: ${Color.PRIMARY_TEXT};
  height: 48px;
  background-color: transparent;
  line-height: 48px;
  border: none;
  font-size: 15px;
  font-weight: 700;
  padding: 0 12px;
`

const commonInputContainerStyles = css`
  ${baseMediaStyles};
  position: relative;
  height: 48px;
  margin-bottom: 8px;
  background-color: #fffaf7;
  border: 1px solid #ddccc5;
  border-radius: 8px;

  &[data-invalid='true'] {
    border: 1px solid ${Color.ERROR};
    background-color: rgba(${Color.ERROR_RGB}, 0.1);

    &:before {
      content: url("${warningIcon}");
      position: absolute;
      right: 12px;
      top: 14px;
    }
  }

  &[data-has-label='true'] {
    position: relative;
    margin-top: 22px;
  }
`

const commonInputContainerStylesVariant2 = css`
  ${commonInputContainerStyles};
  background-color: ${Color.WHITE};
  border: none;
`

const commonInputContainerStylesVariant3 = css`
  ${commonInputContainerStyles};
  background-color: ${Color.WHITE};
  border: none;
  outline: none;
`

export const StyledStripePaymentForm = {
  Form: styled.form`
    ${baseMediaStyles};
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  InputsContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  `,
  Label: styled.div`
    position: absolute;
    top: -22px;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
  `,
  Label2: styled.div`
    position: absolute;
    top: -22px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: ${Color.BLACK};
  `,
  PromptButton: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: #c9c2c1;
    color: #ffffff;
    margin-left: 3px;
    cursor: pointer;
  `,
  CardNumberContainer: styled.div`
    ${commonInputContainerStyles};
  `,
  CardNumberContainerVariant2: styled.div`
    ${commonInputContainerStylesVariant2};
  `,
  CardNumberContainerVariant3: styled.div`
    ${commonInputContainerStylesVariant3};
  `,
  CvvExpiryInputContainer: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  CardExpiryContainer: styled.div`
    ${commonInputContainerStyles};
    width: 141px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 168px;
    }
  `,
  CardExpiryContainerVariant2: styled.div`
    ${commonInputContainerStylesVariant2};
    width: 141px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 168px;
    }
  `,
   CardExpiryContainerVariant3: styled.div`
    ${commonInputContainerStylesVariant3};
    width: 141px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 168px;
    }
  `,
  CardCvvContainer: styled.div`
    ${commonInputContainerStyles};
    width: 141px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 168px;
    }
  `,
  CardCvvContainerVariant2: styled.div`
    ${commonInputContainerStylesVariant2};
    width: 141px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 168px;
    }
  `,
  CardCvvContainerVariant3: styled.div`
    ${commonInputContainerStylesVariant3};
    width: 141px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 168px;
    }
  `,
  CardNumberElement: styled(CardNumberElement)`
    ${commonInputStyles};
  `,
  CardExpiryElement: styled(CardExpiryElement)`
    ${commonInputStyles};
  `,
  CardCvcElementContainer: styled.div`
    position: relative;
  `,
  CardCvcElement: styled(CardCvcElement)`
    ${commonInputStyles};
  `,
  CardholderInputContainer: styled.div`
    ${commonInputContainerStyles};
  `,
  CardholderInputContainerVariant2: styled.div`
    ${commonInputContainerStylesVariant2};
  `,
  CardholderInput: styled.input`
    ${commonInputStyles};
    font-family: 'Nunito', sans-serif;
    outline: none;
    box-shadow: none;
    width: 100%;

    ::placeholder {
      color: rgba(176, 160, 153, 0.5);
      font-weight: 600;
    }
  `,
  ErrorsContainer: styled.div`
    width: 100%;
    margin: 24px 0;
  `,
  ErrorText: styled.div`
    width: 100%;
    position: relative;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #ff7070;
    padding-left: 27px;
    margin-bottom: 8px;

    &:before {
      content: url("${warningIcon}");
      position: absolute;
      left: 0;
      top: 0;
    }
  `,
  PaymentButtonHint: styled.p`
    width: 100%;
    text-align: center;
  `,
  ContinueButton: styled(Button)`
    background-color: ${Color.PRIMARY};

    &:disabled {
      background-color: ${Color.PRIMARY};
      box-shadow: none;
      opacity: 0.5;
    }
  `
}
