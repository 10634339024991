import { RefObject, useEffect } from 'react'
import { EMAIL_REGEXP } from 'root-constants'

export const useHandleClickOutside = (
  refObject: RefObject<HTMLElement>,
  setEmailHasFocus: (value: boolean) => void,
  buttonRef?: RefObject<HTMLElement>,
  onSubmit?: (inputValue?: string) => void,
): void => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        onSubmit &&
        buttonRef &&
        buttonRef.current?.contains(e.target as Node)
      ) {
        const inputValue = (
          document.getElementById('user-email') as HTMLInputElement
        ).value

        if (inputValue && EMAIL_REGEXP.test(inputValue) && inputValue !== '') {
          onSubmit(inputValue)
        }

        return
      }

      if (refObject && !refObject.current?.contains(e.target as Node)) {
        setEmailHasFocus(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])
}
