import React, { InputHTMLAttributes, useCallback, useState } from 'react'
import { StyledInput as S } from './styles'

type TProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'required'> & {
  labelName?: string
  hasVisibilityControl?: boolean
  isValid?: boolean
  validationText?: string
  hintText?: string
  type?: string
  handleClearButtonClick?: () => void
  moveLabelToTop?: boolean
}

export const InputEmailVariant2: React.FC<TProps> = ({
  labelName,
  hasVisibilityControl = false,
  isValid = true,
  validationText = '',
  hintText = '',
  type = 'text',
  handleClearButtonClick,
  moveLabelToTop = false,
  ...props
}) => {
  const [clearButtonVisible, setClearButtonVisible] = useState(false)

  const handleBlur = useCallback(
    (e) => {
      const target = e.target as any
      target.value = target.value.trim()

      if (props.onChange) {
        props.onChange(e)
      }

      if (props.onBlur) {
        props.onBlur(e)
      }
    },
    [props],
  )

  const handleChange = useCallback(
    (e) => {
      const target = e.target as any
      target.value = target.value.trim()

      setClearButtonVisible(false)

      if (target.value) {
        setClearButtonVisible(true)
      }

      if (props.onChange) {
        props.onChange(e)
      }
    },
    [props],
  )

  const clearButtonHandle = useCallback(() => {
    if (handleClearButtonClick) {
      handleClearButtonClick()

      setClearButtonVisible(false)
    }
  }, [handleClearButtonClick])

  return (
    <S.Wrapper2
      data-is-valid={isValid}
      data-validation-text={validationText}
      data-hint-text={hintText}
      data-is-warning-icon-shown={!hasVisibilityControl}
    >
      <S.Label2 moveLabelToTop={moveLabelToTop}>{labelName}</S.Label2>
      <S.Input2
        required
        type={type}
        {...props}
        onBlur={handleBlur}
        onChange={handleChange}
        isLabelOnTop={moveLabelToTop}
      />
      {clearButtonVisible && <S.ClearIcon2 onClick={clearButtonHandle} />}
    </S.Wrapper2>
  )
}
