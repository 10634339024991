import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledComponents as S } from './styles'

export const PrivacyNote: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Note>
      <S.LockImg />
      <S.Description>{t`commonComponents.privacyProtect`}</S.Description>
    </S.Note>
  )
}
