import styled from 'styled-components'
import Arrow from 'assets/images/sprite/back-button.svg'
import BackSvg from 'assets/images/sprite/back.svg'

export const StyledBackButton = {
  BackButton: styled.button`
    all: unset;
    position: absolute;
    left: 12px;
    top: 13px;
    z-index: 2;
  `,
  BackButtonImg: styled(Arrow)`
    height: 14px;
  `,
  Back: styled.button`
    width: 34px;
    height: 34px;
    background-color: white;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
  `,
  Image: styled(BackSvg)`
    width: 100%;
    height: 100%;
  `,
}
