import { Option, OptionType } from 'components/option/Option'
import { ITrialSelectPlanItemProps } from 'modules/subscriptions/types'
import React, { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import Discount from 'assets/images/sprite/discount-label.svg'
import Discount65 from 'assets/images/sprite/discount-label-65.svg'
import { ISubscription } from 'models/suscriptions.model'
import { selectSubscription } from 'root-redux/payment/payment-selects'
import { useTranslation } from 'react-i18next'
import { LOCALES } from 'root-constants'
import { StyledPlanBlock as S } from './PlanBlock.styles'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'

type TProps = {
  onButtonClick: () => void
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<React.PropsWithChildren<ITrialSelectPlanItemProps>>
  availableSubscriptions: ISubscription[]
  isNonPayerFlow: boolean
  isCancelOffer?: boolean
}

export const SelectPlanBlockVariant5: React.FC<
  React.PropsWithChildren<TProps>
> = ({
  onButtonClick,
  onSelect,
  SelectPlanItem,
  availableSubscriptions,
  isNonPayerFlow,
  isCancelOffer = false,
}) => {
  const { t, i18n } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)

  const isLongLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  const isSubscriptionSelected = useCallback(
    (subscription) => selectedSubscription?.id === subscription.id,
    [selectedSubscription?.id],
  )

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = availableSubscriptions.find(
        (subscription) => subscription.id === value,
      )
      onSelect(checkedSubscription)
    },
    [onSelect, availableSubscriptions],
  )

  return (
    <S.SelectPlanCardVariant5 id={SUBSCRIPTION_SECTION_IDS.chooseMyPlan}>
      <S.CardTitleWhite2>{t`commonComponents.chooseYourPlan`}</S.CardTitleWhite2>
      <S.PlanItems>
        {availableSubscriptions.map((subscription) => (
          <Option
            type={OptionType.RADIO}
            name="subscription"
            value={subscription.id}
            key={subscription.id}
            onChange={handleChange}
          >
            <SelectPlanItem
              currency={subscription.currencySign}
              subscription={subscription.trialPrices}
              id={subscription.id}
              isDefault={subscription.isDefault}
              isSelected={isSubscriptionSelected(subscription)}
              isNonPayerFlow={isNonPayerFlow}
            />
          </Option>
        ))}
      </S.PlanItems>
      <div id={SUBSCRIPTION_SECTION_IDS.startMyPlanButton}>
        {availableSubscriptions.length > 0 && (
          <S.PlanButtonWrapper>
            <S.DiscountImage2 isLongLocale={isLongLocale}>
              {isCancelOffer ? <Discount65 /> : <Discount />}
            </S.DiscountImage2>
            <GreenArrowButtonVariant2
              onClick={onButtonClick}
              text="Get my plan"
            />
          </S.PlanButtonWrapper>
        )}
      </div>
    </S.SelectPlanCardVariant5>
  )
}
