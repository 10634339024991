import { Color, MediaBreakpoint } from 'root-constants'
import styled, { css } from 'styled-components'
import { Option } from 'components/option/Option'
import { OptionVariant2 } from 'components/option/OptionVariant2'
import {
  baseColumnStyles,
  baseColumnStylesVariant2,
  baseMediaStylesVariant2,
  basePageStyles,
  baseTextStyles,
  baseTitleStyles,
  desktopMediaQuery,
  fullViewHeight,
  fullViewMinHeight,
} from '../styles'

const breedButton = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 27px;
  padding: 8px 15px;
  color: ${Color.PRIMARY_TEXT};
  background-color: transparent;
  border: 1px solid #b0a099;
  border-radius: 44px;
`

const styledTitle = css`
  font-size: 20px;
  line-height: 140%;
  font-weight: 800;
  text-align: center;
  color: ${Color.BLACK};
`

type TOptionContentProps = {
  background: string
  borderColor: string
}

type TOptionTextProps = {
  background: string
}

export const StyledQuestion = {
  WelcomeWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.BEIGE};
    padding-top: 75px;

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.BEIGE};
    padding-top: 96px;

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  Wrapper2: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    background-color: ${Color.BEIGE};
    padding-top: 96px;
    height: 100%;
    background-size: 100%;

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  Wrapper3: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: #8851b6;
    padding-top: 48px;
    top: 48px;
    height: calc(var(--full-height, 100dvh) - 48px);

    @media ${desktopMediaQuery} {
      padding-top: 112px;
    }
  `,
  Wrapper4: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    background-color: ${Color.WHITE};
    padding-top: 98px;
    height: 100%;
    background-size: 100%;

    @media ${desktopMediaQuery} {
      padding-top: 106px;
    }
  `,
  SexQuestionWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: #8851b6;
    justify-content: center;
  `,
  Content: styled.div`
    ${baseColumnStyles};
  `,
  Content2: styled.div`
    ${baseColumnStyles};
    margin-bottom: 85px;
  `,
  Content3: styled.div`
    ${baseColumnStylesVariant2};
  `,
  Content4: styled.div`
    ${baseColumnStylesVariant2};
    margin-bottom: 110px;
  `,
  ContentWithSlider: styled.div`
    ${baseColumnStyles};

    @media ${desktopMediaQuery} {
      width: 360px;
      overflow-x: hidden;
    }
  `,
  Title: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;
    margin-top: 8px;
    min-height: 72px;
    display: flex;
    align-items: center;
  `,
  Title4: styled.h2`
    font-size: 28px;
    line-height: 140%;
    font-weight: 900;
    text-align: center;
    color: ${Color.BLACK};
    margin-bottom: 8px;
  `,
  Question: styled.h2`
    ${baseTitleStyles};
    text-align: center;
    color: ${Color.WHITE};
  `,
  PlanTitle: styled.h2`
    font-size: 30px;
    line-height: 41px;
    font-weight: 800;
    color: ${Color.WHITE};
    text-transform: uppercase;
    text-align: center;
  `,
  Title2: styled.h2`
    ${baseTitleStyles};
    min-height: 72px;
    display: flex;
    align-items: center;
  `,
  Title3: styled.h2`
    ${baseTitleStyles};
    margin-bottom: 16px;
    margin-top: 16px;
    min-height: 72px;
    display: flex;
    align-items: center;
  `,
  Title5: styled.h2`
    ${styledTitle};
    margin: 16px 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-bottom: 24px;
    }
  `,
  Title6: styled.h2`
    ${styledTitle};
    margin: 24px 0 24px;
  `,
  Title7: styled.h2`
    ${styledTitle};
    margin: 8px 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin: 16px 0;
    }
  `,
  Option: styled(Option)`
    position: relative;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    & > input:disabled {
      opacity: 0.5;
    }
  `,
  Option2: styled(Option)`
    position: relative;

    &:not(:last-child) {
      margin-bottom: 26px;
    }

    & > input:disabled {
      opacity: 0.5;
    }
  `,
  Option3: styled(Option)`
    position: relative;

    [data-is-multiple='true'] & {
      width: 100%;
    }

    & > input:disabled {
      opacity: 0.5;
    }
  `,
  OptionContent: styled.div<TOptionContentProps>`
    height: 160px;
    width: 160px;
    background-color: ${Color.WHITE};
    border-radius: 16px;
    display: flex;
    align-items: flex-end;

    input:checked + & {
      background-color: ${({ background }) => background};
      border: 2px solid ${({ borderColor }) => borderColor};
      pointer-events: none;
      height: 160px;
      width: 160px;

      > div {
        height: 50px;
      }

      > img {
        margin-bottom: 50px;
      }
    }
  `,
  TextWrapper: styled.div<TOptionTextProps>`
    width: 160px;
    height: 52px;
    background-color: ${({ background }) => background};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    align-items: center;
  `,
  OptionText: styled.div`
    font-size: 17px;
    font-weight: 700;
    line-height: 23px;
    color: ${Color.WHITE};
  `,
  OptionVariant2: styled(OptionVariant2)`
    position: relative;
    min-width: 154px;

    > div {
      border: none;
    }
  `,
  OptionsContainer: styled.div`
    width: 100%;
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `,
  OptionsContainer2: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -6px;
  `,
  OptionsContainer3: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -6px;
  `,
  OptionsContainer4: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  `,
  QuestionButtonWithImg: styled.div`
    border-radius: 16px;
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid ${Color.BEIGE};

    input:checked + & {
      border: 2px solid ${Color.MEDIUM_GREEN};
    }
  `,
  QuestionButtonWithImg2: styled.div`
    border-radius: 16px;
    background-color: ${Color.WHITE};
    color: ${Color.BLACK};
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #d9d9d9;

    input:checked + & {
      border: 1px solid ${Color.PRIMARY};
      background-color: ${Color.PRIMARY_FIXED};

      div {
        background-color: ${Color.PRIMARY};
        color: ${Color.WHITE};
      }
    }
  `,
  OptionImage: styled.img`
    width: 135px;
    height: 64px;
    margin: 12px 12px 8px 12px;
  `,
  OptionImage2: styled.img`
    width: 162px;
    height: 102px;
  `,
  OptionDescription: styled.div`
    width: 100%;
    background: ${Color.OUTLINE_GREY};
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 0 0 16px 16px;
    color: ${Color.BLACK};
  `,
  CatAgeText: styled.p`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
  `,
  CatAgeText2: styled.h3`
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
  `,
  CatAgeDescription: styled.p`
    font-size: 11px;
    font-weight: 400;
    opacity: 0.8;
  `,
  OptionSubText: styled.p`
    display: flex;
    align-items: center;
    font-size: 11px;
    line-height: 15px;
    font-weight: 600;
    padding-bottom: 32px;
    color: ${Color.PRIMARY_TEXT_SHADOW};
  `,
  AnswerText: styled.p`
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 16px;
  `,
  AnswerText2: styled.p`
    font-weight: 800;
    font-size: 13px;
    line-height: 150%;
    margin-bottom: 16px;
  `,
  StickyBlockWrapper: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${Color.BEIGE};
    padding-bottom: 50px;
    padding-top: 8px;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }
  `,
  StickyBlockWrapper2: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${Color.BEIGE};
    padding-bottom: 56px;
    padding-top: 8px;
    width: 100%;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }
  `,
  StickyContainer: styled.div<{ shouldShowShadow?: boolean }>`
    width: 100vw;
    position: fixed;
    z-index: 1;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${Color.WHITE};
    padding: 8px 16px 56px;

    ${({ shouldShowShadow }) => shouldShowShadow && 'box-shadow: 0px -6px 20px 0 rgba(0, 0, 0, 0.08)'};
  `,
  ButtonWrapper: styled.div`
    ${baseMediaStylesVariant2};
  `,
  Link: styled.a`
    font-size: 14px;
    margin-top: 16px;
    font-weight: 700;
    line-height: 27px;
    text-decoration-line: underline;
    color: ${Color.BROWNISH_GRAY};
  `,
  SubtitleText: styled.h2`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.PRIMARY_TEXT_SHADOW};
    margin-bottom: 16px;
  `,
  Subtitle: styled.h4`
    ${baseTextStyles};
    margin-bottom: 16px;
    color: ${Color.WHITE};
    opacity: 80%;
  `,
  Subtitle2: styled.h4`
    font-size: 16px;
    line-height: 22px;
    color: ${Color.WHITE};
    text-transform: uppercase;
    opacity: 80%;
    margin-top: 8px;
  `,
  Subtitle3: styled.h4`
    font-size: 15px;
    line-height: 150%;
    font-weight: 400;
    opacity: 0.8;
    text-align: center;
    color: ${Color.BLACK};
    margin-bottom: 34px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-bottom: 42px;
    }
  `,
  QuestionWrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 52px;
    margin-top: 40px;

    @media (min-width: ${MediaBreakpoint.PHONE_WITH_390_MIN}px) {
      margin-top: 80px;
    }
  `,
  IconWrapper: styled.span`
    padding-right: 16px;
  `,
  SliderWrapper: styled.div`
    margin-bottom: 48px;

    .swiper-wrapper {
      display: flex;
    }

    .swiper-slide {
      position: relative;
      width: 200px;
      height: 300px;
    }
  `,
  SliderImage: styled.img`
    width: 200px;
    height: inherit;
    border-radius: 24px;
  `,
  WelcomeTitle: styled.h2`
    ${baseTitleStyles};
    line-height: 32px;
    margin-bottom: 16px;
    text-align: center;
    color: ${Color.WHITE};
  `,
  OptionsContainerWelcome: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  `,
  QuestionButton: styled.p`
    ${breedButton};
    white-space: nowrap;
    margin-right: 8px;
    color: ${Color.PRIMARY_TEXT};
    background-color: ${Color.WHITE};
    border: 2px solid ${Color.WHITE};

    input:checked + & {
      border: 2px solid ${Color.MEDIUM_GREEN};
    }
  `,
  QuestionButton2: styled.p`
    ${breedButton};
    white-space: nowrap;
    margin-right: 8px;
    color: ${Color.PRIMARY_TEXT};
    background-color: ${Color.WHITE};
    border: 1px solid #d9d9d9;
    padding: 10px 16px;
    font-size: 13px;
    line-height: 150%;

    input:checked + & {
      border: 1px solid ${Color.PRIMARY};
      background: ${Color.PRIMARY_FIXED};
    }
  `,
  QuestionButton3: styled.div`
    border-radius: 16px;
    background-color: ${Color.WHITE};
    color: ${Color.BLACK};
    padding: 16px;
    border: 1px solid #d9d9d9;
    width: 100%;
    font-size: 15px;
    line-height: 140%;
    font-weight: 700;

    input:checked + & {
      border: 1px solid ${Color.PRIMARY};
      background-color: ${Color.PRIMARY_FIXED};

      div {
        background-color: ${Color.PRIMARY};
        color: ${Color.WHITE};
      }
    }
  `,
  WelcomeSubtitle: styled.h4`
    ${baseTitleStyles};
    color: ${Color.PRIMARY_TEXT};
    font-weight: 700;
  `,
  WelcomeTitleVariant2: styled.h4`
    font-size: 26px;
    font-weight: 800;
    line-height: 32px;
    color: #854eb3;
    text-align: center;
    margin-bottom: 24px;
  `,
  EmojiImage: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 16px;
  `,
  CatSexImage: styled.img`
    width: 140px;
    height: 140px;
    position: absolute;
    margin-bottom: 52px;
    margin-left: 10px;
  `,
  QuestionImage: styled.img`
    width: 160px;
    height: 102px;
    margin-bottom: 24px;
  `,
  ImageWrapper: styled.div`
    svg {
      height: 56px;
      width: 56px;
    }
  `,
}
