import styled, { createGlobalStyle } from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import checkIcon from 'assets/images/green-check.svg'
import { Button } from 'components/buttons/Button'
import { basePageStyles, desktopMediaQuery } from 'pages/styles'
import { IProps } from 'modules/upsell/components/OfferDetails'
import StarsSvg from 'assets/images/sprite/question.svg'
import ChewingDog from 'assets/images/sprite/info.svg'

export const VioletBackgroundStyle = createGlobalStyle`
    body {
        background-color: ${Color.DARK_VIOLET};
        background-image: none;
    }
`

export const StyledComponents = {
  Wrapper: styled.div`
    ${basePageStyles};
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    background-color: ${Color.DARK_VIOLET};
    gap: 8px;
    height: 100%;
  `,
  Content: styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media ${desktopMediaQuery} {
      width: 343px;
    }
  `,
  OfferWrapper: styled.div<IProps>`
    ${basePageStyles};
    display: ${(props) => (props.isVisible ? 'flex' : 'none')};
    flex-direction: column;
    background-color: ${Color.DARK_VIOLET};
    margin-bottom: 98px;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      display: flex !important;
    }
  `,
  CatPDFImage: styled.img`
    width: 255px;
    min-width: 255px;
  `,
  Title: styled.h2`
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
    margin-top: -48px;
    color: ${Color.WHITE};
    margin-bottom: 8px;
  `,
  SaveButton: styled.div`
    height: 32px;
    padding: 8px 16px;
    background: ${Color.ERROR};
    border-radius: 24px;
    color: ${Color.WHITE};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 16px;
  `,
  Block: styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,
  Point: styled.div`
    padding-left: 35px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.WHITE};

    &::before {
      content: '';
      display: flex;
      position: absolute;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url("${checkIcon}");
    }
  `,
  OfferInfo: styled.div`
    display: flex;
    margin: 16px;
    flex-direction: column;
    padding: 32px 8px;
    background-color: ${Color.LILAC};
    gap: 24px;
    border-radius: 40px;
  `,
  Subtitle: styled.div`
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.WHITE};
    text-align: center;
  `,
  QuestionImg: styled(StarsSvg)`
    width: 62px;
    height: 62px;
    margin-right: 16px;
  `,
  ImgWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    z-index: 1;
    margin-bottom: -45px;
  `,
  Button: styled(Button)`
    margin: 8px 0;
    height: 50px;
  `,
  ContinueLink: styled.a`
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: ${Color.WHITE};
    opacity: 0.8;
  `,
  AdditionalInfo: styled.div`
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    color: ${Color.WHITE};
    opacity: 0.8;
    text-align: center;
    margin: 10px 0 20px;
    padding: 0 16px;
  `,
  Offer: styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: ${Color.WHITE};

    & > strong {
      color: ${Color.ERROR};
      text-decoration: line-through;
    }
  `,
  ChewingDog: styled(ChewingDog)`
    width: 16px;
    height: 16px;
    margin-bottom: 5px;
  `,
  Animation: styled.div`
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;

    svg {
      position: absolute;
      top: 8px;
      border-radius: 40px;
      width: 288px !important;
    }
  `,
  StickyBlock: styled.div`
    width: 100%;
    padding: 0 16px;
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 16px;
    bottom: 0;
    padding-bottom: 17px;
    background-color: #4a23a5;
    z-index: 2;
  `,
}
