import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { basePageStyles, fullViewHeight } from 'pages/styles'

export const StyledUrge = {
  UrgeWrapper: styled.div`
    ${basePageStyles};
    ${fullViewHeight};
    background-color: ${Color.BEIGE};
  `,
  UrgeContent: styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 328px;
    padding-top: 24px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      width: 390px;
      padding-top: 40px;
      gap: 24px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) and (max-height: 830px) {
      max-height: calc(100% - 110px);
      overflow-y: scroll;
    }
  `,
  UrgeImage: styled.img`
    width: 200px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    color: ${Color.PRIMARY_TEXT};

    span {
      color: ${Color.PURPLE};
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      font-size: 32px;
      line-height: 1.4;
    }
  `,
  ExpertInfoWrapper: styled.div`
    border-radius: 16px;
    border: 2px solid ${Color.PURPLE};
    width: 100%;
    background-color: ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      border-radius: 24px;
    }
  `,
  BlockTitle: styled.div`
    font-size: 18px;
    line-height: 1;
    color: ${Color.WHITE};
    font-weight: 600;
    background-color: ${Color.PURPLE};
    padding: 14px;
    border-radius: 14px 14px 0 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      font-size: 22px;
      padding: 14px 26px;
      border-radius: 22px 22px 0 0;
    }
  `,
  ExpertDescription: styled.div`
    font-size: 15px;
    font-weight: 500;
    color: ${Color.PRIMARY_TEXT};
    padding: 16px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      font-size: 20px;
      padding: 24px 28px 20px;
    }
  `,
  ExpertName: styled.div`
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 8px;
      width: 20px;
      height: 20px;

      @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
        width: 24px;
        height: 24px;
      }
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-bottom: 8px;
      font-size: 22px;
      line-height: 18px;
    }
  `,
  StickyBlockWrapper: styled.div`
    position: fixed;
    z-index: 1;
    bottom: 0;
    background-color: ${Color.BEIGE};
    padding-bottom: 50px;
    padding-top: 8px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    width: 100%;

    &[data-is-virtual-keypad-active='true'] {
      position: relative;
    }

    button {
      width: 328px;

      @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
        width: 390px;
      }
    }
  `,
}
