import styled from 'styled-components'
import CloseSvg from 'assets/images/sprite/close-button.svg'

export const StyledCloseButton = {
  CloseButton: styled.button`
    width: 34px;
    height: 34px;
    background-color: transparent;
    padding: 0;
    margin: 0;
    border: none;
    cursor: pointer;
  `,
  Image: styled(CloseSvg)`
    width: 10px;
    height: 10px;
  `,
}
