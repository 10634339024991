import { FlowContext } from 'components/FlowContext'
import { Option, OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'constants/pages'

import { saveUserAnswer } from 'root-redux/user/user-actions'
import { useTranslation } from 'react-i18next'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'What breed is your cat?'

const OPTIONS_VALUES_MAP = [
  'mixedBreed',
  'americanShorthair',
  'ragdoll',
  'maineCoon',
  'siamese',
  'persian',
  'exoticShorthair',
  'britishShorthair',
  'abyssinian',
  'sphynx',
  'scottishFold',
  'devonRex',
  'other',
]

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        saveUserAnswer({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{t`onboarding.breed.question`}</S.Title>

        <S.OptionsContainer2>
          {OPTIONS_VALUES_MAP.map((item) => (
            <Option {...optionProps} value={item} key={item}>
              <S.QuestionButton>
                {t(`onboarding.breed.${item}`)}
              </S.QuestionButton>
            </Option>
          ))}
          <Option {...optionProps} value="idk" key="idk">
            <S.QuestionButton>🤔 {t`onboarding.common.idk`}</S.QuestionButton>
          </Option>
        </S.OptionsContainer2>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.BREED_2

export const BreedQuestionVariant2 = Component
