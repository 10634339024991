import React from 'react'
import Laptop from 'assets/images/sprite/laptop-cat.svg'
import Home from 'assets/images/sprite/home.svg'
import Think from 'assets/images/sprite/emoji-think.svg'
import Gain from 'assets/images/sprite/gain.svg'
import AngryCatImg from 'assets/images/sprite/angry-cat-emoji.svg'
import Emoji from 'assets/images/sprite/emoji-clever.svg'
import Cat from 'assets/images/sprite/cat-emoji.svg'
import CatInLove from 'assets/images/sprite/cat-in-love-emoji.svg'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { Trans, useTranslation } from 'react-i18next'
import { StyledObtainingList as S } from './ObtainingList.styles'

export const ObtainingListVariant3: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.Wrapper2 id={SUBSCRIPTION_SECTION_IDS.obtaining}>
      <S.Title2>
        What inside <br /> in your plan?
      </S.Title2>
      <S.ListsWrapper>
        <S.ListItem>
          <Laptop />
          {t`onboarding.obtainingList.item1`}
        </S.ListItem>
        <S.ListItem>
          <CatInLove />
          {t`onboarding.obtainingList.item2`}
        </S.ListItem>
        <S.ListItem>
          <AngryCatImg />
          {t`onboarding.obtainingList.item3`}
        </S.ListItem>
        <S.ListItem>
          <Think />
          {t`onboarding.obtainingList.item4`}
        </S.ListItem>
        <S.ListItem>
          <Gain />
          {t`onboarding.obtainingList.item5`}
        </S.ListItem>
        <S.ListItem>
          <Cat />
          <Trans
            i18nKey="onboarding.obtainingList.item6"
            components={[<br />]}
          />
        </S.ListItem>
        <S.ListItem>
          <Home />
          {t`onboarding.obtainingList.item7`}
        </S.ListItem>

        <S.ListItem>
          <Emoji width="48" height="48" />
          {t`onboarding.obtainingList.item8`}
        </S.ListItem>
      </S.ListsWrapper>
    </S.Wrapper2>
  )
}
