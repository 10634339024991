import React, { ButtonHTMLAttributes, useCallback, useContext } from 'react'
import { FlowContext } from 'components/FlowContext'
import { StyledBackButton as S } from './styles'

type TProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  skipPreparePage?: boolean
}

export const FlowBackButton: React.FC<TProps> = ({
  skipPreparePage = false,
  ...props
}) => {
  const { onPreviousStep, onSkipOneStepBack } = useContext(FlowContext)

  const handleBack = useCallback(() => {
    if (skipPreparePage) {
      onSkipOneStepBack()
    } else {
      onPreviousStep()
    }
  }, [onPreviousStep, onSkipOneStepBack, skipPreparePage])

  return (
    <S.BackButton {...props} onClick={handleBack}>
      <S.BackButtonImg width={24} />
    </S.BackButton>
  )
}
