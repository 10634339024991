import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { StyledQuestionButton } from 'components/buttons/QuestionButtons.styles'
import { TDynamicPageComponent } from 'models/common.model'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PageId } from 'constants/pages'

import { saveUserAnswer } from 'root-redux/user/user-actions'
import { selectAnswers } from 'root-redux/common/common-selects'
import { getSelectedAnswers } from 'helpers/getSelectedAnswers'
import { BEHAVIOR_ISSUES_OPTIONS_VALUES } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Does your cat have any specific behavioral issues?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)
  const savedAnswers = useSelector(selectAnswers)
  const selectedAnswers = useMemo(
    () => getSelectedAnswers(savedAnswers, currentPageId),
    [savedAnswers, currentPageId],
  )
  const [answers, setAnswers] = useState<string[]>([])
  const [isNoIssues, setIsNoIssues] = useState(false)

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])

          if (value === BEHAVIOR_ISSUES_OPTIONS_VALUES.NO_ISSUES) {
            setIsNoIssues(true)
            setAnswers([BEHAVIOR_ISSUES_OPTIONS_VALUES.NO_ISSUES])
          }
        } else {
          if (
            value === BEHAVIOR_ISSUES_OPTIONS_VALUES.NO_ISSUES &&
            isNoIssues
          ) {
            setIsNoIssues(false)
          }
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)

          if (!newValues.length) {
            setIsNoIssues(false)
          }
        }
      },
    }),
    [answers, currentPageId, setIsNoIssues, setAnswers, isNoIssues],
  )

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      saveUserAnswer({
        question: QUESTION,
        answers,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep, answers])

  const isAnswerChecked = useCallback(
    (answer) => answers.includes(answer),
    [answers],
  )

  useEffect(() => {
    setAnswers(selectedAnswers as string[])
  }, [selectedAnswers])

  return (
    <S.Wrapper2>
      <S.Content>
        <S.Title>
          <Trans i18nKey="onboarding.behavior.question" components={[<br />]} />
        </S.Title>
        <S.SubtitleText>
          {t`onboarding.common.chooseAllThatApply`}:
        </S.SubtitleText>
        <StyledOption.OptionsContainer>
          <S.Option
            {...optionProps}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.NO_ISSUES}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              😻 {t`onboarding.behavior.noIssues`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(BEHAVIOR_ISSUES_OPTIONS_VALUES.SCRATCHING)}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.SCRATCHING}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              🛋️ {t`onboarding.behavior.scratching`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(
              BEHAVIOR_ISSUES_OPTIONS_VALUES.LITTER_BOX_ISSUES,
            )}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.LITTER_BOX_ISSUES}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              💩 {t`onboarding.behavior.litterBox`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(BEHAVIOR_ISSUES_OPTIONS_VALUES.AGGRESSION)}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.AGGRESSION}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              😾 {t`onboarding.behavior.aggression`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(
              BEHAVIOR_ISSUES_OPTIONS_VALUES.EXCESSIVE_MEOWING,
            )}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.EXCESSIVE_MEOWING}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              🔊 {t`onboarding.behavior.excessiveMeowing`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(
              BEHAVIOR_ISSUES_OPTIONS_VALUES.NIGHTTIME_ACTIVITY,
            )}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.NIGHTTIME_ACTIVITY}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              🌙 {t`onboarding.behavior.nightActivity`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(
              BEHAVIOR_ISSUES_OPTIONS_VALUES.SEPARATION_ANXIETY,
            )}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.SEPARATION_ANXIETY}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              😿 {t`onboarding.behavior.separationAnxiety`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(
              BEHAVIOR_ISSUES_OPTIONS_VALUES.SOCIALIZATION,
            )}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.SOCIALIZATION}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              👀 {t`onboarding.behavior.socializationIssues`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            disabled={isNoIssues}
            checked={isAnswerChecked(
              BEHAVIOR_ISSUES_OPTIONS_VALUES.STEALING_FOOD,
            )}
            value={BEHAVIOR_ISSUES_OPTIONS_VALUES.STEALING_FOOD}
          >
            <StyledQuestionButton.Wrapper
              data-has-check-icon
              data-disabled={isNoIssues}
            >
              🍗 {t`onboarding.behavior.foodStealing`}
            </StyledQuestionButton.Wrapper>
          </S.Option>
        </StyledOption.OptionsContainer>
        {!!answers.length && (
          <S.StickyBlockWrapper>
            <GreenArrowButton onClick={handleContinue} />
          </S.StickyBlockWrapper>
        )}
      </S.Content>
    </S.Wrapper2>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.BEHAVIOR_ISSUES

export const BehaviorIssuesQuestion = Component
