/* eslint-disable max-lines */
export const enum PageId {
  CAT_SEX = 'cat_sex',
  CAT_SEX_2 = 'cat_sex_2',
  CAT_AGE = 'cat_age',
  CAT_AGE_2 = 'cat_age_2',
  CAT_AGE_3 = 'cat_age_3',
  CAT_BREED = 'cat_breed',
  BREED_2 = 'cat_breed_2',
  BREED_3 = 'cat_breed_3',
  SHELTER_CAT = 'shelter_cat',
  SHELTER_CAT_2 = 'shelter_cat_2',
  CAT_COMMANDS = 'cat_commands',
  URGE_CAT = 'urge_cat',
  URGE_SOCIAL_PROOF = 'urge_social_proof',
  URGE_SOCIAL_PROOF_2 = 'urge_social_proof_2',
  REACT_NEW_PEOPLE = 'cat_react_new_people',
  NOISE_RESPONSE = 'noise_response',
  COME_WHEN_CALLED = 'cat_come_when_called',
  TOYS = 'cat_toys',
  GROOMING = 'cat_grooming',
  TOUCH_REACT = 'cat_touch_react',
  BEHAVIOR_ISSUES = 'cat_behavior_issues',
  BEHAVIOR_ISSUES_2 = 'cat_behavior_issues_2',
  URGE_IMPORTANT = 'urge_cat_important',
  CLICKER_TRAINED = 'cat_clicker_trained',
  MEDICAL_CONDITIONS = 'medical_conditions',
  ACTIVITY = 'cat_activity',
  MAIN_GOAL = 'cat_main_goal',
  TIME = 'cat_time',
  PREPARE_FLOW = 'cat_prepare_flow',
  EMAIL_MEOWZ = 'email_meowz',
  CAT_SUBSCRIPTIONS = 'cat_subscriptions',
  SUBSCRIPTIONS = 'subscriptions',
  SUBSCRIPTIONS_2 = 'cat_subscriptions_2',
  SUBSCRIPTIONS_CS = 'cat_subscriptions_cs',
  SUBSCRIPTIONS_3 = 'cat_subscriptions_3',
  UPSELL = 'upsell',
  UPSELL_CS = 'upsell_cs',
  CANCEL_OFFER = 'cat_cancel_offer',
  CANCEL_OFFER_2 = 'cat_cancel_offer_2',
  CANCEL_OFFER_4 = 'cat_cancel_offer_3',
  CANCEL_OFFER_3 = 'cancel_offer',
  LAST_CHANCE_OFFER = 'last_chance_offer',
  LAST_CHANCE_OFFER_2 = 'last_chance_offer_cat_2',
  LAST_CHANCE_OFFER_3 = 'last_chance_offer_3',
  MEOWZ_PAYMENT = 'meowz_payment',
  BODY_LANGUAGE = 'body_language',
  BODY_LANGUAGE_2 = 'body_language_2',
  URGE_2 = 'urge_cat_2',
  MAIN_GOAL_2 = 'cat_main_goal_2',
  EMAIL_2 = 'email_meowz_2',
  EMAIL_CS = 'email_meowz_cs',
  PAYMENT_2 = 'meowz_payment_2',
  PAYMENT = 'meowz_payment_3',
  PAYMENT_CS = 'meowz_payment_cs',
  WELCOME_1 = 'welcome_cat_1',
  ACCOUNT = 'account',
  DOWNLOAD = 'download',
  DOWNLOAD_PDF = 'downloadPdf',
  CAT_MAIN_GOAL_3 = 'cat_main_goal_3',
  CAT_AGRESSION = 'cat_agression',
  PLAYTIME_ATTACK = 'playtime_attack',
  CAT_WAKING_UP = 'cat_waking_up',
  URGE_SOCIAL_PROOF_3 = 'urge_social_proof_3',
  CAT_SCRATCHING = 'cat_scratching',
  CAT_BREAK_ITEMS = 'cat_break_items',
  CAT_RESPOND_TO_NO = 'cat_respond_to_no',
  CAT_TIME_2 = 'cat_time_2',
  PREPARE_FLOW_2 = 'cat_prepare_flow_2',
  EMAIL_3 = 'email_meowz_3',
  PAYMENT_4 = 'meowz_payment_4',
}
