import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import kittyImg from 'assets/images/kitten-img.png'
import youngAdultImg from 'assets/images/young-cat-img.png'
import matureImg from 'assets/images/mature-cat-img.png'
import seniorImg from 'assets/images/senior-cat-img.png'
import { PageId } from 'constants/pages'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { StyledQuestion as S } from './Questions.styles'
import { AGE_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'Choose your cat`s age'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        saveUserAnswer({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )
      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper4>
      <S.Content3>
        <S.Title4>
          PERSONALIZED <br />
          CAT TRAINING PLAN
        </S.Title4>
        <S.Subtitle3>BASED ON YOUR CAT BEHAVIOR</S.Subtitle3>
        <S.OptionsContainer data-is-multiple>
          <S.Option2 {...optionProps} value={AGE_OPTIONS_VALUES.KITTEN_GROUP}>
            <S.QuestionButtonWithImg2>
              <img src={kittyImg} />
              <S.OptionDescription>
                <S.CatAgeText2>Kitten</S.CatAgeText2>
                <S.CatAgeDescription>1-12 months</S.CatAgeDescription>
              </S.OptionDescription>
            </S.QuestionButtonWithImg2>
          </S.Option2>
          <S.Option2
            {...optionProps}
            value={AGE_OPTIONS_VALUES.ADOLESCENT_GROUP}
          >
            <S.QuestionButtonWithImg2>
              <img src={youngAdultImg} />
              <S.OptionDescription>
                <S.CatAgeText2>Young adult</S.CatAgeText2>
                <S.CatAgeDescription>1-7 years</S.CatAgeDescription>
              </S.OptionDescription>
            </S.QuestionButtonWithImg2>
          </S.Option2>
          <S.Option2 {...optionProps} value={AGE_OPTIONS_VALUES.ADULT_GROUP}>
            <S.QuestionButtonWithImg2>
              <img src={matureImg} />
              <S.OptionDescription>
                <S.CatAgeText2>Mature adult</S.CatAgeText2>
                <S.CatAgeDescription>7-10 years</S.CatAgeDescription>
              </S.OptionDescription>
            </S.QuestionButtonWithImg2>
          </S.Option2>
          <S.Option2 {...optionProps} value={AGE_OPTIONS_VALUES.SENIOR_GROUP}>
            <S.QuestionButtonWithImg2>
              <img src={seniorImg} />
              <S.OptionDescription>
                <S.CatAgeText2>Senior cat</S.CatAgeText2>
                <S.CatAgeDescription>10+ years</S.CatAgeDescription>
              </S.OptionDescription>
            </S.QuestionButtonWithImg2>
          </S.Option2>
        </S.OptionsContainer>
      </S.Content3>
    </S.Wrapper4>
  )
}

Component.progressBar = 'controlled'
Component.progressBarHidden = true
Component.hasHeader = true
Component.id = PageId.CAT_AGE_3

export const AgeQuestionVariant3 = Component
