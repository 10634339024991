import lastOfferImg from 'assets/images/cat-71-offer.jpg'
import Arrow from 'assets/images/sprite/dark-arrow.svg'
import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useContext } from 'react'
import { PageId } from 'constants/pages'
import { StyledLastChanceOfferPage as S } from './LastChanceOfferPage.styles'

const Component: TDynamicPageComponent = () => {
  const { onNextStep } = useContext(FlowContext)

  return (
    <S.UrgeWrapper2>
      <S.CloseIcon width={16} onClick={onNextStep} />
      <S.UrgeContent2>
        <S.UrgeImage src={lastOfferImg} alt="funny cat" />
        <S.Content>
          <S.UrgeTitle>Last chance offer</S.UrgeTitle>
          <S.Subtitle2>
            We are offering you &nbsp;
            <br />
            <strong>the unique 71% discount </strong>
            <br /> that you can see how your feline friend can
            <br /> improve cat&apos;s behavior and learn many
            <br /> commands and tricks in short time.
          </S.Subtitle2>
          <S.WhiteButton onClick={onNextStep}>
            <S.ButtonText>Get 71% Off</S.ButtonText>
            <Arrow width={24} />
          </S.WhiteButton>
        </S.Content>
      </S.UrgeContent2>
    </S.UrgeWrapper2>
  )
}

Component.id = PageId.LAST_CHANCE_OFFER_3

export const LastChanceOfferVariant3 = Component
