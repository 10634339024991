import { PageId } from 'constants/pages'

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  currentSearch,
}: {
  pageId: PageId
  cohort: string
  language: string
  uuid: string
  currentSearch: string
}): string => {
  const searchParams = new URLSearchParams(currentSearch)

  searchParams.set('cohort', cohort)
  searchParams.set('uuid', uuid)
  searchParams.set('lang', language)

  return `${pageId}?${searchParams.toString()}`
}
