import React, { useEffect, useRef } from 'react'
import lottie from 'lottie-web/build/player/lottie_light'
import meowzChart from 'assets/animation/meowzChart2.json'
import personalPlanImg from 'assets/images/personal-plan-cat.png'
import { useTranslation } from 'react-i18next'
import { StyledBlock as S } from './DidYouKnowBlock.styles'

export const DidYouKnowBlockVariant2: React.FC = () => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: meowzChart,
      loop: true,
    })
  }, [])

  return (
    <S.Wrapper3>
      <S.Title2>{t`subscription.didYouKnow`}</S.Title2>
      <S.Description2>
        <strong>74% of users</strong> started Meowz training program <br />
        advanced in their goals <br />
        within the first month*
      </S.Description2>
      <S.Chart2 ref={animationContainerRef} />
      <S.Comment2>{t`subscription.remark`}</S.Comment2>
      <S.PhoneImg3 src={personalPlanImg} alt="personal plan cat" />
    </S.Wrapper3>
  )
}
