import React, { useMemo } from 'react'
import { Trans } from 'react-i18next'
import { ISubscription } from 'models/suscriptions.model'
import { useSelector } from 'react-redux'
import { selectCurrencySign } from 'root-redux/payment/payment-selects'

interface IProps {
  selectedSubscription: ISubscription
}

export const FooterPriceTextWeeks: React.FC<IProps> = ({
  selectedSubscription,
}) => {
  const currency = useSelector(selectCurrencySign)

  const periodQuantity = useMemo(
    () =>
      selectedSubscription?.trialPrices.durationDays === 7
        ? 4
        : selectedSubscription?.trialPrices.durationDays / 7,
    [selectedSubscription],
  )

  return (
    <Trans
      i18nKey={
        selectedSubscription?.trialPrices.durationDays <= 7
          ? 'subscription.clearDescription.withoutCancellationTrialWeeks'
          : 'subscription.clearDescription.withoutCancellationWeeks'
      }
      values={{
        formattedPeriod: periodQuantity,
        currency,
        fullPrice: selectedSubscription?.mainPrices.fullPrice,
      }}
      components={[<strong />]}
    />
  )
}
