import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import behaviorProblemImg from 'assets/images/behavior-problem-goal.png'
import bestParentImg from 'assets/images/best-parent-goal.png'
import catPsychologyImg from 'assets/images/cat-psychology-goal.png'
import tricksImg from 'assets/images/tricks-goal.png'
import homeAdaptationImg from 'assets/images/home-adaptation-goal.png'
import otherImg from 'assets/images/other-goal.png'
import { StyledQuestionButton } from 'components/buttons/QuestionButtons.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PageId } from 'constants/pages'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { selectAnswers } from 'root-redux/common/common-selects'
import { getSelectedAnswers } from 'helpers/getSelectedAnswers'
import { GOALS_OPTIONS_VALUES } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { getCheckedScreenHeight } from 'helpers/getCheckedScreenHeight'

const QUESTION = 'How can we help you with your cat?'

const OPTIONS = [
  {
    text: 'Solve behavior problems',
    value: 'Improve cat behavior',
    image: behaviorProblemImg,
  },
  {
    text: 'Become best cat parent',
    value: 'Become best parent',
    image: bestParentImg,
  },
  {
    text: 'Understand cat psychology',
    value: 'Learn cat psychology',
    image: catPsychologyImg,
  },
  {
    text: 'Teach my cat tricks',
    value: 'Teach tricks',
    image: tricksImg,
  },
  {
    text: 'Make home adaptation easier',
    value: 'Easy home adaptation',
    image: homeAdaptationImg,
  },
  {
    text: 'Other',
    value: GOALS_OPTIONS_VALUES.OTHER,
    image: otherImg,
  },
]

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)
  const savedAnswers = useSelector(selectAnswers)
  const selectedAnswers = useMemo(
    () => getSelectedAnswers(savedAnswers, currentPageId),
    [savedAnswers, currentPageId],
  )
  const [answers, setAnswers] = useState<string[]>([])
  const [shouldShowShadow, setShouldShowShadow] = useState(
    getCheckedScreenHeight(750, 730),
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, currentPageId],
  )

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      saveUserAnswer({
        question: QUESTION,
        answers,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep, answers])

  useEffect(() => {
    setAnswers(selectedAnswers as string[])
  }, [selectedAnswers])

  useEffect(() => {
    const handleResize = () => {
      setShouldShowShadow(getCheckedScreenHeight(750, 730))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <S.Wrapper4>
      <S.Content4>
        <S.Title5>
          How can we help you <br />
          with your cat?
        </S.Title5>
        <S.OptionsContainer data-is-multiple>
          {OPTIONS.map(({ text, image, value }) => (
            <S.Option
              key={value}
              {...optionProps}
              value={value}
              checked={answers.includes(value)}
            >
              <StyledQuestionButton.Wrapper2 data-has-check-icon>
                <img src={image} />
                {text}
              </StyledQuestionButton.Wrapper2>
            </S.Option>
          ))}
        </S.OptionsContainer>
        <S.StickyContainer shouldShowShadow={shouldShowShadow}>
          <S.ButtonWrapper>
            <GreenArrowButtonVariant2
              isButtonDisabled={!answers.length}
              onClick={handleContinue}
            />
          </S.ButtonWrapper>
        </S.StickyContainer>
      </S.Content4>
    </S.Wrapper4>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CAT_MAIN_GOAL_3

export const MainGoalQuestionVariant3 = Component
