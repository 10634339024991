import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import { Button } from 'components/buttons/Button'
import { baseMediaStyles } from 'pages/styles'

export const StyledComponents = {
  Button: styled(Button)`
    ${baseMediaStyles};
    bottom: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 290px;
    }
  `,
  Button2: styled(Button)`
    width: 100%;
    bottom: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${Color.PRIMARY};
    box-shadow: none;

    &:disabled {
      background-color: #cce397;
    }
  `,
  OutlinedButton: styled(Button)`
    ${baseMediaStyles};
    bottom: 55px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${Color.WHITE};

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 290px;
    }
  `,
  ButtonText: styled.span`
    width: 100%;
  `,
  ButtonText2: styled.span`
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    line-height: 150%;
  `,
}
