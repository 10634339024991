export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export type TRequestParamValue =
  | number
  | boolean
  | string
  | (number | boolean | string)[]

export interface IRequestParams {
  [key: string]: TRequestParamValue
}

export type TRequestOptionsBody = Record<string, any> | any[] | FormData

export interface IRequestOptions {
  method?: RequestMethod
  params?: IRequestParams
  headers?: Headers
  body?: TRequestOptionsBody
  signal?: AbortSignal
}

export interface IResponseResult<T> {
  success: boolean
  status?: number
  data?: T | TResponseErrorData
}

export type TResponseErrorData = any
