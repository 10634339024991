import { Color, MediaBreakpoint } from 'root-constants'
import styled, { css } from 'styled-components'
import {
  baseMediaStyles,
  basePageStyles,
  desktopMediaQuery,
} from 'pages/styles'
import Check from 'assets/images/sprite/green-check.svg'
import LightGreenCheck from 'assets/images/sprite/light-green-check.svg'

type TStyledCancelOfferProp = {
  isPlanVisible?: boolean
}

export const baseTitle = css`
  font-size: 24px;
  line-height: 30px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 24px;
`

export const StyledSubscriptions = {
  Wrapper: styled.div`
    ${basePageStyles};
    background-color: ${Color.BEIGE};
    width: 100vw;
  `,
  Wrapper2: styled.div`
    ${basePageStyles};
    width: 100vw;
  `,
  WrapperWhite: styled.div`
    ${basePageStyles};
    background-color: ${Color.WHITE};
  `,
  Wrapper3: styled.div`
    ${basePageStyles};
    background-color: #FED34B;
  `,
  AnimationWrapper: styled.div`
    width: 100vw;
    height: auto;
    position: absolute;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  Content: styled.div`
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${desktopMediaQuery} {
      width: 360px;
    }
  `,
  Content2: styled.div<TStyledCancelOfferProp>`
    padding-top: ${({ isPlanVisible }) => (isPlanVisible ? 0 : 60)}px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${desktopMediaQuery} {
      width: 360px;
    }
  `,
  ContentVariant2: styled.div`
    padding-top: 60px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${desktopMediaQuery} {
      width: 360px;
    }
  `,
  ContentVariant3: styled.div`
    padding-top: 60px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${desktopMediaQuery} {
      width: 430px;
    }
  `,
  CatImage: styled.img`
    width: 100vw;
    min-height: 500px;
  `,
  CatImageVariant2: styled.img`
    width: 100vw;
    height: auto;
    min-height: 400px;
  `,
  CatImageVariant3: styled.img`
    width: 100vw;
    height: auto;
    min-height: 360px;
    margin-top: 86px;
  `,
  CatImageVariant4: styled.img`
    max-width: 360px;
    width: auto;
    height: auto;
    min-height: 360px;
  `,
  ImageWrapper: styled.div`
    width: 100%;
    background: #8d5fff;
    display: flex;
    justify-content: center;
  `,
  Title: styled.h2`
    text-align: center;
    position: absolute;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    top: 32px;
    width: 100%;

    strong {
      color: ${Color.VIOLET};
    }
  `,
  TitleVariant2: styled.div`
    text-align: center;
    position: absolute;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    top: 32px;
    width: 100%;

    strong {
      color: ${Color.WHITE};
    }
  `,
  TitleVariant3: styled.div<TStyledCancelOfferProp>`
    text-align: center;
    position: absolute;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    top: ${({ isPlanVisible }) => (isPlanVisible ? 32 : 92)}px;
    width: 100%;

    strong {
      color: ${Color.WHITE};
    }
  `,
  TitleVariant4: styled.h2`
    text-align: center;
    position: absolute;
    font-size: 32px;
    font-weight: 800;
    top: 32px;
    width: 100%;
  `,
  TitleVariant5: styled.div`
    text-align: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 120%;
    width: 100%;
    background: #8d5fff;
    color: ${Color.WHITE};
    padding: 40px 0 16px;

    strong {
      color: #ffcd29;
      font-weight: 900;
    }
  `,
  TitleVariant6: styled.h2`
    text-align: center;
    position: absolute;
    font-size: 32px;
    font-weight: 800;
    top: 32px;
    width: 100%;
    color: ${Color.BLACK};
  `,
  Subtitle: styled.h4`
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    top: 68px;
  `,
  CardItemsWrapper: styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    justify-content: center;
    padding: 40px 16px 20px;

    ${baseMediaStyles};
  `,
  CardItemsWrapper2: styled.div`
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    justify-content: center;
    padding: 40px 16px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      padding: 40px 24px;
    }
  `,
  CardItem: styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 13px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px dashed ${Color.BROWNISH_GRAY};
  `,
  CardItem2: styled.div`
    display: flex;
    width: 100%;
    padding-bottom: 16px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #d9d9d9;
  `,
  CheckImage: styled(Check)`
    height: 24px;
    min-width: 24px;
  `,
  LightGreenCheckImage: styled(LightGreenCheck)`
    height: 24px;
    min-width: 24px;
  `,
  Answer: styled.div`
    font-size: 20px;
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  `,
  Answer2: styled.div`
    font-size: 17px;
    font-weight: 400;
    color: ${Color.BLACK};

    strong {
      font-weight: 800;
    }
  `,
  PlanButtonWrapper: styled.div`
    position: relative;
    padding-top: 20px;
  `,
  PlanButtonWrapper2: styled.div`
    width: 100%;
    position: relative;
    padding: 20px 16px 0;
  `,
  DiscountImage: styled.div<{ isLongLocale?: boolean }>`
    position: absolute;
    ${({ isLongLocale }) => (isLongLocale ? 'right: 60px' : 'left: 240px')};
    top: 0;

    svg {
      width: 48px;
      height: 48px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      left: ${({ isLongLocale }) => (isLongLocale ? '205px' : '200px')};
    }
  `,
  DiscountImage2: styled.div`
    position: absolute;
    right: 64px;
    top: 0;

    svg {
      width: 48px;
      height: 48px;
    }
  `,
  FinalBlockWrapper: styled.div`
    width: 100%;
    background-color: ${Color.BEIGE};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    padding-bottom: 16px;
  `,
  FinalBlockWrapper2: styled.div`
    width: 100%;
    background-color: #ebe3ff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 22px;
    padding-bottom: 16px;
  `,
  FinalBlockWrapper3: styled.div`
    width: 100%;
    background-color: ${Color.BEIGE};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;
    gap: 14px;
  `,
  BadgeImage: styled.img`
    position: absolute;
    width: 122px;
    top: 502px;
    left: 30px;
  `,
  BadgeImage2: styled.img`
    position: absolute;
    width: 122px;
    top: 360px;
    left: 30px;
  `,
  Agreement: styled.p`
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.5);
    margin-top: 16px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  AgreementWithDescription: styled.p`
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: rgba(${Color.PRIMARY_TEXT_RGB}, 0.5);
    margin-top: 16px;
    padding: 0 16px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  AgreementWithDescription2: styled.p`
    font-size: 9px;
    line-height: 150%;
    text-align: center;
    color: #989898;
    padding: 0 16px;
    margin-top: 2px;

    a {
      color: inherit;
      text-decoration: underline;
    }
  `,
  IntroImageWrapper: styled.div`
    background: #FED34B;
    border-radius: 0 0 32px 32px;
  `,
}
