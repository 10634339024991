import React from 'react'
import loveCatEmoji from 'assets/images/emoji/loving-cat-emoji.png'
import thinkingEmoji from 'assets/images/emoji/thinking-emoji.png'
import angryCatEmoji from 'assets/images/emoji/angry-cat-emoji.png'
import { Option, OptionType } from 'components/option/Option'
import { StyledAnalyzingAnswersModal as S } from './AnalyzingAnswersModal.styles'

const ANSWERS = [
  { answer: 'Loves it', emoji: loveCatEmoji },
  { answer: 'Sometimes enjoys it', emoji: thinkingEmoji },
  { answer: 'Hates it', emoji: angryCatEmoji },
]

type TAnalyzingAnswersModal = {
  isModalVisible: boolean
  onChange?: (value: string) => void
  answers?: string[]
  setAnswers?: () => void
  children?: React.ReactNode
}

export const AnalyzingAnswersModalVariant2: React.FC<
  TAnalyzingAnswersModal
> = ({ isModalVisible, onChange, children, ...rest }) => (
  <S.Wrapper isVisible={isModalVisible} isMeowz3>
    <S.Content2>
      <S.Title2>{children}</S.Title2>
      {ANSWERS.map(({ answer, emoji }) => (
        <Option
          key={answer}
          type={OptionType.RADIO}
          value={answer}
          {...rest}
          onChange={() => onChange && onChange(answer)}
        >
          <S.Option2>
            <img src={emoji} alt="emoji" />
            {answer}
          </S.Option2>
        </Option>
      ))}
    </S.Content2>
  </S.Wrapper>
)
