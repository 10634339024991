import { TDynamicPageComponent } from 'models/common.model'
import lottie from 'lottie-web/build/player/lottie_light'
import chart from 'assets/animation/socialBehaviorChart.json'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { PageId } from 'constants/pages'
import crown from 'assets/images/crown-icon.svg'
import { FlowContext } from 'components/FlowContext'
import { useDispatch } from 'react-redux'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { StyledUrge as S } from './Urge.styles'

const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: chart,
      loop: true,
    })
  }, [])

  return (
    <S.UrgeWrapper4>
      <S.UrgeContent2>
        <S.Title3>
          Improve your Cat social <br /> behavior <span>x2</span> with Meowz
        </S.Title3>
        <S.ChartAnimation ref={animationContainerRef}>
          <S.ChartTitle>Cat Health, Wellbeing, Nutrition</S.ChartTitle>
        </S.ChartAnimation>
        <S.UsersInfoBlock>
          <S.InfoCircle>
            <img src={crown} alt="crown icon" />
          </S.InfoCircle>
          <S.InfoText>
            <strong>71% of Meowz users</strong> start their first lesson within
            one day
          </S.InfoText>
        </S.UsersInfoBlock>
        <S.StickyContainer>
          <S.ButtonWrapper>
            <GreenArrowButtonVariant2 onClick={handleContinue} />
          </S.ButtonWrapper>
        </S.StickyContainer>
      </S.UrgeContent2>
    </S.UrgeWrapper4>
  )
}

Component.id = PageId.URGE_SOCIAL_PROOF_3
Component.hasHeader = true

export const UrgeSocialProofVariant3 = Component
