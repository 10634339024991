import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/suscriptions.model'
import { CURRENCY, DEFAULT_CURRENCY, TimeInterval } from 'root-constants'
import { IStripeAccount } from 'models/common.model'

export const selectSubscription = (state: IAppState): ISubscription | null =>
  state.payment.selectedSubscription || null

export const selectSubscriptions = (state: IAppState): ISubscription[] | null =>
  state.payment.subscriptions || null

export const selectSubscriptionsPlansLoadedFlag = (state: IAppState): boolean =>
  state.payment.subscriptionsPlansWasLoaded

export const selectPeriodName = (state: IAppState): TimeInterval | null =>
  state.payment.selectedSubscription?.mainPrices.periodName || null

export const selectPeriodQuantity = (state: IAppState): number | null =>
  state.payment.selectedSubscription?.mainPrices.periodQuantity || null

export const selectFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.mainPrices.fullPrice || 0

export const select3DSecureIframeUrl = (state: IAppState): string =>
  state.payment.threeDSecureIframeURL

export const selectIsPaymentFlowsShown = (state: IAppState): boolean =>
  state.payment.isPaymentFlowsShown

export const selectIsStoreButtonsShown = (state: IAppState): boolean =>
  state.payment.isStoreButtonsShown

export const selectStripePublicKey = (state: IAppState): string | null =>
  state.payment.paymentConfig?.stripe.publicKey || null

export const selectTrialFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.fullPrice || 0

export const selectTrialOldFullPrice = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.oldPrices.fullPrice || 0

export const selectTrialDurationDays = (state: IAppState): number =>
  state.payment.selectedSubscription?.trialPrices.durationDays || 0

export const selectCurrency = (state: IAppState): string =>
  state.payment.selectedSubscription?.currency || DEFAULT_CURRENCY

export const selectCurrencySign = (state: IAppState): string =>
  state.payment.selectedSubscription?.currencySign || CURRENCY.USD

export const selectProductPrice = (state: IAppState): string =>
  state.payment.selectedSubscription?.productPrice || ''

export const selectStripeAccount = (state: IAppState): IStripeAccount => ({
  accountId: state.payment.paymentConfig?.stripe.accountId || '',
  accountName: state.payment.paymentConfig?.stripe.accountName || '',
})

export const selectPlanId = (state: IAppState): number =>
  state.payment.selectedSubscription?.planId || 0

export const selectPaymentClientSecret = (state: IAppState): string =>
  state.payment.paymentClientSecret

export const selectSubscriptionId = (state: IAppState): string =>
  state.payment.subscriptionId

export const selectPayPalPlanId = (state: IAppState): string =>
  state.payment.selectedSubscription?.paypalPlanId || ''

export const selectPayPalClientId = (state: IAppState): string | null =>
  state.payment.paymentConfig?.paypal.clientId || null

export const selectSuccessfulPaypalPayment = (state: IAppState): boolean =>
  state.payment.successfulPaymentWithPaypal

export const selectSupplyTitle = (state: IAppState): string =>
  state.payment.supplyTitle
