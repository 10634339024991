import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import rating from 'assets/images/rating.png'

export const StyledComment = {
  Avatar: styled.img`
    width: 48px;
  `,
  CommentWrapper: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 16px;
    width: 328px;
    padding: 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 11px;
  `,
  CommentWrapper2: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 16px;
    width: 328px;
    padding: 16px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.1);

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      width: 382px;
    }
  `,
  HeaderWrapper: styled.div`
    display: flex;
    gap: 11px;
  `,
  HeaderWrapper2: styled.div`
    display: flex;
    gap: 11px;
    margin-bottom: 12px;
  `,
  TitleWrapper: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;
  `,
  Title: styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY_TEXT};
  `,
  Title2: styled.div`
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: ${Color.BLACK};
  `,
  Date: styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: ${Color.BROWNISH_GRAY};
  `,
  Autor: styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: #989898;
  `,
  Rating: styled.div`
    background-size: contain;
    background-image: url("${rating}");
    background-repeat: no-repeat;
    width: 122px;
    height: 20px;
  `,
  DetailsWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  FlagWrapper: styled.div`
    min-width: 48px !important;
    height: 48px;
    background-color: ${Color.WHITE};
    display: inline-block;
    border-radius: 50%;
  `,
  Text: styled.div`
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
  `,
  Text2: styled.div`
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    color: ${Color.BLACK};
  `,
}
