export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const AMPLITUDE_API_KEY = '5a7981c5cbbfb0626b3bb07d863c36d9'
export const DEFAULT_COHORT = 'meowz2_1'
export const CS_COHORT = 'meowz_cs'
export const LANG_QUERY_PARAM = 'lang'
export const INITIAL_LANGUAGE = 'en'
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'CUSTOM_TOKEN'
export const MEOWZ2_1_V4 = 'meowz2_1_v4'
export const MEOWZ2_1 = 'meowz2_1'
export const MEOWZ1 = 'meowz1'
export const MEOWZ3 = 'meowz3'

export const LOCALES = {
  EN: 'en',
  ES: 'es',
  PT: 'pt',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
}

export const SUPPORTED_LOCALES: string[] = [LOCALES.EN, LOCALES.ES]

export const enum LoginMethod {
  EMAIL = 'email',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
  GOOGLE = 'google.com',
  FACEBOOK = 'facebook.com',
  APPLE = 'apple.com',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
  [LoginMethodRaw.GOOGLE]: LoginMethod.GOOGLE,
  [LoginMethodRaw.FACEBOOK]: LoginMethod.FACEBOOK,
  [LoginMethodRaw.APPLE]: LoginMethod.APPLE,
}

export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const PRIVACY_POLICY_LINKS = {
  [CurrentEnvironment.DEV]: 'https://ws-meowz.supernove8.com/privacy_policy',
  [CurrentEnvironment.STAGE]: 'https://ws-meowz.nove8.com/privacy_policy',
  [CurrentEnvironment.PROD]: 'https://meowz.app/privacy_policy',
}

export const TERMS_OF_USE_LINKS = {
  [CurrentEnvironment.DEV]: 'https://ws-meowz.supernove8.com/terms_of_use',
  [CurrentEnvironment.STAGE]: 'https://ws-meowz.nove8.com/terms_of_use',
  [CurrentEnvironment.PROD]: 'https://meowz.app/terms_of_use',
  // ['localhost']: 'https://ws-meowz.supernove8.com/terms_of_use',
}

export const CONTACT_FORM_LINKS = {
  [CurrentEnvironment.DEV]: 'https://account-meowz.gidev.xyz/contact-form',
  [CurrentEnvironment.STAGE]: 'https://account-meowz.gistage.com/contact-form',
  [CurrentEnvironment.PROD]: 'https://account.meowz.app/contact-form',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum Color {
  ERROR_RGB = '255, 97, 97',
  PRIMARY_TEXT_RGB = '74, 53, 50',
  MEDIUM_GREEN_RGB = '53, 180, 63',
  ERROR = '#FF6161',
  BG_MAIN = '#F4E8DE',
  BEIGE = '#FFF5E9',
  BEIGE_LIGHT = '#FFF6EF',
  WHITE = '#FFFFFF',
  BROWNISH_GRAY = '#B0A099',
  PRIMARY_TEXT = '#4A3532',
  PRIMARY_TEXT_SHADOW = '#4A3532CC',
  TEXT_SECONDARY = '#616161',
  COLD_GREEN = '#4bab77',
  MEDIUM_GREEN = '#35B43F',
  APPLE = '#34B340',
  LIGHT_MOSS_GREEN = '#94CE8E',
  YELLOW_ORANGE = '#F8B900',
  YELLOW_LIGHT = '#FFF192',
  POWDERY_PINK = '#EA8889',
  PURPLE = '#7879F1',
  VIOLET = '#5B5DE4',
  BLACK = '#000',
  LILAC = '#6136C6',
  DARK_VIOLET = '#4A23A5',
  GREY_FIELD_INPUT = '#FFFAF7',
  DARK_PURPLE = '#854eb3',
  RAISIN_BLACK = '#2C201F',
  GREY = '#F3F3F3',
  SHADOW_GREY = '#F7F7F7',
  PRIMARY_FIXED = '#EAF4D5',
  PRIMARY = '#98C72F',
  OUTLINE_GREY = '#F0F0F0',
  SECONDARY_PURPLE = '#8D5FFF',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 360,
  MEDIUM_PHONE = 370,
  MAX_PHONE = 431,
  MAX_PHONE_TEST = 432,
  MAX_PHONE_MIN_WIDTH_TEST = 430,
  TABLET = 520,
  PHONE_WITH_390_MIN = 390,
  PHONE_WITH_390_MAX = 389,
}

export const enum ValidationText {
  EMAIL_EXISTS = 'account.accountAlreadyExist',
  INVALID_EMAIL = 'onboarding.email.validation',
  INVALID_EMAIL_VARIANT_2 = 'Please, check email address. It looks like there might be a typo',
  EMPTY_EMAIL_ERROR = 'Please, provide your email that we can send you the training plan',
  SHORT_PASSWORD = 'account.shortPassword',
  WRONG_CONFIRMATION_PASSWORD = 'account.notEqualPassword',
  WRONG_PASSWORD = 'The password you entered is incorrect',
  WRONG_EMAIL = 'The email you entered is incorrect',
  INVALID_TEXT = `Your input doesn't look valid. Please, check`,
}

export const ONE_LINK_URL = 'https://meowz.onelink.me/8mZO/acvk0w46'
export const IDK = 'idk' // idk - i don't know

export const GENERAL_QUESTIONS_OPTIONS_VALUES = {
  NO: 'no',
  SO_SO: 'soSo',
  YES: 'yes',
  OFTEN: 'often',
  SOMETIMES: 'sometimes',
  NEVER: 'never',
}

export const CAT_COMMANDS_OPTIONS_VALUES = {
  GENTLE: 'gentle',
  FIND_IT: 'findIt',
  TARGET: 'target',
  SIT: 'sit',
  JUMP: 'jump',
  COME: 'come',
  IN_THE_BOX: 'inTheBox',
  STAND_UP: 'standUp',
  KISS_ME: 'kissMe',
  LAY_DOWN: 'layDown',
  HIGH_FIVE: 'highFive',
  TOILET: 'toilet',
}

export const NONE = 'none'
export const COMMON_ERROR = 'Something went wrong'

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum PLAN_TYPES {
  PURCHASE = 'purchase',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
}

export const DEFAULT_CURRENCY = 'usd'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0

export const TERMS_OF_USE_LINK = 'https://meowz.app/terms_of_use'
export const PRIVACY_POLICY_LINK = 'https://meowz.app/privacy_policy'

export const ENV_MAP = {
  [CurrentEnvironment.DEV]: 'ws-woofz.supernove8.com',
  [CurrentEnvironment.STAGE]: 'ws-woofz.nove8.com',
  [CurrentEnvironment.PROD]: 'woofz.academy',
}

export const enum StripeFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
  EMAIL = 'email',
}

export const EMPTY_FIELD_ERROR = `can't be blank`

export const DEFAULT_WEB_FUNNELS_CONFIG_FILE = {
  signUpMethods: {
    chooseOtherMethodsShown: true,
    otherMethodsShown: {
      facebook: true,
      apple: true,
      google: true,
    },
  },
  paypalMethodShown: true,
  allRightsReservedYear: '2025',
}

export const enum FirebaseRemoteConfigKey {
  WEB_FUNNELS_CONFIG_FILE = 'web_funnels_config_file',
  ABTEST_WOOBE_1378_MEOWZ_FEEDBACK_SCREEN2 = 'abtest_woobe_1378_meowz_feedback_screen2',
}

export const COHORT_KEYS_MAP = {
  [MEOWZ2_1]: FirebaseRemoteConfigKey.ABTEST_WOOBE_1378_MEOWZ_FEEDBACK_SCREEN2,
}

export const enum FirebaseRemoteConfigTestGroup {
  ABTEST_WOOBE_1378_B_CIRCLE_SCREEN = 'abtest_woobe_1378_b_circle_screen',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const PDF_CAT_BOOK_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/dd091d04963a2377056e6fd3117e4173b04f2d66b1858b1cd36be7a8ca0cbf44/Meowz+-+%D1%81at+training+%26+care+%281%29.pdf'

export const PDF_CAT_BOOK_LINK_ES =
  'https://cdn-prod-unified-content.gismart.xyz/dd091d04963a2377056e6fd3117e4173b04f2d66b1858b1cd36be7a8ca0cbf44/Meowz+-+Cuida+y+entrena+a+tu+gato.pdf'

export const firebaseConfig = {
  apiKey: 'AIzaSyAJpq22YSehG4Tf44pHIpRhBJY3irczxBM',
  authDomain: 'meowz-d84a0.firebaseapp.com',
  projectId: 'meowz-d84a0',
  storageBucket: 'meowz-d84a0.appspot.com',
  messagingSenderId: '1098149029047',
  appId: '1:1098149029047:web:b1e3d2249a7e3715bd5932',
  measurementId: 'G-MLEEENFZ6G',
}

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const CURRENCY = {
  GBP: '£',
  BRL: 'R$',
  CAD: 'C$',
  AUD: 'AU$',
  EUR: '€',
  USD: '$',
}
