import React, { useEffect, useMemo, useRef } from 'react'
import guaranteeImage from 'assets/images/cat-guarantee-2.png'
import NewbieBubble from 'assets/images/sprite/message.svg'
import lottie from 'lottie-web/build/player/lottie_light'
import trainingProgress from 'assets/animation/trainingProgress.json'
import { CONTACT_FORM_LINKS, LOCALES, TERMS_OF_USE_LINKS } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { Trans, useTranslation } from 'react-i18next'
import { StyledGuarantee as S } from './Guarantee.styles'

export const GuaranteeVariant5: React.FC = () => {
  const { t, i18n } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const env = getCurrentEnv()

  const isLongLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: trainingProgress,
      loop: true,
    })
  }, [])

  return (
    <S.WrapperVariant4>
      <S.CatImageVariant2 src={guaranteeImage} />
      <S.Title2>
        <Trans i18nKey="onboarding.guarantee.title" />
      </S.Title2>
      <S.Text4>
        {t`onboarding.guarantee.text`}
        <S.InnerLink target="_blank" to={TERMS_OF_USE_LINKS[env]}>
          &nbsp;{t`commonComponents.termsOfUse`}
        </S.InnerLink>
        &nbsp;{t`commonComponents.contact`}&nbsp;
        <S.InnerLink to={CONTACT_FORM_LINKS[env]} target="_blank">
          Support.
        </S.InnerLink>
      </S.Text4>
      <S.ChartWrapper>
        <S.ChartTitle2>{t`onboarding.guarantee.trackProgress`}</S.ChartTitle2>
        <S.Chart ref={animationContainerRef} style={{ width: '330px' }} />
        <S.NowText2>{t`onboarding.guarantee.now`}</S.NowText2>
        <S.TextPlan2 isLongLocale={isLongLocale}>With our plan</S.TextPlan2>
        <S.ExpertBubble />
        <S.NewbieBubble>
          <NewbieBubble width={69} height={30} />
          <S.NewbieTitle2>{t`onboarding.guarantee.newbie`}</S.NewbieTitle2>
        </S.NewbieBubble>
      </S.ChartWrapper>
    </S.WrapperVariant4>
  )
}
