import React, { useMemo } from 'react'
import { TSupply } from 'modules/subscriptions/types'
import { StyledPlanBlock as S } from './PlanBlock.styles'

export interface ISelectPlanItemCsProps {
  onButtonClick: (value: any, title: string) => void
  supply: TSupply
}

export const PlanItemVariantCs: React.FC<ISelectPlanItemCsProps> = ({
  supply: {
    fullPrice,
    oldPrice,
    id,
    title,
    subtitle,
    image,
    rate,
    rateCount,
    rateImage,
    isDeal,
    isBestseller,
  },
  onButtonClick,
}) => {
  const fullTitle = useMemo(
    () => [title, subtitle].join(' '),
    [title, subtitle],
  )

  return (
    <S.PlanItemCs key={id}>
      {(isDeal || isBestseller) && (
        <S.SupplyMarker deal={isDeal}>
          {isDeal ? 'Deal' : 'Bestseller'}
        </S.SupplyMarker>
      )}
      <S.PlanItemImage src={image} alt="supplies" />
      <S.PlanTitleCs>
        {title}
        <S.PlanSubtitleCs>{subtitle}</S.PlanSubtitleCs>
      </S.PlanTitleCs>
      <S.RatingContainer>
        {rateImage}
        <S.Rate>
          {rate} <span>{rateCount}</span>
        </S.Rate>
      </S.RatingContainer>
      <S.OldPricesWrapperCs>
        <S.CurrentPriceCs>${fullPrice}</S.CurrentPriceCs>
        <S.StartOldPriceCs>${oldPrice}</S.StartOldPriceCs>
      </S.OldPricesWrapperCs>
      <S.PurchaseButton onClick={() => onButtonClick(id, fullTitle)}>
        <S.CartIcon />
        Purchase
      </S.PurchaseButton>
    </S.PlanItemCs>
  )
}
