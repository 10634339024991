import React from 'react'
import checkIcon from 'assets/images/check-on-light-green.svg'

import { StyledProgressBar as S } from './ProgressBar.styles'

type TProps = {
  value: number
  isInProgress?: boolean
  isFinished?: boolean
  children?: React.ReactNode
}

export const ProgressBarVariant2: React.FC<TProps> = ({
  value,
  isInProgress,
  isFinished,
  children,
}) => (
  <S.Wrapper>
    <S.Description2 isInProgress={isInProgress} isFinished={isFinished}>
      <S.Text>{children}</S.Text>
      {isFinished ? (
        <S.CheckIcon src={checkIcon} />
      ) : (
        <S.Value>{value}%</S.Value>
      )}
    </S.Description2>
    <S.ProgressWrapper>
      <S.Progress value={value} max={100} />
    </S.ProgressWrapper>
  </S.Wrapper>
)
