import { Option, OptionType } from 'components/option/Option'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import Discount from 'assets/images/sprite/discount-label-75.svg'
import { ISubscription } from 'models/suscriptions.model'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { ISelectPlanItemProps } from 'modules/subscriptions/components/plan-blocks/PlanItem'
import { selectSubscription } from 'root-redux/payment/payment-selects'
import { StyledPlanBlock as S } from './PlanBlock.styles'

type TProps = {
  onButtonClick: () => void
  onSelect: (subscription?: ISubscription) => void
  SelectPlanItem: React.FC<React.PropsWithChildren<ISelectPlanItemProps>>
  availableSubscriptions: ISubscription[]
  isNonPayerFlow: boolean
}

export const SelectPlanBlockVariant2: React.FC<
  React.PropsWithChildren<TProps>
> = ({
  onButtonClick,
  onSelect,
  SelectPlanItem,
  availableSubscriptions,
  isNonPayerFlow,
}) => {
  const selectedSubscription = useSelector(selectSubscription)
  const { t } = useTranslation()

  const isSubscriptionSelected = useCallback(
    (subscription) => selectedSubscription?.id === subscription.id,
    [selectedSubscription?.id],
  )

  const handleChange = useCallback(
    (value) => {
      const checkedSubscription = availableSubscriptions.find(
        (subscription) => subscription.id === value,
      )
      onSelect(checkedSubscription)
    },
    [onSelect, availableSubscriptions],
  )

  return (
    <S.SelectPlanCardVariant2 id={SUBSCRIPTION_SECTION_IDS.chooseMyPlan}>
      <S.CardTitle>{t`commonComponents.chooseYourPlan`}</S.CardTitle>
      {availableSubscriptions.map((subscription) => (
        <Option
          type={OptionType.RADIO}
          name="subscription"
          value={subscription.id}
          key={subscription.id}
          onChange={handleChange}
        >
          <SelectPlanItem
            currency={subscription.currencySign}
            subscription={subscription.mainPrices}
            id={subscription.id}
            isDefault={subscription.isDefault}
            isSelected={isSubscriptionSelected(subscription)}
            isNonPayerFlow={isNonPayerFlow}
          />
        </Option>
      ))}
      <S.PlanButtonWrapper>
        <S.DiscountImage>
          <Discount />
        </S.DiscountImage>
        <GreenArrowButton onClick={onButtonClick} text="Start My Plan" />
      </S.PlanButtonWrapper>
    </S.SelectPlanCardVariant2>
  )
}
