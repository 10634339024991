import { FlowContext } from 'components/FlowContext'
import { Option, OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { StyledQuestionButton } from 'components/buttons/QuestionButtons.styles'
import { TDynamicPageComponent } from 'models/common.model'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CAT_COMMANDS_OPTIONS_VALUES, NONE } from 'root-constants'

import { PageId } from 'constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { selectAnswers } from 'root-redux/common/common-selects'
import { getSelectedAnswers } from 'helpers/getSelectedAnswers'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Which commands has your сat already learned?'

const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const savedAnswers = useSelector(selectAnswers)
  const selectedAnswers = useMemo(
    () => getSelectedAnswers(savedAnswers, currentPageId),
    [savedAnswers, currentPageId],
  )

  const [answers, setAnswers] = useState<string[]>([])
  const [isCommandsButtonsDisabled, setIsCommandsButtonsDisabled] =
    useState(false)

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [answers, currentPageId, currentPageNumber, dispatch, onNextStep])

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])

          if (value === 'null') {
            setIsCommandsButtonsDisabled(true)
          }
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)

          if (!newValues.length) {
            setIsCommandsButtonsDisabled(false)
          }
        }
      },
    }),
    [answers, currentPageId],
  )

  const continueWithoutCommand = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: QUESTION,
        answers: NONE,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, onNextStep, dispatch])

  useEffect(() => {
    setAnswers(selectedAnswers as string[])
  }, [selectedAnswers])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          Which commands has your сat
          <br /> already learned?
        </S.Title>
        <S.SubtitleText>
          {t`onboarding.common.chooseAllThatApply`}:
        </S.SubtitleText>
        <StyledOption.OptionsContainer data-is-multiple>
          <Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.GENTLE}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Gentle
            </StyledQuestionButton.Wrapper>
          </Option>
          <Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.IN_THE_BOX}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              In the Box
            </StyledQuestionButton.Wrapper>
          </Option>
          <Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.FIND_IT}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Find it
            </StyledQuestionButton.Wrapper>
          </Option>
          <Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.STAND_UP}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Stand up
            </StyledQuestionButton.Wrapper>
          </Option>
          <Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.TARGET}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Target
            </StyledQuestionButton.Wrapper>
          </Option>
          <S.Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.KISS_ME}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Kiss me
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.SIT}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Sit
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.LAY_DOWN}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Lay down
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.JUMP}
            disabled={isCommandsButtonsDisabled}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Jump
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option
            {...optionProps}
            value={CAT_COMMANDS_OPTIONS_VALUES.HIGH_FIVE}
          >
            <StyledQuestionButton.Wrapper data-has-check-icon>
              High five
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option {...optionProps} value={CAT_COMMANDS_OPTIONS_VALUES.COME}>
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Come
            </StyledQuestionButton.Wrapper>
          </S.Option>
          <S.Option {...optionProps} value={CAT_COMMANDS_OPTIONS_VALUES.TOILET}>
            <StyledQuestionButton.Wrapper data-has-check-icon>
              Using the toilet
            </StyledQuestionButton.Wrapper>
          </S.Option>
        </StyledOption.OptionsContainer>

        <S.StickyBlockWrapper>
          <GreenArrowButton
            onClick={handleContinue}
            isButtonDisabled={!answers.length}
          />
          <S.Link onClick={continueWithoutCommand}>
            My cat doesn&apos;t know any commands
          </S.Link>
        </S.StickyBlockWrapper>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CAT_COMMANDS

export const CommandsQuestion = Component
