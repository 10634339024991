import styled from 'styled-components'
import { Color } from 'root-constants'
import checkOff from 'assets/images/questions-check-off.svg'
import checkOn from 'assets/images/questions-check-on.svg'
import checkOnLight from 'assets/images/check-on-light-green.svg'

export const StyledQuestionButton = {
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    height: 56px;
    font-size: 15px;
    line-height: 16px;
    padding: 11px 16px;
    color: ${Color.PRIMARY_TEXT};
    background-color: ${Color.WHITE};
    border: none;
    border-radius: 16px;
    transition:
      background-image 0.2s ease-out,
      background-color 0.2s ease-out;

    input:checked + & {
      border: 2px solid ${Color.MEDIUM_GREEN};
    }

    &[data-has-check-icon='true'] {
      background-image: url("${checkOff}");
      background-repeat: no-repeat;
      background-position: right 14px center;
      padding-right: 46px;

      input:checked + & {
        background-image: url("${checkOn}");
      }
    }

    &[data-disabled='true'] {
      opacity: 0.5;
    }

    svg {
      margin-right: 16px;
    }
  `,
  Wrapper2: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    height: 136px;
    font-size: 13px;
    line-height: 150%;
    padding: 12px 28px;
    color: ${Color.BLACK};
    background-color: ${Color.WHITE};
    border: 1px solid #d9d9d9;
    border-radius: 16px;
    text-align: center;
    transition:
      background-image 0.2s ease-out,
      background-color 0.2s ease-out;

    input:checked + & {
      border: 1px solid ${Color.PRIMARY};
      background-color: ${Color.PRIMARY_FIXED};
    }

    &[data-has-check-icon='true'] {
      background-image: url("${checkOff}");
      background-repeat: no-repeat;
      background-position: right 13px top 12px;
      background-size: 24px;

      input:checked + & {
        background-image: url("${checkOnLight}");
      }
    }

    &[data-disabled='true'] {
      opacity: 0.5;
    }

    img {
      width: 64px;
      height: 64px;
      margin-bottom: 8px;
    }
  `,
}
