import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'

import arrowBounce from 'assets/animation/arrowBounce.json'
import importantCat from 'assets/images/important-cat.jpg'
import Arrow from 'assets/images/sprite/dark-arrow.svg'
import lottie from 'lottie-web/build/player/lottie_light'
import { Trans, useTranslation } from 'react-i18next'
import { PageId } from 'constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledUrge as S } from './Urge.styles'

const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null!)

  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: arrowBounce,
      loop: true,
    })
  }, [])

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  return (
    <S.UrgeWrapper2>
      <S.UrgeContent>
        <S.UrgeImage src={importantCat} alt="cat" />
        <S.Title>
          <Trans i18nKey="onboarding.urge.title2" components={[<br />]} />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.urge.description2" components={[<br />]} />
        </S.Subtitle>
        <div ref={animationContainerRef} style={{ width: 30 }} />
        <S.WhiteButton onClick={handleContinue}>
          <S.ButtonText>{t`actions.continue`}</S.ButtonText>
          <Arrow width={24} />
        </S.WhiteButton>
      </S.UrgeContent>
    </S.UrgeWrapper2>
  )
}

Component.id = PageId.URGE_IMPORTANT

export const UrgeImportant = Component
