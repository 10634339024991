import { Color, MediaBreakpoint } from 'root-constants'
import styled, { keyframes } from 'styled-components'
import {
  baseColumnStyles,
  baseColumnStylesVariant2,
  basePageStyles,
  fullViewMinHeight,
} from 'pages/styles'

const l = keyframes`
    to {
        clip-path: inset(0 -30% 0 0)
    }
`

export const StyledPrepareFlow = {
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    padding-top: 50px;
    background-color: ${Color.BEIGE};
    height: 100%;
  `,
  Wrapper2: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    padding-top: 50px;
    height: 100%;
  `,
  Content: styled.div`
    ${baseColumnStyles};
    margin-top: 24px;
  `,
  Content2: styled.div`
    ${baseColumnStylesVariant2};
    margin-top: 24px;
  `,
  SliderWrapper: styled.div`
    .commentsCarousel {
      max-width: 328px;
      overflow: hidden;
    }

    .swiper-wrapper {
      display: flex;
      height: 300px;
    }

    .swiper-slide {
      position: relative;
      width: 288px;
      //height: 470px;
      padding-top: 22px;
    }
  `,
  SliderWrapper2: styled.div`
    .commentsCarousel {
      max-width: 328px;
      overflow: hidden;
    }

    .swiper-wrapper {
      display: flex;
      height: 300px;
    }

    .swiper-slide {
      position: relative;
      width: 328px;
      padding-top: 32px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      .commentsCarousel {
        max-width: 382px;
        overflow: hidden;
      }

      .swiper-slide {
        width: 382px;
        padding-top: 40px;
      }
    }
  `,
  CommentsTitleWrapper: styled.div`
    display: flex;
    gap: 6px;
    align-items: center;
  `,
  CommentsTitleWrapper2: styled.div`
    display: flex;
    gap: 13px;
    align-items: center;
    margin-top: 8px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      margin-top: 16px;
    }
  `,
  BrunchImg: styled.img`
    width: 18px;
    height: 32px;
  `,
  CommentsTitle: styled.div`
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.PRIMARY_TEXT};
    text-align: center;
  `,
  CommentsTitle2: styled.div`
    font-weight: 600;
    font-size: 17px;
    line-height: 150%;
    color: ${Color.BLACK};
    text-align: center;
  `,
  PrepareFlowTitle: styled.div`
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: ${Color.PRIMARY_TEXT};
    margin-bottom: 12px;
  `,
  PrepareFlowTitle2: styled.div`
    font-weight: 900;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    color: ${Color.BLACK};
    margin-bottom: 24px;
  `,
  Dots: styled.div`
    display: inline-block;
    clip-path: inset(0 100% 0 0);
    animation: ${l} 2s steps(4) infinite;
  `,
}
