import styled, { css } from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'
import checkOn from 'assets/images/check-on-green.svg'
import checkOnLightGreen from 'assets/images/check-on-light-green.svg'
import checkOff from 'assets/images/questions-check-off.svg'
import { ButtonVariant2 } from 'components/buttons/ButtonVariant2'
import priceBg from 'assets/images/price-bage-transparent.svg'
import priceBg2 from 'assets/images/prcie-bg-default.svg'
import priceBgBordersActive from 'assets/images/price-bg-borders-active.svg'
import Cart from 'assets/images/sprite/cart.svg'

import activePriceBg from 'assets/images/avtive-price-bage.svg'

interface IPlanItemProps {
  isSelected?: boolean
  isDefault?: boolean
  isNonPayerFlow?: boolean
}

type TProp = {
  isLongLocale?: boolean
}

const activePlanItemStyles = css`
  background-color: ${Color.WHITE};
  background-image: url("${checkOn}");
  background-repeat: no-repeat;
  background-position: left 16px center;
  border: 2px solid ${Color.MEDIUM_GREEN};
`

const activePlanItemStylesVariant2 = css`
  background-color: ${Color.PRIMARY_FIXED};
  background-image: url("${checkOnLightGreen}");
  background-repeat: no-repeat;
  background-position: left 16px center;
  border: 2px solid ${Color.PRIMARY};
`

const oldPriceStyles = css`
  opacity: 0.5;
  font-weight: 700;
  text-decoration: line-through;
  text-decoration-color: ${Color.ERROR};
  margin-right: 7px;
`

const currentPriceStyles = css`
  font-weight: 700;
  line-height: 18px;
`

const check = `url("${checkOff}")`

const PlanItem = styled.div<IPlanItemProps>`
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 12px 14px 48px;
  border-radius: 16px;
  background-image: url("${checkOff}");
  background-repeat: no-repeat;
  background-position: left 16px center;
  background-color: rgba(355, 255, 255, 0.7);
  background-size: 20px;
`

const defaultPricePerPeriodStyles = css`
  color: ${Color.PRIMARY_TEXT};
`
const style = `url("${activePriceBg}")`

const activePricePerPeriodStyles = css`
  background-image: ${style};
`

const activePricePerPeriodStyles2 = css`
  background-image: url("${priceBg2}");
`

const PricePerPeriod = styled.div<IPlanItemProps>`
  width: 77px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  color: ${Color.BROWNISH_GRAY};
  padding: 9px 9px 9px 18px;
  background-image: url("${priceBg}");
  background-size: 77px 46px;
  background-repeat: no-repeat;
  background-position: right center;
  transition:
    background-image 0.2s ease-out,
    color 0.2s ease-out;

  strong {
    color: ${Color.PRIMARY_TEXT};
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }

  ${({ isDefault }) => isDefault && defaultPricePerPeriodStyles};
`

const PricePerPeriodVariant2 = styled.div<IPlanItemProps>`
  width: 77px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-weight: 600;
  font-size: 8px;
  line-height: 8px;
  color: ${Color.BLACK};
  padding: 9px 9px 9px 18px;
  background-image: url("${priceBgBordersActive}");
  background-size: 77px 46px;
  background-repeat: no-repeat;
  background-position: right center;
  transition:
    background-image 0.2s ease-out,
    color 0.2s ease-out;

  strong {
    color: ${Color.BLACK};
    font-weight: 700;
    font-size: 15px;
    line-height: 140%;
  }
`

export const StyledPlanBlock = {
  PlanItem: styled(PlanItem)<{ isCancelOffer?: boolean}>`
    ${({ isSelected }) => isSelected && activePlanItemStyles};
    position: relative;
    margin-bottom: ${({ isCancelOffer }) => isCancelOffer ? '4px' : '0'}
  `,
  PlanItemVariant2: styled(PlanItem)`
    ${({ isSelected }) => isSelected && activePlanItemStyles};
    position: relative;
    margin-bottom: 8px;
  `,
  PlanItemVariant3: styled(PlanItem)`
    ${({ isSelected }) => isSelected && activePlanItemStyles};
    position: relative;
    margin-bottom: 13px;
  `,
  PlanItemVariant4: styled(PlanItem)`
   background-color: ${Color.WHITE};
    ${({ isSelected }) => isSelected && activePlanItemStylesVariant2};
    position: relative;
    height: 63px;
    padding: 8px 12px 8px 48px;
  `,
  PlanItemCs: styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 24px;
    position: relative;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      margin-bottom: 8px;
    }

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      margin-bottom: 22px;
    }
  `,
  PlanTitle: styled.div<TProp>`
    width: ${({ isLongLocale }) => (isLongLocale ? 'fit-content' : '110px')};
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
  `,
  PlanTitle2: styled.div`
    width: 110px;
    font-weight: 700;
    font-size: 17px;
    line-height: 150%;
    color: ${Color.BLACK};
  `,
  PlanTitleCs: styled.div`
    max-width: 160px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    opacity: 0.8;
  `,
  PlanSubtitleCs: styled.div`
    max-width: 160px;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 4px;
  `,
  PricesWrapper: styled.div`
    display: flex;
  `,
  OldPrice: styled.div`
    ${oldPriceStyles};
    align-self: flex-end;
  `,
  MostPopularBadge: styled.div`
    display: block;
    padding: 4px 10px;
    position: absolute;
    top: -10px;
    left: 150px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: ${Color.WHITE};
    background-color: ${Color.ERROR};
    border-radius: 16px;
    color: ${Color.WHITE};
  `,
  MostPopularBadge2: styled.div`
    display: block;
    padding: 2px 8px;
    position: absolute;
    top: -10px;
    right: 80px;
    font-size: 12px;
    line-height: 150%;
    font-weight: 600;
    text-transform: uppercase;
    color: ${Color.WHITE};
    background: linear-gradient(90deg, #f8bc45 0%, #fc754a 50%, #eb3524 100%);
    border-radius: 16px;
    color: ${Color.WHITE};
  `,
  OldPricesWrapper: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `,
  OldPricesWrapperCs: styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
  `,
  StartOldPrice: styled.div`
    opacity: 0.5;
    font-weight: 700;
    text-decoration: line-through;
    margin-right: 7px;
    text-decoration-color: ${Color.ERROR};
    font-size: 14px;
    line-height: 18px;
  `,
  StartOldPriceCs: styled.div`
    font-weight: 600;
    text-decoration: line-through;
    margin-left: 4px;
    text-decoration-color: ${Color.ERROR};
    color: ${Color.ERROR};
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 2px;
  `,
  CurrentPrice: styled.div`
    ${currentPriceStyles};
    align-self: flex-end;
    font-size: 14px;
    line-height: 18px;
  `,
  CurrentPriceCs: styled.div`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
  `,
  OldIntervalPrice: styled.div`
    color: ${Color.BROWNISH_GRAY};
    font-weight: 700;
    text-decoration: line-through;
    text-decoration-color: ${Color.ERROR};
    font-size: 10px;
  `,
  OldIntervalPrice2: styled.div`
    color: ${Color.BLACK};
    font-weight: 700;
    text-decoration: line-through;
    text-decoration-color: ${Color.ERROR};
    font-size: 10px;
    opacity: 0.8;
  `,
  SelectPlanCard: styled.div`
    position: relative;
    width: 100%;
    padding: 32px 16px 109px;
    background-color: #f1c73d;

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) and (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      padding: 32px 28px 109px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE - 1}px) {
      width: 360px;
    }
  `,
  SelectPlanCardVariant2: styled.div`
    position: relative;
    width: 100%;
    padding: 32px 16px 109px;
    background: #f19fc5;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  SelectPlanCardVariant3: styled.div`
    position: relative;
    width: 100%;
    padding: 32px 16px 109px;
    background: #8a8be4;
    border-radius: 40px 40px 0 0;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 360px;
    }
  `,
  SelectPlanCardVariant4: styled.div`
    position: relative;
    width: 100%;
    padding: 24px 16px;
    background: #6840bc;
    border-radius: 40px;
  `,
  SelectPlanCardVariant5: styled.div`
    position: relative;
    width: 100%;
    padding: 24px 16px;
    background: #8d5fff;
    border-radius: 0 0 40px 40px;
    z-index: 2;
  `,
  SelectPlanCardVariantCs: styled.div`
    position: relative;
    width: 100%;
    padding: 24px 16px 0;
    background: ${Color.WHITE};
    border-radius: 40px;
    margin-bottom: 80px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      padding: 24px 16px 16px;
    }

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      padding: 24px 2px 0;
    }
  `,
  CardTitle: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 25px;

    @media (max-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 20px;
    }
  `,
  CardTitleWhite: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 25px;
    color: ${Color.WHITE};

    @media (max-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 20px;
    }
  `,
  CardTitleWhite2: styled.h3`
    font-weight: 800;
    font-size: 24px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 24px;
    color: ${Color.WHITE};
  `,
  Button: styled(ButtonVariant2)`
    margin-top: 21px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      width: 328px;
    }
  `,
  PlanButtonWrapper: styled.div`
    position: relative;
    padding-top: 20px;
  `,
  DiscountImage: styled.div<TProp>`
    position: absolute;
    ${({ isLongLocale }) => (isLongLocale ? 'right: 60px' : 'left: 240px')};
    top: 0;

    svg {
      width: 48px;
      height: 48px;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      left: ${({ isLongLocale }) => (isLongLocale ? '220px' : '210px')};
    }
  `,
  DiscountImage2: styled.div<TProp>`
    position: absolute;
    right: 54px;
    top: 0;

    svg {
      width: 48px;
      height: 48px;
    }
  `,
  PricePerPeriod: styled(PricePerPeriod)`
    ${({ isSelected }) => isSelected && activePricePerPeriodStyles};
  `,
  PricePerPeriod2: styled(PricePerPeriodVariant2)`
    ${({ isSelected }) => isSelected && activePricePerPeriodStyles2};
  `,
  PlanItems: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
  `,
  PlanItemsCs: styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      gap: 24px;
    }

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      gap: 10px;
    }
  `,
  PlanImage: styled.img`
    width: 120px;
    height: 95px;
    position: absolute;
    top: -68px;
    left: calc(50% - 60px);
  `,
  PlanItemImage: styled.img`
    width: 160px;
    height: 160px;
    margin-bottom: 8px;
  `,
  PurchaseButton: styled.button`
    display: flex;
    align-items: center;
    background: ${Color.MEDIUM_GREEN};
    color: ${Color.WHITE};
    margin-top: 8px;
    border-radius: 24px;
    padding: 8px 30px;
    border: none;
    outline: none;
    width: 100%;
    cursor: pointer;
  `,
  SuppliesTitle: styled.div<{ background: string }>`
    padding: 16px 2px;
    background: ${({ background }) => background};
    width: 100%;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    font-weight: 800;
    color: ${Color.WHITE};
    margin: 16px 0 32px;

    @media (max-width: ${MediaBreakpoint.MAX_PHONE}px) {
      margin-top: 32px;
    }

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      margin-top: 18px;
    }
  `,
  CartIcon: styled(Cart)`
    width: 24px;
    height: 24px;
    margin-right: 8px;
  `,
  RatingContainer: styled.div`
    display: flex;
    align-items: center;
  `,
  Rate: styled.p`
    font-size: 9px;
    line-height: 12px;
    font-weight: 600;

    span {
      opacity: 0.7;
    }
  `,
  SupplyMarker: styled.div<{ deal: boolean }>`
    padding: 2px 8px;
    font-size: 9px;
    line-height: 14px;
    text-align: center;
    font-weight: 700;
    border-radius: 24px;
    color: ${Color.WHITE};
    background: ${({ deal }) => (deal ? '#e31992' : '#f88600')};
    position: absolute;
    top: 8px;
    right: 8px;
  `,
  TrialBadge: styled.div`
    background: ${Color.BROWNISH_GRAY};
    padding: 4px 6px;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    color: ${Color.WHITE};
    border-radius: 4px;
    width: fit-content;
  `,
}
