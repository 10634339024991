import Cautious from 'assets/images/sprite/cat-cautious.svg'
import Curious from 'assets/images/sprite/cat-curious.svg'
import Hides from 'assets/images/sprite/cat-hides.svg'
import Observe from 'assets/images/sprite/cat-observe.svg'
import Other from 'assets/images/sprite/cat-other-behaviour.svg'
import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { PageId } from 'constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { Trans, useTranslation } from 'react-i18next'
import { StyledQuestion as S } from './Questions.styles'
import { REACT_TO_NEW_PEOPLE_OPTIONS_VALUES } from '../../constants/options-constants'

const QUESTION = 'How does your cat react to new people entering your home?'

const OPTIONS = [
  {
    value: REACT_TO_NEW_PEOPLE_OPTIONS_VALUES.HIDES,
    translateKey: 'onboarding.reactToNewPeople.hide',
    image: <Hides />,
  },
  {
    value: REACT_TO_NEW_PEOPLE_OPTIONS_VALUES.OBSERVE,
    translateKey: 'onboarding.reactToNewPeople.observe',
    image: <Observe />,
  },
  {
    value: REACT_TO_NEW_PEOPLE_OPTIONS_VALUES.CAUTIOUS,
    translateKey: 'onboarding.reactToNewPeople.cautiously',
    image: <Cautious />,
  },
  {
    value: REACT_TO_NEW_PEOPLE_OPTIONS_VALUES.CURIOUS,
    translateKey: 'onboarding.reactToNewPeople.curious',
    image: <Curious />,
  },
  {
    value: REACT_TO_NEW_PEOPLE_OPTIONS_VALUES.OTHER,
    translateKey: 'onboarding.common.other',
    image: <Other />,
  },
]

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans
            i18nKey="onboarding.reactToNewPeople.question"
            components={[<br />]}
          />
        </S.Title>
        <StyledOption.OptionsContainer>
          {OPTIONS.map((option) => (
            <S.Option key={option.value} {...optionProps} value={option.value}>
              <QuestionButton>
                <S.ImageWrapper>{option.image}</S.ImageWrapper>{' '}
                {t(option.translateKey)}
              </QuestionButton>
            </S.Option>
          ))}
        </StyledOption.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.REACT_NEW_PEOPLE

export const ReactNewPeopleQuestion = Component
