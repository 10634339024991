import React, { useMemo } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { selectCurrentVariantSteps } from 'root-redux/common/common-selects'
import { useGetAvailableRoute } from 'hooks/useGetAvailableRoute'
import { Account } from 'modules/account/Account'
import { DownloadApp } from 'pages/download/DownloadApp'
import { DownloadAppWithPdf } from 'pages/download/DownloadAppWithPdf'
import { selectIsUpsellPaid } from 'root-redux/user/user-selects'
import { wrapUseRoutesV6 } from '@sentry/react'
import { PageId } from 'constants/pages'
import { ID_TO_PAGE_MAP } from 'constants/pages-map'

const useSentryRoutes = wrapUseRoutesV6(useRoutes)

export const RouteList: React.FC = () => {
  const steps = useSelector(selectCurrentVariantSteps)
  const isUpsellPaid = useSelector(selectIsUpsellPaid)
  const availableRoute = useGetAvailableRoute()

  const firstPageId = useMemo(() => steps[0].id, [steps])

  return useSentryRoutes([
    {
      index: true,
      element: <Navigate to={firstPageId} />,
    },
    {
      element: <Outlet />,
      children: steps.map(({ id: pageId }) => {
        const CurrentPage =
          ID_TO_PAGE_MAP[pageId] || ID_TO_PAGE_MAP[firstPageId]

        return {
          path: pageId,
          element: (
            <ProtectedRoute isAvailable={!availableRoute} key={pageId}>
              <CurrentPage />
            </ProtectedRoute>
          ),
        }
      }),
    },
    {
      path: PageId.ACCOUNT,
      element: <Account />,
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          {isUpsellPaid ? <DownloadAppWithPdf /> : <DownloadApp />}
        </ProtectedRoute>
      ),
    },
    {
      path: '*',
      element: <Navigate to={firstPageId} />,
    },
  ])
}
