import { Elements } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import { loadStripe } from '@stripe/stripe-js/pure'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectIsCancelOfferApplied } from 'root-redux/common/common-selects'
import {
  select3DSecureIframeUrl,
  selectCurrency,
  selectFullPrice,
  selectIsPaymentFlowsShown,
  selectPeriodName,
  selectPeriodQuantity,
  selectStripePublicKey,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { selectEmail } from 'root-redux/user/user-selects'
import { eventLogger, ScreenName } from 'services/eventLogger-service'
import { createProductId } from 'helpers/createProductId'
import { Spinner } from 'components/spinner/Spinner'
import { StripePaymentProcessing } from 'modules/payment/components/payment-processing/StripePaymentProcessing'
import { createProductName } from 'helpers/createProductName'
import { StyledPayment as S } from './Payment.styles'
import { STRIPE_ELEMENTS_OPTIONS } from '../constants'
import { PaymentFlowsVariant4 } from '../components/PaymentFlowVariant4'
import { TDynamicPageComponent } from 'models/common.model'
import { PageId } from 'constants/pages'

const Component: TDynamicPageComponent = () => {
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const introQuantity = useSelector(selectTrialDurationDays)
  const price = useSelector(selectFullPrice)
  const introPrice = useSelector(selectTrialFullPrice)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const stripePublicKey = useSelector(selectStripePublicKey)
  const isPaymentFlowsShown = useSelector(selectIsPaymentFlowsShown)
  const email = useSelector(selectEmail)
  const currency = useSelector(selectCurrency)
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null)

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )
  const productName = useMemo(
    () =>
      createProductName({
        periodName,
        periodQuantity,
        price,
        introPrice,
        introQuantity,
        currency,
      }),
    [periodName, periodQuantity, price, introPrice, introQuantity, currency],
  )
  const screenName = useMemo(
    () => (isCancelOfferApplied ? ScreenName.CANCEL : ScreenName.SALES),
    [isCancelOfferApplied],
  )

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        productName,
        screenName,
      })

      eventLogger.logPaymentMethodPageShown({
        screenName,
        email,
      })
      window.ttq.identify({ email })
      window.ttq.track('InitiateCheckout', {
        content_id: productId,
      })
    }
  }, [productId, productName, screenName, email])

  useEffect(() => {
    if (stripePublicKey) {
      // loadStripe should be called once
      setStripePromise(loadStripe(stripePublicKey))
    }
  }, [stripePublicKey])

  return (
    <Elements stripe={stripePromise} options={STRIPE_ELEMENTS_OPTIONS}>
      <S.Wrapper>
        {threeDSecureIframeUrl ? (
          <S.ThreeDSecureIframe
            title="3DSecure"
            src={threeDSecureIframeUrl}
            scrolling="yes"
          />
        ) : (
          <>
            <StripePaymentProcessing />
            <S.Content>
              <PaymentFlowsVariant4
                productId={productId}
                productName={productName}
              />
            </S.Content>
          </>
        )}
        {!isPaymentFlowsShown && <Spinner />}
      </S.Wrapper>
    </Elements>
  )
}

Component.hasHeader = true
Component.id = PageId.PAYMENT_4

export const PaymentVariant3 = Component
