import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Trans, useTranslation } from 'react-i18next'
import {
  selectCurrencySign,
  selectPeriodName,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { StyledTotalAmount as S } from './TotalAmount.styles'

export const TotalAmountVariant4: React.FC = () => {
  const trialCurrentPrice = useSelector(selectTrialFullPrice)
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const periodName = useSelector(selectPeriodName)
  const currency = useSelector(selectCurrencySign)
  const { t } = useTranslation()

  const periodNameFormatted = useMemo(() => {
    if (!periodName) return ''
    return t(`onboarding.planBlock.${periodName}`)
  }, [periodName])

  return (
    <S.TotalPrice2>
      {t`payment.total`}:&nbsp;
      <strong>
        {currency}
        {trialCurrentPrice}
      </strong>
      &nbsp;
      {trialPeriodDays === 7 ? (
        <Trans i18nKey="payment.forTrial" values={{ trialPeriodDays }} />
      ) : (
        <Trans
          i18nKey="payment.forPriceTotal"
          values={{ periodQuantity: trialPeriodDays / 30, periodNameFormatted }}
        />
      )}
    </S.TotalPrice2>
  )
}
