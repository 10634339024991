import { TDynamicPageComponent } from 'models/common.model'
import lottie from 'lottie-web/build/player/lottie_light'
import lessonsCounter from 'assets/animation/lessonsCounter.json'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { PageId } from 'constants/pages'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import pawIcon from 'assets/images/paw-icon.png'
import { FlowContext } from 'components/FlowContext'
import { useDispatch, useSelector } from 'react-redux'
import { selectAnswers } from 'root-redux/common/common-selects'
import { AnswerKey } from 'modules/subscriptions/constants'
import { getCatQuestionAnswers } from 'helpers/getCatQuestionAnswers'
import { useTranslation } from 'react-i18next'
import { IDK } from 'root-constants'
import { getAnswersValues } from 'modules/subscriptions/helpers'
import { getCheckedScreenHeight } from 'helpers/getCheckedScreenHeight'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { StyledUrge as S } from './Urge.styles'

const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const answers = useSelector(selectAnswers)
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)
  const [shouldShowShadow, setShouldShowShadow] = useState(
    getCheckedScreenHeight(690, 670),
  )

  const answerValues = useMemo(
    () =>
      getCatQuestionAnswers(answers, [
        AnswerKey.AGE,
        AnswerKey.CAT_BREED,
        AnswerKey.GOALS_QUESTION,
        AnswerKey.SHELTER,
      ]) || {},
    [answers],
  )

  const goals = useMemo(() => {
    if (
      answerValues[AnswerKey.GOALS_QUESTION]?.[0] ===
        'onboarding.common.other' &&
      answerValues[AnswerKey.GOALS_QUESTION].length === 1
    ) {
      return 'Improve cat behavior'
    }
    return getAnswersValues(
      answerValues[AnswerKey.GOALS_QUESTION] as string[],
      t,
    )
  }, [answerValues])

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: lessonsCounter,
      loop: false,
    })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setShouldShowShadow(getCheckedScreenHeight(690, 670))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <S.UrgeWrapper4>
      <S.UrgeContent2>
        <S.Title2>
          <span>Your journey</span> begins!
        </S.Title2>
        <S.Subtitle3>WE HAVE PREPARED</S.Subtitle3>
        <S.LessonsCounter ref={animationContainerRef} />
        <S.CatInfoContainer>
          <S.CatInfoTitle>Tailored for your cat:</S.CatInfoTitle>
          <S.CatInfoItem>
            <img src={pawIcon} alt="cat paw" />
            Age:&nbsp;<strong>{t(answerValues.cat_age as string)}</strong>
          </S.CatInfoItem>
          {answerValues.cat_breed !== IDK && (
            <S.CatInfoItem>
              <img src={pawIcon} alt="cat paw" />
              Breed:&nbsp;<strong>{t(answerValues.cat_breed as string)}</strong>
            </S.CatInfoItem>
          )}
          <S.CatInfoItem>
            <img src={pawIcon} alt="cat paw" />
            Shelter:&nbsp;
            <strong>{t(answerValues.shelter_cat as string)}</strong>
          </S.CatInfoItem>
          <S.CatInfoItem isHideBorder>
            <img src={pawIcon} alt="cat paw" />
            Goal:&nbsp;<strong>{goals}</strong>
          </S.CatInfoItem>
        </S.CatInfoContainer>
        <S.StickyContainer shouldShowShadow={shouldShowShadow}>
          <S.ButtonWrapper>
            <GreenArrowButtonVariant2 onClick={handleContinue} />
          </S.ButtonWrapper>
        </S.StickyContainer>
      </S.UrgeContent2>
    </S.UrgeWrapper4>
  )
}

Component.id = PageId.URGE_SOCIAL_PROOF_2
Component.hasHeader = true

export const UrgeSocialProofVariant2 = Component
