import { useStripe } from '@stripe/react-stripe-js'
import { FlowContext } from 'components/FlowContext'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import applePayLogoWhite from 'assets/images/sprite/apple_pay_white.svg'
import googlePayLogoWhite from 'assets/images/sprite/google_pay_white.svg'
import { CENTS_IN_DOLLAR } from 'root-constants'
import { useTranslation } from 'react-i18next'
import {
  selectCurrency,
  selectFullPrice,
  selectPeriodName,
  selectPeriodQuantity,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { resetErrorAction } from 'root-redux/common/common-actions'
import { selectEmail } from 'root-redux/user/user-selects'
import { TPaymentRequestButton } from 'modules/payment/types'
import {
  purchaseAction,
  setIsPaymentFlowsShownAction,
} from 'root-redux/payment/payment-actions'
import { PaymentRequest } from '@stripe/stripe-js'
import { TAppDispatch } from 'root-redux/store'
import { PaymentRequestOptions as S } from './PaymentRequestOptions.styles'

type TProps = {
  screenName: string
}

export const PaymentRequestOption: React.FC<TProps> = ({ screenName }) => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const { currentPageId } = useContext(FlowContext)
  const stripe = useStripe()
  const trialCurrentPrice = useSelector(selectTrialFullPrice)
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const currency = useSelector(selectCurrency)
  const currentPrice = useSelector(selectFullPrice)
  const email = useSelector(selectEmail)
  const trialPeriodDays = useSelector(selectTrialDurationDays)
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  )
  const [buttonTypes, setButtonTypes] = useState<TPaymentRequestButton | null>(
    null,
  )

  const ButtonIcon = useMemo(
    () => (buttonTypes?.applePay ? applePayLogoWhite : googlePayLogoWhite),
    [buttonTypes],
  )

  const calculatedPrice = useMemo(
    () => +((trialCurrentPrice || currentPrice) * CENTS_IN_DOLLAR).toFixed(),
    [trialCurrentPrice, currentPrice],
  )

  const periodNameFormatted = useMemo(() => {
    if (!periodName) return ''
    return t(`onboarding.planBlock.${periodName}`)
  }, [periodName])

  const paymentLabel = useMemo(
    () =>
      trialCurrentPrice
        ? t('payment.forTrial', { trialPeriodDays })
        : t('payment.forPriceTotal', {
            periodQuantity,
            periodNameFormatted,
          }),
    [periodQuantity, periodNameFormatted, trialPeriodDays, trialCurrentPrice],
  )

  useEffect(() => {
    if (!stripe || !currentPageId) {
      return
    }

    const pr = stripe.paymentRequest({
      currency: currency.toLowerCase(),
      country: 'GB',
      requestPayerEmail: true,
      requestPayerName: true,
      total: {
        label: paymentLabel,
        amount: calculatedPrice,
      },
    })

    pr.canMakePayment().then((result) => {
      if (result) {
        setButtonTypes(result as TPaymentRequestButton)
        setPaymentRequest(pr)
      }
      dispatch(setIsPaymentFlowsShownAction(true))
    })

    pr.on('paymentmethod', (event) => {
      dispatch(resetErrorAction())
      dispatch(
        purchaseAction({
          stripe,
          paymentPageId: currentPageId,
          createPaymentResFromDigitalWallet: event,
          screenName,
          currency,
        }),
      )
    })
  }, [
    dispatch,
    currency,
    stripe,
    calculatedPrice,
    currentPageId,
    periodQuantity,
    periodName,
    paymentLabel,
    screenName,
  ])

  const handleButtonClick = useCallback(() => {
    if (paymentRequest) {
      paymentRequest.show()
      window.ttq.identify({ email })
      window.ttq.track('AddPaymentInfo')
      window.snaptr('track', 'ADD_BILLING')
    }
  }, [paymentRequest, email])

  return paymentRequest ? (
    <>
      <S.Divider>
        <S.DividerText>{t`payment.OR`}</S.DividerText>
      </S.Divider>
      <S.PaymentMethodWrapper>
        <S.CustomPaymentButton
          onClick={handleButtonClick}
          type="button"
          id="payment-request-button"
        >
          <ButtonIcon width={58} height={22} />
        </S.CustomPaymentButton>
      </S.PaymentMethodWrapper>
    </>
  ) : null
}
