import { TDynamicPageComponent } from 'models/common.model'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { PageId } from 'constants/pages'
import { PLAN_TYPES } from 'root-constants'
import {
  StyledComponents as S,
  VioletBackgroundStyle,
} from 'modules/upsell/styles'
import dogPDF from 'assets/images/cat-pdf.png'
import { useSelector, useDispatch } from 'react-redux'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'
import { FlowContext } from 'components/FlowContext'
import { Trans, useTranslation } from 'react-i18next'
import { eventLogger, Events, ScreenName } from 'services/eventLogger-service'
import { makeUpsellAction } from 'root-redux/payment/upsell'
import { UpsellStripePaymentProcessing } from 'modules/payment/components/payment-processing/UpsellStripePaymentProcessing'
import { selectCurrencySign } from 'root-redux/payment/payment-selects'
import { OfferDetails } from '../components/OfferDetails'

const UpsellPageComponent: React.FC<ISubscriptionsPageProps> = ({
  availableSubscriptions,
  onSubscriptionSelect,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [isShowOfferDetails, setIsShowOfferDetails] = useState(false)
  const currency = useSelector(selectCurrencySign)
  const { onAccountStep } = useContext(FlowContext)

  const makeUpsell = useCallback(
    () => dispatch(makeUpsellAction(ScreenName.UPSELL)),
    [dispatch],
  )

  const upsellPrice = useMemo(
    () => availableSubscriptions[0],
    [availableSubscriptions],
  )

  useEffect(() => {
    onSubscriptionSelect(upsellPrice)
  }, [upsellPrice, onSubscriptionSelect])

  useEffect(() => {
    if (isShowOfferDetails) {
      const viewPriceEventSent = sessionStorage.getItem(
        Events.PRESS_UPSELL_TOOLTIP,
      )

      if (!viewPriceEventSent) {
        eventLogger.logPressUpsellTooltip({
          productName: 'pdf_zen_course',
        })
        sessionStorage.setItem(Events.PRESS_UPSELL_TOOLTIP, 'true')
      }

      const el = document.getElementById('details')
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isShowOfferDetails])

  const showAdditionalInfo = () => {
    setIsShowOfferDetails(true)
  }

  return (
    <S.Wrapper>
      <S.Content>
        <UpsellStripePaymentProcessing />
        <VioletBackgroundStyle />
        <S.CatPDFImage src={dogPDF} decoding="async" alt="upsell image" />
        <S.Title>{t`upsell.title`}</S.Title>
        <S.SaveButton>
          <Trans i18nKey="upsell.save" values={{ percent: 50 }} />
        </S.SaveButton>
        <S.Offer>
          {t`upsell.getPdf`}&nbsp;
          <S.ChewingDog onClick={showAdditionalInfo} />
          <br />
          <Trans
            i18nKey="upsell.justFor"
            values={{
              price: upsellPrice?.trialPrices?.oldPrices?.fullPrice,
              currency,
            }}
          />
          &nbsp; {currency}
          {upsellPrice?.trialPrices?.fullPrice}
        </S.Offer>
        <S.AdditionalInfo>{t`upsell.getReady`}</S.AdditionalInfo>
        <OfferDetails isVisible={isShowOfferDetails} />
        <S.StickyBlock>
          <S.Animation onClick={makeUpsell}>
            <S.Button>{t`upsell.getMyOffer`}</S.Button>
          </S.Animation>
          <S.ContinueLink onClick={onAccountStep}>
            {t`upsell.skipAndContinue`}
          </S.ContinueLink>
        </S.StickyBlock>
      </S.Content>
    </S.Wrapper>
  )
}

const ComponentUpsell: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.UPSELL}
    planType={PLAN_TYPES.ONE_TIME}
    isUpsell
  >
    {(props: ISubscriptionsPageProps) => <UpsellPageComponent {...props} />}
  </SubscriptionsWrapper>
)

ComponentUpsell.hasHeader = true
ComponentUpsell.id = PageId.UPSELL

export const Upsell = ComponentUpsell
