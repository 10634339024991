import { basePageStyles, fullViewHeight, fullViewMinHeight } from 'pages/styles'
import { Color } from 'root-constants'
import styled from 'styled-components'

export const StyledBackModal = {
  Wrapper: styled.div`
    ${basePageStyles};
    ${fullViewMinHeight};
    height: 100%;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
    position: fixed;
    top: 0;
    z-index: 4;
  `,
  Content: styled.div`
    position: fixed;
    bottom: 0;
    border-radius: 40px 40px 0 0;
    background: ${Color.WHITE};
    padding: 32px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 430px;
  `,
  Title: styled.h2`
    font-size: 28px;
    font-weight: 800;
    line-height: 140%;
    color: ${Color.BLACK};
    margin-bottom: 8px;
  `,
  Description: styled.div`
    font-size: 15px;
    font-weight: 400;
    line-height: 150%;
    color: ${Color.BLACK};
    text-align: center;
    opacity: 0.8;

    strong {
      font-weight: 800;
      color: #8d5fff;
    }
  `,
  Chart: styled.div`
    background-color: ${Color.WHITE};
    border-radius: 14px;
    width: 328px;
  `,
  Comment: styled.div`
    font-size: 9px;
    font-weight: 500;
    line-height: 150%;
    opacity: 0.5;
    color: ${Color.BLACK};
    margin-bottom: 16px;
    margin-top: -16px;
  `,
  InfoBlock: styled.div`
    padding: 12px;
    border-radius: 16px;
    background: rgba(235, 227, 255, 0.5);
    font-size: 13px;
    line-height: 150%;
    font-weight: 600;
    color: ${Color.BLACK};
    width: 328px;
    margin-bottom: 32px;

    strong {
      font-weight: 800;
    }
  `,
}