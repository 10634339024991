import styled from 'styled-components'
import { Color, MediaBreakpoint } from 'root-constants'

type TProp = {
  isLongLocale?: boolean
}

export const StyledHeader = {
  HeaderWrapper: styled.div`
    width: 100%;
    background: linear-gradient(
        279.09deg,
        #f8bc45 -1.71%,
        #fc754a 49.7%,
        #eb3524 100.06%
      ),
      #c4c4c4;
    height: 60px;
    box-shadow: 0 8px 12px rgba(242, 76, 50, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    &[data-is-hide='true'] {
      display: none;
    }

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      height: 60px;
      box-shadow: 0 8px 12px rgba(242, 76, 50, 0.2);
      margin-left: calc(50% - 180px);
      width: 360px;
    }
  `,
  HeaderContentWrapper: styled.div`
    background: transprent;
    width: 100vw;
    height: 60px;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
  `,
  HeaderWrapper2: styled.div`
    width: 100%;
    background: linear-gradient(
        279.09deg,
        #f8bc45 -1.71%,
        #fc754a 49.7%,
        #eb3524 100.06%
      ),
      #c4c4c4;
    height: 60px;
    box-shadow: 0 8px 12px rgba(242, 76, 50, 0.2);

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      height: 60px;
      box-shadow: 0 8px 12px rgba(242, 76, 50, 0.2);
      width: 430px;
    }
  `,
  Background: styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    left: calc(50% - 160px);
  `,
  Lightning: styled.img`
    padding-left: 8px;
    height: 40px;
    width: 40px;
  `,
  ContentWrapper: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      position: relative;
      left: calc(50% - 180px);
      width: 360px;
      height: 60px;
      display: flex;
    }
  `,
  ContentWrapper2: styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      position: relative;
      left: calc(50% - 215px);
      width: 430px;
      height: 60px;
      display: flex;
    }
  `,
  ButtonWrapper: styled.div`
    display: block;
  `,
  Text: styled.div<TProp>`
    display: flex;
    align-items: center;
    font-size: ${({ isLongLocale }) => (isLongLocale ? '12px' : '14px')};
    font-weight: 700;
    line-height: 22px;
  `,
  Text2: styled.div<TProp>`
    display: flex;
    align-items: center;
    font-size: ${({ isLongLocale }) => (isLongLocale ? '11px' : '13px')};
    font-weight: 400;
    line-height: 150%;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      font-size: ${({ isLongLocale }) => (isLongLocale ? '15px' : '17px')};
    }
  `,
  Timer: styled.div`
    font-size: 22px;
    font-weight: 900;
    line-height: 22px;
    margin-left: 8px;
  `,
  Timer2: styled.div`
    font-size: 20px;
    font-weight: 700;
    line-height: 140%;
    margin-left: 11px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE_MIN_WIDTH_TEST}px) {
      font-size: 24px;
      line-height: 120%;
      margin-left: 8px;
    }
  `,
  TextWrapper: styled.div<TProp>`
    display: flex;
    flex-direction: column;
    color: ${Color.WHITE};
    margin-left: ${({ isLongLocale }) => (isLongLocale ? '4px' : '12px')};
  `,
  TextWrapper2: styled.div<TProp>`
    display: flex;
    flex-direction: row;
    color: ${Color.WHITE};
    margin-left: ${({ isLongLocale }) => (isLongLocale ? '4px' : '24px')};
  `,
  Button: styled.button<TProp>`
    position: absolute;
    height: 32px;
    right: 16px;
    border: none;
    box-shadow: 0 8px 8px rgba(242, 76, 50, 0.3);
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    border-radius: 24px;
    text-transform: uppercase;

    font-weight: 800;
    font-size: 10px;
    ${({ isLongLocale }) => !isLongLocale && 'line-height: 20px'};
    display: flex;
    align-items: center;
    letter-spacing: 0.3px;
    justify-content: center;
    top: 14px;
    min-width: 134px;
    width: 88px;

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      min-width: auto;
      ${({ isLongLocale }) => isLongLocale && 'font-size: 8px'};
    }
  `,
  Button2: styled.button<TProp>`
    position: absolute;
    height: 32px;
    right: 24px;
    border: none;
    box-shadow: 0 8px 8px rgba(242, 76, 50, 0.3);
    background-color: ${Color.WHITE};
    color: ${Color.PRIMARY_TEXT};
    border-radius: 24px;
    text-transform: uppercase;
    padding: 8px 11px;
    font-weight: 800;
    font-size: 13px;
    ${({ isLongLocale }) => !isLongLocale && 'line-height: 150%'};
    display: flex;
    letter-spacing: 0.3px;
    justify-content: center;
    top: 14px;
    width: 107px;

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 99px;
      padding: 8px;
      ${({ isLongLocale }) => isLongLocale && 'font-size: 8px'};
    }
  `,
  ButtonBorder: styled.div`
    position: absolute;
    width: 142px;
    height: 40px;
    right: 12px;
    top: 10px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.2);

    -webkit-animation: pulse 1s linear infinite;
    animation: pulse 1s linear infinite;

    @keyframes pulse {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
      }
      100% {
        -webkit-transform: scale(1.25, 1.25);
        transform: scale(1.25, 1.25);
        opacity: 0;
      }
    }

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 95px;
    }
  `,
  ButtonBorder2: styled.div`
    position: absolute;
    width: 113px;
    height: 40px;
    right: 22px;
    top: 10px;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, 0.2);

    -webkit-animation: pulse 1s linear infinite;
    animation: pulse 1s linear infinite;

    @keyframes pulse {
      0% {
        -webkit-transform: scale(1, 1);
        transform: scale(1, 1);
        opacity: 1;
      }
      100% {
        -webkit-transform: scale(1.25, 1.25);
        transform: scale(1.25, 1.25);
        opacity: 0;
      }
    }

    @media (max-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      width: 103px;
    }
  `,
}
