import paymentServicesImage from 'assets/images/payment-services.png'
import { FlowContext } from 'components/FlowContext'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { PayPalContainer } from 'modules/payment/components/paypal/PayPalContainer'
import React, { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import {
  selectCurrentVariantSteps,
  selectFirebaseConfigFile,
  selectIsCancelOfferApplied,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { resetErrorAction } from 'root-redux/common/common-actions'
import {
  selectCurrency,
  selectFullPrice,
  selectPeriodName,
  selectPeriodQuantity,
  selectTrialDurationDays,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { StripePaymentForm } from 'modules/payment/components/stripe-payment-form/StripePaymentForm'
import { selectEmail } from 'root-redux/user/user-selects'
import { eventLogger, ScreenName } from 'services/eventLogger-service'
import { googleAnalyticsLogger } from 'services/googleAnalytics-service'
import { createProductId } from 'helpers/createProductId'
import { PaymentRequestOptionVariant2 } from 'modules/payment/components/payment-request-options/PaymentRequestOptionVariant2'
import { TotalAmountVariant3 } from 'modules/payment/components/total-amount/TotalAmountVariant3'
import { createProductName } from 'helpers/createProductName'
import { PageId } from '../../../constants/pages'
import { StyledPaymentFlows as S } from './PaymentFlows.styles'

export const PaymentFlowsVariant3: React.FC = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const {
    onPreviousStep,
    onSubscriptionsForNonPayerUsers,
    onFirstAdditionalPageForNonPayerUsers,
  } = useContext(FlowContext)
  const cohort = useSelector(selectVariantCohort)
  const currentVariantSteps = useSelector(selectCurrentVariantSteps)
  const isCancelOfferApplied = useSelector(selectIsCancelOfferApplied)
  const email = useSelector(selectEmail)
  const periodName = useSelector(selectPeriodName)
  const periodQuantity = useSelector(selectPeriodQuantity)
  const price = useSelector(selectFullPrice)
  const introPrice = useSelector(selectTrialFullPrice)
  const introQuantity = useSelector(selectTrialDurationDays)
  const currency = useSelector(selectCurrency)
  const { paypalMethodShown } = useSelector(selectFirebaseConfigFile)
  const screenName = useMemo(
    () => (isCancelOfferApplied ? ScreenName.CANCEL : ScreenName.SALES),
    [isCancelOfferApplied],
  )

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )
  const productName = useMemo(
    () =>
      createProductName({
        periodName,
        periodQuantity,
        price,
        introPrice,
        introQuantity,
        currency,
      }),
    [periodName, periodQuantity, price, introPrice, introQuantity, currency],
  )

  const handleBack = useCallback(() => {
    eventLogger.logPaymentMethodPageClose({
      email,
      productId,
      productName,
      screenName,
    })
    const isFlowIncludesPagesForNonPayerUsers = currentVariantSteps.some(
      (page) =>
        [
          PageId.CANCEL_OFFER,
          PageId.CANCEL_OFFER_2,
          PageId.CANCEL_OFFER_3,
        ].includes(page.id),
    )

    dispatch(resetErrorAction())
    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)

    if (!isFlowIncludesPagesForNonPayerUsers) {
      onPreviousStep()
      return
    }

    currentVariantSteps.find((item) =>
      [PageId.LAST_CHANCE_OFFER_3].includes(item.id),
    ) && !isCancelOfferApplied
      ? onFirstAdditionalPageForNonPayerUsers()
      : onSubscriptionsForNonPayerUsers()
  }, [
    cohort,
    email,
    screenName,
    currentVariantSteps,
    dispatch,
    pathname,
    productId,
    productName,
    onSubscriptionsForNonPayerUsers,
    onPreviousStep,
  ])

  return (
    <>
      <S.TitleWrapper>
        <S.Title>{t`payment.checkout`}</S.Title>
        <S.CloseButton2 onClick={handleBack} />
      </S.TitleWrapper>
      <TotalAmountVariant3 />
      <S.PaymentTitle>
        <span>{t`payment.paymentInfo`}</span>
        <S.PaymentTitleProtection>{t`payment.protected`}</S.PaymentTitleProtection>
      </S.PaymentTitle>
      <S.Form style={{ width: '100%', marginBottom: '10px' }}>
        <StripePaymentForm screenName={screenName} topModalPosition="280px" />
      </S.Form>

      <PaymentRequestOptionVariant2 screenName={screenName} />

      {paypalMethodShown && (
        <>
          <S.Divider>
            <S.DividerText>{t`payment.OR`}</S.DividerText>
          </S.Divider>
          <PayPalContainer
            customStyle={{
              color: 'gold',
            }}
            screenName={screenName}
          />
        </>
      )}

      <S.PaymentServiceImage
        src={paymentServicesImage}
        decoding="async"
        alt="payments services"
        style={{ marginTop: '0' }}
      />
      <S.Guarantee>{t`payment.guarantee`}</S.Guarantee>
      <S.Disclaimer>
        {t`payment.disclaimer`}&nbsp;
        <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>.&nbsp;
        {t`payment.reference`}
      </S.Disclaimer>
    </>
  )
}
