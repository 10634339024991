import React, { useMemo } from 'react'
import guaranteeImage from 'assets/images/guarantee-cat.jpg'
import catBefore from 'assets/images/cat-before.jpg'
import catAfter from 'assets/images/cat-after.jpg'
import { CONTACT_FORM_LINKS, TERMS_OF_USE_LINKS } from 'root-constants'
import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { Trans, useTranslation } from 'react-i18next'
import { StyledGuarantee as S } from './Guarantee.styles'

export const GuaranteeVariant4: React.FC = () => {
  const { t } = useTranslation()
  const env = getCurrentEnv()

  const dateBefore = useMemo(() => {
    const currentDateBefore = new Date()
    return new Date(currentDateBefore.setDate(currentDateBefore.getDate() - 45))
  }, [])
  const dateAfter = useMemo(() => {
    const currentDateAfter = new Date()
    return new Date(currentDateAfter.setDate(currentDateAfter.getDate() - 7))
  }, [])

  return (
    <S.WrapperVariant3>
      <S.CatImageVariant3 src={guaranteeImage} />
      <S.Title2>100% money-back guarantee</S.Title2>
      <S.Text3>
        {t`onboarding.guarantee.text`}&nbsp;
        <S.InnerLink target="_blank" to={TERMS_OF_USE_LINKS[env]}>
          {t`commonComponents.termsOfUse`}
        </S.InnerLink>
        &nbsp;{t`commonComponents.contact`}&nbsp;
        <S.InnerLink to={CONTACT_FORM_LINKS[env]} target="_blank">
          Support.
        </S.InnerLink>
      </S.Text3>
      <S.ResultBlockWrapper>
        <S.ResultsBlock2>
          <S.Title>
            <Trans
              i18nKey="onboarding.guarantee.resultsOfUser"
              components={[<br />, <strong />]}
            />
          </S.Title>
          <S.ResultsWrapper>
            <S.ImageWrapper>
              <S.ResultImage src={catBefore} isBlackAndWhite />
              <S.LabelBefore2>
                {dateBefore.toLocaleDateString('en-US')}
              </S.LabelBefore2>
            </S.ImageWrapper>
            <S.ImageWrapper>
              <S.ResultImage src={catAfter} />
              <S.LabelAfter2>
                {dateAfter.toLocaleDateString('en-US')}
              </S.LabelAfter2>
              <S.Emoji2 />
            </S.ImageWrapper>
          </S.ResultsWrapper>
        </S.ResultsBlock2>
      </S.ResultBlockWrapper>
    </S.WrapperVariant3>
  )
}
