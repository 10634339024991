import React, { useEffect, useRef } from 'react'
import guaranteeImage from 'assets/images/cat-guarantee-2.png'
import NewbieBubble from 'assets/images/sprite/message.svg'
import lottie from 'lottie-web/build/player/lottie_light'
import trainingProgress from 'assets/animation/trainingProgress.json'
import { useSelector } from 'react-redux'
import { NONE } from 'root-constants'
import { selectAnswers } from 'root-redux/common/common-selects'
import { PageId } from 'constants/pages'
import { StyledGuarantee as S } from './Guarantee.styles'

export const GuaranteeVariant2: React.FC = () => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const answers = useSelector(selectAnswers)

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: trainingProgress,
      loop: true,
    })
  }, [])

  return (
    <S.WrapperVariant2>
      <S.CatImageVariant2 src={guaranteeImage} />
      <S.Title>
        100% money-back
        <br /> guarantee
      </S.Title>
      <S.Text>
        We are confident in the quality of our plan - millions of clients around
        the world have already got visible results. We are ready to return your
        money back if you don’t see the results and can demonstrate that you
        followed your plan.
      </S.Text>
      <S.ChartWrapper>
        <S.ChartTitle>Track the training progress</S.ChartTitle>
        <S.Chart ref={animationContainerRef} style={{ width: '330px' }} />
        <S.NowText>now</S.NowText>
        <S.TextPlan>with our plan</S.TextPlan>
        <S.ExpertBubble />
        <S.NewbieBubble>
          <NewbieBubble width={69} />
          <S.NewbieTitle>Newbie</S.NewbieTitle>
          <S.CommandsCount>
            {answers && answers[PageId.CAT_COMMANDS] !== NONE
              ? answers[PageId.CAT_COMMANDS]?.length
              : 0}
            &nbsp;commands
          </S.CommandsCount>
        </S.NewbieBubble>
      </S.ChartWrapper>
    </S.WrapperVariant2>
  )
}
