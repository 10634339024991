import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { PageId } from 'constants/pages'

import { QuestionButton } from 'components/buttons/QuestionButton'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { Trans, useTranslation } from 'react-i18next'
import { GROOMING_OPTIONS_VALUES } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'How does your cat behave during grooming sessions?'

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>
          <Trans i18nKey="onboarding.grooming.question" components={[<br />]} />
        </S.Title>

        <StyledOption.OptionsContainer>
          <S.Option {...optionProps} value={GROOMING_OPTIONS_VALUES.RESISTS}>
            <QuestionButton>{t`onboarding.grooming.resists`}</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={GROOMING_OPTIONS_VALUES.TOLERATES}>
            <QuestionButton>{t`onboarding.grooming.tolerates`}</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={GROOMING_OPTIONS_VALUES.CALM}>
            <QuestionButton>{t`onboarding.grooming.accept`}</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={GROOMING_OPTIONS_VALUES.ENJOY}>
            <QuestionButton>{t`onboarding.grooming.enjoy`}</QuestionButton>
          </S.Option>
          <S.Option {...optionProps} value={GROOMING_OPTIONS_VALUES.OTHER}>
            <QuestionButton>{t`onboarding.common.other`}</QuestionButton>
          </S.Option>
        </StyledOption.OptionsContainer>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.GROOMING

export const GroomingQuestion = Component
