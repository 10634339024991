import boy from 'assets/images/cat-male.png'
import girl from 'assets/images/cat-female.png'
import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { setAnswersAction } from 'root-redux/common/common-actions'
import Arrow from 'assets/images/sprite/white-right-arrow.svg'
import { SEX_OPTIONS } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'Your cat is:'

const Colors = {
  girl: '#EA8799',
  girlLight: '#FFE6EB',
  boy: '#5C86EC',
  boyLight: '#D3E0FF',
}

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        setAnswersAction({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return (
    <S.Wrapper3>
      <S.Content>
        <S.PlanTitle>
          Personalized
          <br />
          cat training plan
        </S.PlanTitle>
        <S.Subtitle2>based on your cat behavior</S.Subtitle2>

        <S.QuestionWrapper>
          <S.Question>{QUESTION}</S.Question>

          <StyledOption.OptionsContainerVariant2>
            <S.Option {...optionProps} value={SEX_OPTIONS.GIRL}>
              <S.OptionContent
                background={Colors.girlLight}
                borderColor={Colors.girl}
              >
                <S.CatSexImage src={girl} alt="crescent" />

                <S.TextWrapper background={Colors.girl}>
                  <S.OptionText>Girl</S.OptionText>
                  <Arrow width={24} />
                </S.TextWrapper>
              </S.OptionContent>
            </S.Option>
            <S.Option {...optionProps} value={SEX_OPTIONS.BOY}>
              <S.OptionContent
                background={Colors.boyLight}
                borderColor={Colors.boy}
              >
                <S.CatSexImage src={boy} alt="crescent" />

                <S.TextWrapper background={Colors.boy}>
                  <S.OptionText>Boy</S.OptionText>
                  <Arrow width={24} />
                </S.TextWrapper>
              </S.OptionContent>
            </S.Option>
          </StyledOption.OptionsContainerVariant2>
        </S.QuestionWrapper>
      </S.Content>
    </S.Wrapper3>
  )
}

export const SexQuestionVariant3 = Component
