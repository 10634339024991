import React from 'react'
import { PageId } from 'constants/pages'
import { OptionType } from 'components/option/Option'
import { BODY_LANGUAGE_OPTIONS_VALUES } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'
import { Trans, useTranslation } from 'react-i18next'
import { StyledOption } from 'components/option/Option.styles'
import { QuestionButton } from 'components/buttons/QuestionButton'

type TOptionType = {
  type: OptionType
  name?: PageId | undefined
}

type TProps = {
  optionProps: TOptionType
  onOpenModal: (value: string) => void
}

export const OldBodyLanguageOptions: React.FC<TProps> = ({
  optionProps,
  onOpenModal,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <S.Wrapper>
        <S.Content>
          <S.Title>
            <Trans
              i18nKey="onboarding.bodyLanguage.question"
              components={[<br />]}
            />
          </S.Title>

          <StyledOption.OptionsContainer>
            <S.Option
              {...optionProps}
              onChange={onOpenModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.QUITE_WELL}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.usuallyInterpret`}
              </QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              onChange={onOpenModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.BASIC}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.basicUnderstanding`}
              </QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              onChange={onOpenModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.CHALLENGING}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.challengingToUnderstand`}
              </QuestionButton>
            </S.Option>
            <S.Option
              {...optionProps}
              onChange={onOpenModal}
              value={BODY_LANGUAGE_OPTIONS_VALUES.NO_IDEA}
            >
              <QuestionButton>
                {t`onboarding.bodyLanguage.noIdea`}
              </QuestionButton>
            </S.Option>
          </StyledOption.OptionsContainer>
        </S.Content>
      </S.Wrapper>
    </>
  )
}
