import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  STICKY_HEADER,
  SUBSCRIPTION_SECTION_IDS,
} from 'modules/subscriptions/constants'
import { useTranslation } from 'react-i18next'
import { LOCALES } from 'root-constants'
import lightning from 'assets/images/lightning.svg'
import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'
import { useSelector } from 'react-redux'
import { selectLanguage } from 'root-redux/common/common-selects'
import { StyledHeader as S } from './StickyHeader.styles'
import { eventLogger, Events, ScreenName } from 'services/eventLogger-service'
import { selectEmail } from 'root-redux/user/user-selects'
import { getScrollLevel } from 'helpers/getScrollLevel'

const subscriptionButtonsIds = [
  SUBSCRIPTION_SECTION_IDS.chooseMyPlan,
  SUBSCRIPTION_SECTION_IDS.startMyPlanButton,
]

const TEN_MINUTES_IN_SECONDS = 600

type TProps = {
  isHide?: boolean
  buttonText?: string
  title?: string
  screenName?: string
  isOfferScreen?: boolean
}

export const StickyHeader: React.FC<TProps> = ({
  isHide = false,
  buttonText = 'subscription.getItNow',
  title = 'subscription.offerExpires',
  screenName = ScreenName.SALES,
  isOfferScreen = false,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const email = useSelector(selectEmail)
  const timerElementRef = useRef<HTMLDivElement>(null)

  const isLongLocale = useMemo(() => language === LOCALES.ES, [language])

  useEffect(() => {
    sessionStorage.removeItem(Events.VIEW_PRICE)

    const blocks = subscriptionButtonsIds.map((item) =>
      document.getElementById(item),
    )

    const observer = new IntersectionObserver(
      (entries) => {
        const el = document.getElementById(STICKY_HEADER)

        if (!el) {
          return
        }

        entries.forEach((entry) => {
          if (subscriptionButtonsIds.includes(entry.target.id)) {
            el.dataset.isHide = entry.isIntersecting ? 'true' : 'false'
          }

          if (
            SUBSCRIPTION_SECTION_IDS.startMyPlanButton.includes(entry.target.id)
          ) {
            const viewPriceEventSent = sessionStorage.getItem(Events.VIEW_PRICE)

            if (viewPriceEventSent) return

            const scrollLvl = getScrollLevel()

            if (entry.isIntersecting) {
              eventLogger.logSubscriptionsViewPrice({
                method: 'scrolled',
                email,
                scrollLvl,
                screenName,
              })
              sessionStorage.setItem(Events.VIEW_PRICE, 'true')
            }
          }
        })
      },
      {
        rootMargin: '0px',
        threshold: 0.2,
      },
    )

    blocks.forEach((block) => block && observer?.observe(block as Element))
  }, [])

  const scrollDown = useCallback(() => {
    const viewPriceEventSent = sessionStorage.getItem(Events.VIEW_PRICE)

    if (!viewPriceEventSent) {
      const scrollLvl = getScrollLevel()

      eventLogger.logSubscriptionsViewPrice({
        method: 'сta_btn_clicked',
        email,
        scrollLvl,
        screenName,
      })
      sessionStorage.setItem(Events.VIEW_PRICE, 'true')
    }

    const el = document.getElementById(SUBSCRIPTION_SECTION_IDS.interestingFact)

    if (el) {
      const yScroll = isOfferScreen ? 150 : 0
      const yOffset = el.getBoundingClientRect().top + window.scrollY + yScroll
      window.scrollTo({ top: yOffset, behavior: 'smooth' })
    }
  }, [])

  useTimerForTarget(timerElementRef, TEN_MINUTES_IN_SECONDS)

  return (
    <S.HeaderWrapper id={STICKY_HEADER} data-is-hide={isHide}>
      <S.ContentWrapper>
        <S.Lightning src={lightning} />
        <S.TextWrapper isLongLocale={isLongLocale}>
          <S.Text isLongLocale={isLongLocale}>{t(title)}</S.Text>
          <S.Timer ref={timerElementRef} />
        </S.TextWrapper>
        <S.ButtonWrapper>
          <S.ButtonBorder />
          <S.Button onClick={scrollDown} isLongLocale={isLongLocale}>
            {t(buttonText)}
          </S.Button>
        </S.ButtonWrapper>
      </S.ContentWrapper>
    </S.HeaderWrapper>
  )
}
