import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PageId } from 'constants/pages'

import screen1 from 'assets/images/slider-cat-1.jpg'
import screen2 from 'assets/images/slider-cat-2.jpg'
import screen3 from 'assets/images/slider-cat-3.jpg'
import screen4 from 'assets/images/slider-cat-4.jpg'
import screen5 from 'assets/images/slider-cat-5.jpg'
import screen6 from 'assets/images/slider-cat-6.jpg'
import screen7 from 'assets/images/slider-cat-7.jpg'
import Girl from 'assets/images/sprite/female.svg'
import Boy from 'assets/images/sprite/male.svg'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { QuestionButton } from 'components/buttons/QuestionButton'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { useTranslation } from 'react-i18next'
import { SexQuestionVariant3 } from 'pages/questions/SexQuestionVariant3'
import { LOCALES, MEOWZ2_1 } from 'root-constants'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { SEX_OPTIONS } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'What gender is your cat?'

export const Component: TDynamicPageComponent = () => {
  const { t, i18n } = useTranslation()
  const dispatch = useDispatch()
  const cohort = useSelector(selectVariantCohort)

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const shouldShowNewContent = useMemo(
    () => cohort === MEOWZ2_1 && i18n.language === LOCALES.EN,
    [cohort, i18n.language],
  )

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        saveUserAnswer({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId, handleContinue],
  )

  return shouldShowNewContent ? (
    <SexQuestionVariant3 />
  ) : (
    <S.SexQuestionWrapper>
      <S.ContentWithSlider>
        <S.SliderWrapper>
          <Swiper
            loop
            slidesPerView="auto"
            spaceBetween={8}
            centeredSlides
            autoplay={{
              delay: 1000,
            }}
          >
            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen1} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen2} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen3} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen4} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen5} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>

            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen6} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>
            <SwiperSlide>
              {({ isActive }) => (
                <motion.div
                  initial={{ scale: 0.92 }}
                  animate={{
                    scale: isActive ? 1 : 0.92,
                  }}
                >
                  <S.SliderImage src={screen7} alt="dog" />
                </motion.div>
              )}
            </SwiperSlide>
          </Swiper>
        </S.SliderWrapper>
        <S.WelcomeTitle>{t`onboarding.sex.question`}</S.WelcomeTitle>
        <S.OptionsContainerWelcome>
          <S.OptionVariant2 {...optionProps} value={SEX_OPTIONS.GIRL}>
            <QuestionButton>
              <Girl height="24" width="24" />
              {t`onboarding.sex.female`}
            </QuestionButton>
          </S.OptionVariant2>
          <S.OptionVariant2 {...optionProps} value={SEX_OPTIONS.BOY}>
            <QuestionButton>
              <Boy height="24" width="24" />
              {t`onboarding.sex.male`}
            </QuestionButton>
          </S.OptionVariant2>
        </S.OptionsContainerWelcome>
        <S.Subtitle>{t`onboarding.sex.description`}</S.Subtitle>
      </S.ContentWithSlider>
    </S.SexQuestionWrapper>
  )
}

Component.progressBar = 'controlled'
Component.progressBarHidden = true
Component.id = PageId.CAT_SEX_2

export const SexQuestionVariant2 = Component
