import { FlowContext } from 'components/FlowContext'
import { InputVariantSelect } from 'components/inpust-select/InputVariantSelect'
import { TDynamicPageComponent } from 'models/common.model'
import { IInputFieldState } from 'models/inputField.model'
import { StyledCatBreed as S } from 'pages/questions/cat-breed/Breed.styles'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IDK } from 'root-constants'
import {
  selectAnswers,
  selectIsFetching,
} from 'root-redux/common/common-selects'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { PageId } from 'constants/pages'
import {
  getFilteredBreedNames,
  getFilteredPopularBreeds,
} from './cat-breed/helpers'

const QUESTION = 'What breed is your cat?'

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetching)
  const [emailHasFocus, setEmailHasFocus] = useState(false)
  const [isAutoCompleteShown, setIsAutoCompleteShown] = useState(false)
  const savedAnswers = useSelector(selectAnswers)

  const [breed, setBreed] = useState<IInputFieldState>({
    value: '',
    isValid: true,
    validationText: '',
  })
  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)
  const isButtonDisabled = useMemo(
    () => !breed.value || !breed.isValid || isFetching,
    [breed.isValid, breed.value, isFetching],
  )

  const filteredBreedNames = useMemo(
    () => getFilteredBreedNames(breed.value),
    [breed.value],
  )

  const filteredPopularBreed = useMemo(
    () => getFilteredPopularBreeds(breed.value),
    [breed.value],
  )

  useEffect(() => {
    filteredBreedNames.length && emailHasFocus
      ? setIsAutoCompleteShown(true)
      : setIsAutoCompleteShown(false)
  }, [breed.value, emailHasFocus, filteredBreedNames.length])

  const onChange = useCallback(
    ({ target: { value } }) => {
      setBreed((prevState) => ({
        ...prevState,
        value,
        isValid: true,
        validationText: '',
      }))
    },
    [setBreed],
  )

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      saveUserAnswer({
        question: QUESTION,
        answers: breed.value,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, breed.value, onNextStep])

  const continueWithoutBreed = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      saveUserAnswer({
        question: QUESTION,
        answers: IDK,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, onNextStep, dispatch])

  useEffect(() => {
    if (
      savedAnswers &&
      currentPageId &&
      savedAnswers[currentPageId] &&
      savedAnswers[currentPageId] !== IDK
    ) {
      setBreed({
        value: savedAnswers[currentPageId] as string,
        isValid: true,
        validationText: '',
      })
    }
  }, [savedAnswers, currentPageId])

  return (
    <S.Wrapper>
      <S.Content>
        <S.Title>{QUESTION}</S.Title>
        <S.InputWrapper>
          <InputVariantSelect
            placeholder="Choose breed"
            value={breed.value}
            name="breeds"
            autoComplete="off"
            selectIconVisible={!breed.value.length}
            setIsAutoCompleteShown={setIsAutoCompleteShown}
            isDataShown={isAutoCompleteShown}
            onChange={onChange}
            onFocus={() => setEmailHasFocus(true)}
          />
          {isAutoCompleteShown && (
            <S.AutoCompleteWrapper>
              {filteredPopularBreed.length >= 1 &&
                filteredBreedNames.length > 1 && (
                  <>
                    <S.AutocompleteSubtitle>
                      MOST POPULAR
                    </S.AutocompleteSubtitle>
                    {filteredPopularBreed.map((value) => (
                      <S.AutoCompleteButton
                        type="button"
                        key={value}
                        value={value}
                        onClick={(e) => {
                          onChange({
                            target: {
                              value: (e.currentTarget as HTMLButtonElement)
                                .value,
                            },
                          })
                          setEmailHasFocus(false)
                        }}
                      >
                        <S.AutoCompleteButtonTextWrapper>
                          {value}
                        </S.AutoCompleteButtonTextWrapper>
                      </S.AutoCompleteButton>
                    ))}
                  </>
                )}
              <S.AutocompleteSubtitle>A-Z</S.AutocompleteSubtitle>
              {filteredBreedNames.map((value) => (
                <S.AutoCompleteButton
                  type="button"
                  key={value}
                  value={value}
                  onClick={(e) => {
                    onChange({
                      target: {
                        value: (e.currentTarget as HTMLButtonElement).value,
                      },
                    })
                    setEmailHasFocus(false)
                  }}
                >
                  <S.AutoCompleteButtonTextWrapper>
                    {value}
                  </S.AutoCompleteButtonTextWrapper>
                </S.AutoCompleteButton>
              ))}
            </S.AutoCompleteWrapper>
          )}
        </S.InputWrapper>
        <GreenArrowButton
          isButtonDisabled={isButtonDisabled}
          onClick={handleContinue}
        />
        <S.OtherBreedLink onClick={continueWithoutBreed}>
          I don’t know my cat’s breed
        </S.OtherBreedLink>
      </S.Content>
    </S.Wrapper>
  )
}

Component.progressBar = 'controlled'
Component.id = PageId.CAT_BREED
Component.hasHeader = true

export const BreedQuestion = Component
