import React, { useEffect, useMemo, useRef } from 'react'
import {
  STICKY_HEADER,
  SUBSCRIPTION_SECTION_IDS,
} from 'modules/subscriptions/constants'
import { useTranslation } from 'react-i18next'
import { LOCALES } from 'root-constants'
import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'
import { useSelector } from 'react-redux'
import { selectLanguage } from 'root-redux/common/common-selects'
import { StyledHeader as S } from './StickyHeader.styles'
import { eventLogger, Events, ScreenName } from 'services/eventLogger-service'
import { selectEmail } from 'root-redux/user/user-selects'
import { getScrollLevel } from 'helpers/getScrollLevel'

const subscriptionButtonsIds = [
  SUBSCRIPTION_SECTION_IDS.chooseMyPlan,
  SUBSCRIPTION_SECTION_IDS.startMyPlanButton,
]

const TEN_MINUTES_IN_SECONDS = 600

type TProps = {
  buttonText?: string
  title?: string
  screenName?: string
  onButtonClick: () => void
}

export const StickyHeaderVariant2: React.FC<TProps> = ({
  buttonText = 'subscription.getItNow',
  title = 'subscription.offerExpires',
  screenName = ScreenName.SALES,
  onButtonClick,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const email = useSelector(selectEmail)
  const timerElementRef = useRef<HTMLDivElement>(null)

  const isLongLocale = useMemo(() => language === LOCALES.ES, [language])

  useEffect(() => {
    sessionStorage.removeItem(Events.VIEW_PRICE)

    const blocks = subscriptionButtonsIds.map((item) =>
      document.getElementById(item),
    )

    const observer = new IntersectionObserver(
      (entries) => {
        const el = document.getElementById(STICKY_HEADER)

        if (!el) {
          return
        }

        entries.forEach((entry) => {
          if (
            SUBSCRIPTION_SECTION_IDS.startMyPlanButton.includes(entry.target.id)
          ) {
            const viewPriceEventSent = sessionStorage.getItem(Events.VIEW_PRICE)

            if (viewPriceEventSent) return

            const scrollLvl = getScrollLevel()

            if (entry.isIntersecting) {
              eventLogger.logSubscriptionsViewPrice({
                method: 'scrolled',
                email,
                scrollLvl,
                screenName,
              })
              sessionStorage.setItem(Events.VIEW_PRICE, 'true')
            }
          }
        })
      },
      {
        rootMargin: '0px',
        threshold: 0.2,
      },
    )

    blocks.forEach((block) => block && observer?.observe(block as Element))
  }, [])

  useTimerForTarget(timerElementRef, TEN_MINUTES_IN_SECONDS)

  return (
    <S.HeaderContentWrapper>
      <S.HeaderWrapper2 id={STICKY_HEADER}>
        <S.ContentWrapper2>
          <S.TextWrapper2 isLongLocale={isLongLocale}>
            <S.Text2 isLongLocale={isLongLocale}>{t(title)}</S.Text2>
            <S.Timer2 ref={timerElementRef} />
          </S.TextWrapper2>
          <S.ButtonWrapper>
            <S.ButtonBorder2 />
            <S.Button2 onClick={onButtonClick} isLongLocale={isLongLocale}>
              {t(buttonText)}
            </S.Button2>
          </S.ButtonWrapper>
        </S.ContentWrapper2>
      </S.HeaderWrapper2>
    </S.HeaderContentWrapper>
  )
}
