import React, { useEffect } from 'react'
import { StyledModal as S } from './Modal.styles'

type TProps = {
  error?: string
  isShown: boolean
  onClose: () => void
}

export const Modal: React.FC<React.PropsWithChildren<TProps>> = ({
  error,
  isShown,
  onClose,
  children,
}) => {
  useEffect(() => {
    document.body.style.overflow = isShown ? 'hidden' : 'unset'
  }, [isShown])

  useEffect(
    () => () => {
      document.body.style.overflow = 'unset'
    },
    [],
  )

  return (
    <S.Wrapper isShown={isShown}>
      <S.Content>
        {error ? (
          <>
            <S.ErrorImage />
            <S.Title>Error</S.Title>
            <S.Error>{error}</S.Error>
          </>
        ) : (
          children
        )}
        <S.Button type="button" onClick={onClose}>
          OK
        </S.Button>
      </S.Content>
    </S.Wrapper>
  )
}
