import { TIdToPageMap } from 'models/common.model'
import { SexQuestion } from 'pages/questions/SexQuestion'
import { SexQuestionVariant2 } from 'pages/questions/SexQuestionVariant2'
import { AgeQuestion } from 'pages/questions/AgeQuestion'
import { AgeQuestionVariant2 } from 'pages/questions/AgeQuestionVariant2'
import { BreedQuestion } from 'pages/questions/BreedQuestion'
import { BreedQuestionVariant2 } from 'pages/questions/BreedQuestionVariant2'
import { ShelterQuestion } from 'pages/questions/ShelterQuestion'
import { CommandsQuestion } from 'pages/questions/CommandsQuestion'
import { Urge } from 'pages/urge/Urge'
import { ReactNewPeopleQuestion } from 'pages/questions/ReactNewPeopleQuestion'
import { NoiseResponseQuestion } from 'pages/questions/NoiseResponseQuestion'
import { ToysQuestion } from 'pages/questions/ToysQuestion'
import { GroomingQuestion } from 'pages/questions/GroomingQuestion'
import { TouchReactQuestion } from 'pages/questions/TouchReactQuestion'
import { BehaviorIssuesQuestion } from 'pages/questions/BehaviorIssuesQuestion'
import { BehaviorIssuesQuestionVariant2 } from 'pages/questions/BehaviorIssuesQuestionVariant2'
import { UrgeImportant } from 'pages/urge/UrgeImportant'
import { UrgeFantasticVariant2 } from 'pages/urge/UrgeFantasticVariant2'
import { ComeWhenCalledQuestion } from 'pages/questions/ComeWhenCalledQuestion'
import { ClickerTrainedQuestion } from 'pages/questions/ClickerTrainedQuestion'
import { MedicalConditionQuestion } from 'pages/questions/MedicalConditionQuestion'
import { ActivityQuestion } from 'pages/questions/ActivityQuestion'
import { MainGoalQuestion } from 'pages/questions/MainGoalQuestion'
import { MainGoalQuestionVariant2 } from 'pages/questions/MainGoalQuestionVariant2'
import { TimeQuestion } from 'pages/questions/CatTimeQuestion'
import { BodyLanguageQuestion } from 'pages/questions/BodyLanguageQuestion'
import { BodyLanguageQuestionVariant2 } from 'pages/questions/BodyLanguageQuestionVariant2'
import { PrepareFlow } from 'pages/prepare-flow/PrepareFlow'
import { EmailPage } from 'modules/email/pages/EmailPage'
import { EmailVariant2 } from 'modules/email/pages/EmailVariant2'
import { EmailVariantCs } from 'modules/email/pages/EmailPageVariantCs'
import { SubscriptionsVariant3 } from 'modules/subscriptions/pages/SubscriptionsVariant3'
import { Subscriptions } from 'modules/subscriptions/pages/Subscriptions'
import { SubscriptionsVariant2 } from 'modules/subscriptions/pages/SubscriptionsVariant2'
import { SubscriptionsVariantCs } from 'modules/subscriptions/pages/SubscriptionsVariantCs'
import { Upsell } from 'modules/upsell/pages/Upsell'
import { UpsellVariantCs } from 'modules/upsell/pages/UpsellVariantCs'
import { CancelOfferPage } from 'modules/subscriptions/pages/cancel-offer/CancelOfferPage'
import { CancelOfferVariant2 } from 'modules/subscriptions/pages/cancel-offer/CancelOfferVariant2'
import { CancelOfferVariant3 } from 'modules/subscriptions/pages/cancel-offer/CancelOfferVariant3'
import { LastChanceOfferPage } from 'modules/subscriptions/pages/last-cahnce-offer/LastChanceOfferPage'
import { LastChanceOfferVariant2 } from 'modules/subscriptions/pages/last-cahnce-offer/LastChanceOfferVariant2'
import { LastChanceOfferVariant3 } from 'modules/subscriptions/pages/last-cahnce-offer/LastChanceOfferPageVariant3'
import { Payment } from 'modules/payment/pages/Payment'
import { PaymentVariant2 } from 'modules/payment/pages/PaymentVariant2'
import { PaymentVariantCs } from 'modules/payment/pages/PaymentVariantCs'
import { PaymentPage } from 'modules/payment/pages/PaymentPage'
import { WelcomePage } from 'pages/questions/WelcomePage'
import { Account } from 'modules/account/Account'
import { DownloadApp } from 'pages/download/DownloadApp'
import { DownloadAppWithPdf } from 'pages/download/DownloadAppWithPdf'
import { AgeQuestionVariant3 } from 'pages/questions/AgeQuestionVariant3'
import { UrgeSocialProof } from 'pages/urge/UrgeSocialProof'
import { MainGoalQuestionVariant3 } from 'pages/questions/MainGoalQuestionVariant3'
import { BreedQuestionVariant3 } from 'pages/questions/BreedQuestionVariant3'
import { ShelterQuestionVariant2 } from 'pages/questions/ShelterQuesitonVariant2'
import { UrgeSocialProofVariant2 } from 'pages/urge/UrgeSocialProofVariant2'
import { CatAgression } from 'pages/questions/CatAgressionQuestion'
import { PlaytimeAttack } from 'pages/questions/PlaytimeAttackQuestion'
import { CatWakingUp } from 'pages/questions/CatWakingUpQuestion'
import { UrgeSocialProofVariant3 } from 'pages/urge/UrgeSocialProofVariant3'
import { CatScratching } from 'pages/questions/CatScratchingQuestion'
import { CatBreakItems } from 'pages/questions/CatBreakItemsQuestion'
import { CatRespondToNo } from 'pages/questions/CatRespondToNoQuestion'
import { CatTraining } from 'pages/questions/CatTrainingQuestion'
import { PrepareFlowVariant2 } from 'pages/prepare-flow/PrepareFlowVariant2'
import { EmailVariant3 } from 'modules/email/pages/EmailVariant3'
import { SubscriptionsVariant4 } from 'modules/subscriptions/pages/SubscriptionsVariant4'
import { PaymentVariant3 } from 'modules/payment/pages/PaymentPageVariant3'
import { CancelOfferVariant4 } from 'modules/subscriptions/pages/cancel-offer/CancelOfferVariant4'

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  cat_sex: SexQuestion,
  cat_sex_2: SexQuestionVariant2,
  cat_age: AgeQuestion,
  cat_age_2: AgeQuestionVariant2,
  cat_age_3: AgeQuestionVariant3,
  cat_breed: BreedQuestion,
  cat_breed_2: BreedQuestionVariant2,
  cat_breed_3: BreedQuestionVariant3,
  shelter_cat: ShelterQuestion,
  cat_commands: CommandsQuestion,
  urge_cat: Urge,
  urge_social_proof: UrgeSocialProof,
  cat_react_new_people: ReactNewPeopleQuestion,
  noise_response: NoiseResponseQuestion,
  cat_toys: ToysQuestion,
  cat_grooming: GroomingQuestion,
  cat_touch_react: TouchReactQuestion,
  cat_behavior_issues: BehaviorIssuesQuestion,
  cat_behavior_issues_2: BehaviorIssuesQuestionVariant2,
  urge_cat_important: UrgeImportant,
  urge_cat_2: UrgeFantasticVariant2,
  cat_come_when_called: ComeWhenCalledQuestion,
  cat_clicker_trained: ClickerTrainedQuestion,
  medical_conditions: MedicalConditionQuestion,
  cat_activity: ActivityQuestion,
  cat_main_goal: MainGoalQuestion,
  cat_main_goal_2: MainGoalQuestionVariant2,
  cat_time: TimeQuestion,
  body_language: BodyLanguageQuestion,
  body_language_2: BodyLanguageQuestionVariant2,
  cat_prepare_flow: PrepareFlow,
  email_meowz: EmailPage,
  email_meowz_2: EmailVariant2,
  email_meowz_cs: EmailVariantCs,
  subscriptions: SubscriptionsVariant3,
  cat_subscriptions: Subscriptions,
  cat_subscriptions_2: SubscriptionsVariant2,
  cat_subscriptions_cs: SubscriptionsVariantCs,
  upsell: Upsell,
  upsell_cs: UpsellVariantCs,
  cat_cancel_offer: CancelOfferPage,
  cat_cancel_offer_2: CancelOfferVariant2,
  cancel_offer: CancelOfferVariant3,
  last_chance_offer: LastChanceOfferPage,
  last_chance_offer_cat_2: LastChanceOfferVariant2,
  last_chance_offer_3: LastChanceOfferVariant3,
  meowz_payment: Payment,
  meowz_payment_2: PaymentVariant2,
  meowz_payment_cs: PaymentVariantCs,
  meowz_payment_3: PaymentPage,
  welcome_cat_1: WelcomePage,
  account: Account,
  download: DownloadApp,
  downloadPdf: DownloadAppWithPdf,
  cat_main_goal_3: MainGoalQuestionVariant3,
  shelter_cat_2: ShelterQuestionVariant2,
  urge_social_proof_2: UrgeSocialProofVariant2,
  cat_agression: CatAgression,
  playtime_attack: PlaytimeAttack,
  cat_waking_up: CatWakingUp,
  urge_social_proof_3: UrgeSocialProofVariant3,
  cat_scratching: CatScratching,
  cat_break_items: CatBreakItems,
  cat_respond_to_no: CatRespondToNo,
  cat_time_2: CatTraining,
  cat_prepare_flow_2: PrepareFlowVariant2,
  email_meowz_3: EmailVariant3,
  cat_subscriptions_3: SubscriptionsVariant4,
  meowz_payment_4: PaymentVariant3,
  cat_cancel_offer_3: CancelOfferVariant4,
}
