import { FlowContext } from 'components/FlowContext'
import {
  PagePath,
  PLAN_TAGS,
  SUBSCRIPTION_SECTION_IDS,
} from 'modules/subscriptions/constants'
import React, { useCallback, useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PLAN_TYPES } from 'root-constants'
import { googleAnalyticsLogger } from 'services/googleAnalytics-service'
import { logSalePageShown } from 'helpers/logSalePageShown'
import {
  selectFirebaseRemoteConfig,
  selectLanguage,
  selectVariantCohort,
} from 'root-redux/common/common-selects'
import { selectEmail, selectUUID } from 'root-redux/user/user-selects'
import {
  selectSubscription,
  selectSubscriptions,
  selectSubscriptionsPlansLoadedFlag,
} from 'root-redux/payment/payment-selects'
import {
  getPaymentConfigAction,
  getSubscriptionPlans,
  setIsCancelOfferAppliedAction,
  setSelectedSubscriptionAction,
  setSubscriptionsPlansWasLoadedAction,
} from 'root-redux/payment/payment-actions'
import { ISubscription } from 'models/suscriptions.model'
import { eventLogger, Events, ScreenName } from 'services/eventLogger-service'
import { TAppDispatch } from 'root-redux/store'

export interface ISubscriptionsPageProps {
  onButtonClick: () => void
  onMenuClick: () => void
  selectedSubscription: ISubscription
  onSubscriptionSelect: (selectedSubscription?: ISubscription) => void
  availableSubscriptions: ISubscription[]
  isNonPayerFlow: boolean
}

interface IProps {
  isNonPayerFlow?: boolean
  skipPlanLoading?: boolean
  isUpsell?: boolean
  screenName?: ScreenName
  pageNumber?: number | null
  children?:
    | React.ReactNode
    | ((props: ISubscriptionsPageProps) => React.ReactNode)
  planType: PLAN_TYPES
}

export const SubscriptionsWrapper: React.FC<IProps> = ({
  isNonPayerFlow = false,
  isUpsell = false,
  screenName,
  pageNumber = null,
  children,
  planType = PLAN_TYPES.PURCHASE,
  skipPlanLoading = false,
}) => {
  const dispatch: TAppDispatch = useDispatch()
  const selectedSubscription = useSelector(selectSubscription)

  const cohort = useSelector(selectVariantCohort)
  const uuid = useSelector(selectUUID)
  const email = useSelector(selectEmail)
  const { onSkipSteps } = useContext(FlowContext)
  const language = useSelector(selectLanguage)
  const firebaseRemoteConfig = useSelector(selectFirebaseRemoteConfig)

  const availableSubscriptions = useSelector(selectSubscriptions)
  // prevent sending sale plans in event from cancel offer page
  const shouldSendSaleEvent = useSelector(selectSubscriptionsPlansLoadedFlag)

  useEffect(() => {
    if (
      availableSubscriptions &&
      availableSubscriptions.length &&
      shouldSendSaleEvent
    ) {
      logSalePageShown({
        availableSubscriptions,
        email,
        ...(pageNumber === null ? {} : { pageNumber }),
        ...(screenName ? { screenName } : {}),
      })
      dispatch(setSubscriptionsPlansWasLoadedAction(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uuid,
    language,
    firebaseRemoteConfig,
    availableSubscriptions,
    shouldSendSaleEvent,
  ])

  useEffect(() => {
    window.scrollTo({ top: 0 })
    const box = document.getElementById(SUBSCRIPTION_SECTION_IDS.planBlock)
    let prevRatio = 0

    const observer = new IntersectionObserver((entries) => {
      const viewPriceEventSent = sessionStorage.getItem(Events.VIEW_PRICE)
      if (viewPriceEventSent) {
        return
      }

      entries.forEach((entry) => {
        const curRatio = entry.intersectionRatio

        if (curRatio > prevRatio) {
          eventLogger.logSubscriptionsViewPrice({
            method: 'scrolled',
            screenName: isNonPayerFlow ? ScreenName.CANCEL : ScreenName.SALES,
            email,
          })
          sessionStorage.setItem(Events.VIEW_PRICE, 'true')
        }

        prevRatio = curRatio
      })
    })

    if (box) {
      observer.observe(box as Element)
    }

    if (!skipPlanLoading) {
      if (!isNonPayerFlow && !isUpsell) {
        dispatch(getPaymentConfigAction())
      }

      if (isNonPayerFlow) {
        dispatch(
          getSubscriptionPlans(planType, [PLAN_TAGS.NO_TAX, PLAN_TAGS.CANCEL]),
        )
      } else {
        dispatch(
          getSubscriptionPlans(planType, isUpsell ? [] : [PLAN_TAGS.NO_TAX]),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (availableSubscriptions &&
        availableSubscriptions.includes(
          selectedSubscription as ISubscription,
        )) ||
      !availableSubscriptions
    ) {
      return
    }

    const subscription =
      availableSubscriptions.find(({ isDefault }) => !!isDefault) ||
      availableSubscriptions[0]

    dispatch(setSelectedSubscriptionAction(subscription))
  }, [availableSubscriptions, dispatch, selectedSubscription])

  const handleShowPayment = useCallback(() => {
    dispatch(setIsCancelOfferAppliedAction(isNonPayerFlow))

    window.fbq('track', 'AddToCart', {}, { eventID: uuid })
    window.snaptr('track', 'START_CHECKOUT', { user_email: email })
    googleAnalyticsLogger.logPageView(`${PagePath.SUBSCRIPTIONS}/${cohort}`)
    googleAnalyticsLogger.logAddingToCart(selectedSubscription as ISubscription)

    onSkipSteps()
  }, [
    dispatch,
    isNonPayerFlow,
    uuid,
    email,
    cohort,
    selectedSubscription,
    onSkipSteps,
  ])

  const handleSelectSubscription = useCallback(
    (subscription?: ISubscription): void => {
      if (!subscription) return

      dispatch(setSelectedSubscriptionAction(subscription))
    },
    [dispatch],
  )

  return (
    <>
      {typeof children === 'function'
        ? children({
            onButtonClick: handleShowPayment,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            selectedSubscription,
            onSubscriptionSelect: handleSelectSubscription,
            availableSubscriptions: availableSubscriptions as ISubscription[],
            isNonPayerFlow,
          })
        : children}
    </>
  )
}
