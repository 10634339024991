import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext } from 'react'
import { PageId } from 'constants/pages'
import autor from 'assets/images/user-12.jpg'
import annotation from 'assets/images/annotation-img.png'
import { FlowContext } from 'components/FlowContext'
import { useDispatch } from 'react-redux'
import { setAnswersAction } from 'root-redux/common/common-actions'
import { CommentVariant2 } from 'pages/prepare-flow/components/CommentVariant2'
import { GreenArrowButtonVariant2 } from 'components/buttons/arrow-button/GreenArrowButtonVariant2'
import { StyledUrge as S } from './Urge.styles'

const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()
  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  return (
    <S.UrgeWrapper3>
      <S.UrgeContent2>
        <S.Title2>
          <span>55 000+</span> cat owners
        </S.Title2>
        <S.Subtitle2>already use Meowz</S.Subtitle2>
        <S.CommentWrapper>
          <S.AnnotationTop src={annotation} />
          <CommentVariant2 image={autor} autor="nicckl" title="Love this app">
            It’s very user friendly and easy to understand. Very good advice for
            caring for your cat!
          </CommentVariant2>
          <S.AnnotationBottom src={annotation} />
        </S.CommentWrapper>
        <S.StickyContainer>
          <S.ButtonWrapper>
            <GreenArrowButtonVariant2 onClick={handleContinue} />
          </S.ButtonWrapper>
        </S.StickyContainer>
      </S.UrgeContent2>
    </S.UrgeWrapper3>
  )
}

Component.id = PageId.URGE_SOCIAL_PROOF
Component.hasHeader = true

export const UrgeSocialProof = Component
