import { IRawVariant, IVariant } from 'models/variant.model'

export const getVariantFromRawVariant = (
  rawVariant: IRawVariant,
): IVariant => ({
  cohort: rawVariant.cohort_id,
  facebookPixelId: rawVariant.facebook_pixel_id,
  optimizeMap: rawVariant.optimize_map,
  steps: rawVariant.steps.map((step) => ({
    id: step.page_id,
    isPayment: step.is_payment,
    isQuestion: step.is_question,
    isSkippable: step.is_skippable,
    isSubscriptions: step.is_subscriptions,
    optimize: step.optimize
      ? {
          id: step.optimize.id,
          isDefault: step.optimize.is_default,
        }
      : null,
  })),
})
