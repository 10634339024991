import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { getOperatingSystemVersion } from 'helpers/getOperatingSystemVersion'
import { ICommonEventProps } from 'models/events.model'

export const getCommonEventProps = (
  utmTags: Record<string, string>,
): ICommonEventProps => ({
  is_prod_env: getCurrentEnv() === 'prod',
  cohort: utmTags.cohort,
  lang: utmTags.lang,
  device_type: getMobileOperatingSystem(),
  operating_system_version: getOperatingSystemVersion(),
  attribution: utmTags,
  viewport_width: window.screen.width,
  viewport_height: window.screen.height,
  viewport_size: `${window.screen.width}x${window.screen.height}`,
})
