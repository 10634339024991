import user1 from 'assets/images/user-1.jpg'
import user2 from 'assets/images/user-2.jpg'
import user3 from 'assets/images/user-3.jpg'
import user4 from 'assets/images/user-4.jpg'
import user5 from 'assets/images/user-5.jpg'
import user6 from 'assets/images/user-6.jpg'
import user7 from 'assets/images/user-7.jpg'
import user8 from 'assets/images/user-8.jpg'
import user9 from 'assets/images/user-9.jpg'
import user10 from 'assets/images/user-10.jpg'
import user11 from 'assets/images/user-11.jpg'
import user12 from 'assets/images/user-12.jpg'
import user13 from 'assets/images/user-13.jpg'

export const COMMENTS = [
  {
    title: 'onboarding.prepareFlow.reviews.item1.title',
    text: 'onboarding.prepareFlow.reviews.item1.text',
    avatar: user1,
    data: 'onboarding.prepareFlow.reviews.item1.date',
    autoplayDelay: 3000,
  },
  {
    title: 'onboarding.prepareFlow.reviews.item2.title',
    text: 'onboarding.prepareFlow.reviews.item2.text',
    avatar: user2,
    data: 'onboarding.prepareFlow.reviews.item2.date',
    autoplayDelay: 2000,
  },
  {
    title: 'onboarding.prepareFlow.reviews.item3.title',
    text: 'onboarding.prepareFlow.reviews.item3.text',
    avatar: user3,
    data: 'onboarding.prepareFlow.reviews.item3.date',
    autoplayDelay: 5000,
  },
  {
    title: 'onboarding.prepareFlow.reviews.item4.title',
    text: 'onboarding.prepareFlow.reviews.item4.text',
    avatar: user4,
    data: 'onboarding.prepareFlow.reviews.item4.date',
    autoplayDelay: 2000,
  },
  {
    title: 'onboarding.prepareFlow.reviews.item5.title',
    text: 'onboarding.prepareFlow.reviews.item5.text',
    avatar: user5,
    data: 'onboarding.prepareFlow.reviews.item5.date',
    autoplayDelay: 5000,
  },
]

export const COMMENTS_V2 = [
  {
    title: 'onboarding.comments.comment1.title',
    text: 'onboarding.comments.comment1.text',
    avatar: user6,
    data: 'onboarding.comments.comment1.date',
  },
  {
    title: 'onboarding.comments.comment2.title',
    text: 'onboarding.comments.comment2.text',
    avatar: user7,
    data: 'onboarding.comments.comment2.date',
  },
  {
    title: 'onboarding.comments.comment3.title',
    text: 'onboarding.comments.comment3.text',
    avatar: user8,
    data: 'onboarding.comments.comment3.date',
  },
  {
    title: 'onboarding.comments.comment4.title',
    text: 'onboarding.comments.comment4.text',
    avatar: user9,
    data: 'onboarding.comments.comment4.date',
  },
  {
    title: 'onboarding.comments.comment5.title',
    text: 'onboarding.comments.comment5.text',
    avatar: user10,
    data: 'onboarding.comments.comment5.date',
  },
  {
    title: 'onboarding.comments.comment6.title',
    text: 'onboarding.comments.comment6.text',
    avatar: user11,
    data: 'onboarding.comments.comment6.date',
  },
]

export const COMMENTS_V3 = [
  {
    title: 'Fantastic',
    text: 'Fantastic app. I cannot believe that it is free. Really good! Really appreciate all the tips',
    avatar: user1,
    autor: 'rayphf',
    autoplayDelay: 3000,
  },
  {
    title: 'Online Help',
    text: 'Enjoying it so far, it’s reassuring that you are doing the right things for your kitty.',
    avatar: user3,
    autor: 'Homemaker',
    autoplayDelay: 2000,
  },
  {
    title: 'Love this app',
    text: 'It’s very user friendly and easy to understand. Very good advice for caring for your cat!',
    avatar: user12,
    autor: 'nicckl',
    autoplayDelay: 5000,
  },
  {
    title: 'Excellent app',
    text: 'Easy to use, very helpful and informative information.',
    avatar: user13,
    autor: 'Jo@Ontario',
    autoplayDelay: 2000,
  },
  {
    title: 'Cat Curious',
    text: 'The Meowz app is easy to use and informative.',
    avatar: user2,
    autor: 'MaKat27',
    autoplayDelay: 5000,
  },
]
