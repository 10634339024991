import catImg from 'assets/images/cat-cancel.jpg'
import { TDynamicPageComponent } from 'models/common.model'
import { SubscriptionsWrapper } from 'modules/subscriptions/SubscriptionsWrapper'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { ISubscriptionsPageProps } from 'modules/subscriptions/types'
import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { LOCALES, PLAN_TYPES } from 'root-constants'
import Discount from 'assets/images/sprite/discount-label-65.svg'
import { ScreenName } from 'services/eventLogger-service'
import { PrivacyPolicyLink } from 'modules/subscriptions/components/privacy-policy/PrivacyPolicyLink'
import { FooterPriceText } from 'modules/subscriptions/components/footer-price/FooterPriceText'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import { PlanItemVariant2 } from 'modules/subscriptions/components/plan-blocks/PlanItemVariant2'
import { GuaranteeVariant3 } from 'modules/subscriptions/components/guarantee/GuaranteeVariant3'
import { StickyHeader } from 'modules/subscriptions/components/sticky-header/StickyHeader'
import { SelectPlanBlockVariant3 } from 'modules/subscriptions/components/plan-blocks/SelectPlanBlockVariant3'
import { PageId } from 'constants/pages'
import { useSelector } from 'react-redux'
import { selectCurrentYear } from 'root-redux/common/common-selects'
import { StyledSubscriptions as S } from '../Subscriptions.styles'

const CatSubscriptionsPage: React.FC<ISubscriptionsPageProps> = ({
  onButtonClick,
  onSubscriptionSelect,
  availableSubscriptions,
  isNonPayerFlow,
  selectedSubscription,
}) => {
  const { t, i18n } = useTranslation()
  const currentYear = useSelector(selectCurrentYear)
  const isPlanVisible = useMemo(() => window.screen.height > 390, [])
  const isLongLocale = useMemo(
    () => i18n.language === LOCALES.ES,
    [i18n.language],
  )

  return (
    <S.Wrapper>
      <S.Content2 isPlanVisible={isPlanVisible}>
        <StickyHeader isHide={isPlanVisible} screenName={ScreenName.CANCEL} />
        <S.TitleVariant3 isPlanVisible={isPlanVisible}>
          <Trans
            i18nKey="cancelOffer.title"
            components={[<br />, <strong />]}
          />
        </S.TitleVariant3>
        <S.CatImage src={catImg} />
        <SelectPlanBlockVariant3
          onButtonClick={onButtonClick}
          onSelect={onSubscriptionSelect}
          SelectPlanItem={PlanItemVariant2}
          availableSubscriptions={availableSubscriptions}
          isNonPayerFlow={isNonPayerFlow}
        />
        <GuaranteeVariant3 />
        <S.FinalBlockWrapper id={SUBSCRIPTION_SECTION_IDS.startMyPlanButton}>
          <S.PlanButtonWrapper>
            <S.DiscountImage isLongLocale={isLongLocale}>
              <Discount />
            </S.DiscountImage>
            <GreenArrowButton
              onClick={onButtonClick}
              text={t`commonComponents.getMyPlan`}
            />
          </S.PlanButtonWrapper>
          <S.AgreementWithDescription>
            <FooterPriceText selectedSubscription={selectedSubscription} />
            <br />
            {t`footer.disclaimer`}&nbsp;
            <TermsOfUseLink>{t`commonComponents.termsOfUse`}</TermsOfUseLink>
            &nbsp;|&nbsp;
            <PrivacyPolicyLink />
            <br />
            &nbsp;
            <Trans
              values={{ year: currentYear }}
              i18nKey="footer.allRightsReserved"
            />
          </S.AgreementWithDescription>
        </S.FinalBlockWrapper>
      </S.Content2>
    </S.Wrapper>
  )
}

const Component: TDynamicPageComponent = () => (
  <SubscriptionsWrapper
    screenName={ScreenName.CANCEL}
    planType={PLAN_TYPES.PURCHASE}
    isNonPayerFlow
  >
    {(props: ISubscriptionsPageProps) => <CatSubscriptionsPage {...props} />}
  </SubscriptionsWrapper>
)

Component.hasHeader = false
Component.id = PageId.CANCEL_OFFER_2

export const CancelOfferVariant2 = Component
