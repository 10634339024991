import styled, { css } from 'styled-components'
import { Color } from 'root-constants'
import LogoImg from 'assets/images/sprite/logo.svg'

const headerWrapperStyles = css`
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const logoTitleStyles = css`
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
`

export interface IProps {
  isUpsell: boolean
}

export const StyledHeader = {
  HeaderWrapper: styled.div<IProps>`
    ${headerWrapperStyles};
    position: ${(props) => (props.isUpsell ? 'fixed' : 'absolute')};
    background-color: ${(props) =>
      props.isUpsell ? '#4A23A5' : 'transparent'};
    border-bottom: ${(props) =>
      props.isUpsell ? '1px solid #7F5FC9' : '1px solid rgba(0, 0, 0, 0.1)'};
    z-index: ${(props) => (props.isUpsell ? 3 : 1)};
  `,
  Header: styled.div`
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 375px;
    margin: 0 auto;
  `,
  LogoWrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  Logo: styled(LogoImg)`
    width: 32px;
    height: 32px;
    margin-right: 10px;
  `,
  LogoTitle: styled.h1<IProps>`
    ${logoTitleStyles};
    color: ${(props) => (props.isUpsell ? Color.WHITE : Color.PRIMARY_TEXT)};
  `,
  LogoTitleWhite: styled.h1`
    ${logoTitleStyles};
    color: ${Color.WHITE};
  `,
}
