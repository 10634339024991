import { FlowContext } from 'components/FlowContext'
import { TDynamicPageComponent } from 'models/common.model'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import arrowBounce from 'assets/animation/arrowBounce.json'
import fantasticCat from 'assets/images/fantastic-cat.jpg'
import Arrow from 'assets/images/sprite/dark-arrow.svg'
import lottie from 'lottie-web/build/player/lottie_light'
import { Trans, useTranslation } from 'react-i18next'
import { PageId } from 'constants/pages'
import { setAnswersAction } from 'root-redux/common/common-actions'
import {
  FirebaseRemoteConfigTestGroup,
  LOCALES,
  MEOWZ2_1,
} from 'root-constants'
import { selectVariantCohort } from 'root-redux/common/common-selects'
import { useGetRemoteConfigVariantData } from 'hooks/useGetRemoteConfigVariantData'
import { UrgeCircleProgressVariant } from 'pages/urge/UrgeCircleProgress'
import { StyledUrge as S } from './Urge.styles'

const Component: TDynamicPageComponent = () => {
  const animationContainerRef = useRef<HTMLDivElement>(null!)
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)
  const cohort = useSelector(selectVariantCohort)
  const abTestConfig = useGetRemoteConfigVariantData()

  const shouldShowAbTestScreen = useMemo(() => {
    if (cohort !== MEOWZ2_1 || i18n.language !== LOCALES.EN) return false

    return (
      abTestConfig ===
      FirebaseRemoteConfigTestGroup.ABTEST_WOOBE_1378_B_CIRCLE_SCREEN
    )
  }, [cohort, i18n.language, abTestConfig])

  useEffect(() => {
    if (!animationContainerRef.current) return

    lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData: arrowBounce,
      loop: true,
    })
  }, [])

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      setAnswersAction({
        question: '',
        answers: '',
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )
    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep])

  return shouldShowAbTestScreen ? (
    <UrgeCircleProgressVariant onContinue={handleContinue} />
  ) : (
    <S.UrgeWrapper>
      <S.UrgeContent>
        <S.UrgeImage src={fantasticCat} alt="cat" />
        <S.Title>
          <Trans i18nKey="onboarding.urge.title" components={[<br />]} />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="onboarding.urge.description" components={[<br />]} />
        </S.Subtitle>
        <div ref={animationContainerRef} style={{ width: 30 }} />
        <S.WhiteButton onClick={handleContinue}>
          <S.ButtonText>{t`actions.continue`}</S.ButtonText>
          <Arrow width={24} />
        </S.WhiteButton>
      </S.UrgeContent>
    </S.UrgeWrapper>
  )
}

Component.id = PageId.URGE_2

export const UrgeFantasticVariant2 = Component
