import React from 'react'
import Laptop from 'assets/images/sprite/laptop-cat.svg'
import Home from 'assets/images/sprite/home.svg'
import Think from 'assets/images/sprite/emoji-think.svg'
import Gain from 'assets/images/sprite/gain.svg'
import AngryCatImg from 'assets/images/sprite/angry-cat-emoji.svg'
import Brain from 'assets/images/sprite/brain.svg'
import Cat from 'assets/images/sprite/cat-emoji.svg'
import CatInLove from 'assets/images/sprite/cat-in-love-emoji.svg'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { StyledObtainingList as S } from './ObtainingList.styles'

export const ObtainingList: React.FC = () => (
  <S.Wrapper id={SUBSCRIPTION_SECTION_IDS.obtaining}>
    <S.Title>What’s inside?</S.Title>
    <S.ListsWrapper>
      <S.ListItem>
        <Laptop /> The ultimate cat training guide
      </S.ListItem>
      <S.ListItem>
        <Home /> Handy tips for home adaptation
      </S.ListItem>
      <S.ListItem>
        <Think /> Basic cues every cat must know
      </S.ListItem>
      <S.ListItem>
        <Gain /> Cat target training
      </S.ListItem>

      <S.ListItem>
        <AngryCatImg /> Prevent problem behaviors
      </S.ListItem>
      <S.ListItem>
        <Brain /> Mental stimulation
      </S.ListItem>
      <S.ListItem>
        <Cat /> Guide for understanding your cat’s
        <br />
        body language
      </S.ListItem>
      <S.ListItem>
        <CatInLove /> Lots of useful cat facts
      </S.ListItem>
    </S.ListsWrapper>
  </S.Wrapper>
)
