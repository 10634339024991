import React from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import { SUBSCRIPTION_SECTION_IDS } from 'modules/subscriptions/constants'
import { COMMENTS_V3 } from 'pages/prepare-flow/components/constants'
import { Trans, useTranslation } from 'react-i18next'
import { StyledComments as S } from './Comments.styles'
import { CommentVariant2 } from 'pages/prepare-flow/components/CommentVariant2'

export const CommentsVariant2: React.FC = () => {
  const { t } = useTranslation()

  return (
    <S.CommentsWrapper2 id={SUBSCRIPTION_SECTION_IDS.comments}>
      <S.Wrapper2>
        <S.Header2>
          <S.Title>
            <Trans
              i18nKey="onboarding.comments.title"
              components={[<br />, <strong />]}
            />
          </S.Title>
          <S.HeaderImage2 />
        </S.Header2>
        <Swiper
          slidesPerView="auto"
          spaceBetween={8}
          centeredSlides
          modules={[Pagination]}
          pagination={{
            clickable: true,
          }}
          loop
        >
          {COMMENTS_V3.map(({ title, avatar, text, autor }) => (
            <SwiperSlide key={title}>
              <CommentVariant2 image={avatar} title={title} autor={autor}>
                {t(text)}
              </CommentVariant2>
            </SwiperSlide>
          ))}
        </Swiper>
      </S.Wrapper2>
    </S.CommentsWrapper2>
  )
}
