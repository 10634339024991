import styled, { css } from 'styled-components'
import { Color } from 'root-constants'
import { baseMediaStyles } from 'pages/styles'

const progressValueStyles = css`
  transition: all 0.2s ease-out;
`

export interface IProps {
  isStart: boolean
  isEnd: boolean
  isMeowz3Cohort?: boolean
}

export const StyledProgressBarVariant2 = {
  Wrapper: styled.div`
    ${baseMediaStyles};
    position: absolute;
    top: 64px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
  `,
  ProgressWrapper: styled.div`
    display: flex;
    gap: 2px;
  `,
  Progress: styled.progress<IProps>`
    height: 8px;
    width: 109px;
    appearance: none;
    border: none;
    display: block;
    border-top-left-radius: ${(props) => (props.isStart ? 8 : 0)}px;
    border-bottom-left-radius: ${(props) => (props.isStart ? 8 : 0)}px;
    border-top-right-radius: ${(props) => (props.isEnd ? 8 : 0)}px;
    border-bottom-right-radius: ${(props) => (props.isEnd ? 8 : 0)}px;
    overflow: hidden;
    background-color: ${({ isMeowz3Cohort, color}) => isMeowz3Cohort ? '#ada8a8' : color};

    &::-webkit-progress-bar {
      background-color: ${Color.WHITE};
      opacity: 0.8;
    }

    &::-webkit-progress-value {
      background-color: ${(props) => props.color};
      opacity: 1;
      ${progressValueStyles}
    }

    &::-moz-progress-bar {
      background-color: ${(props) => props.color};
      opacity: 1;
      ${progressValueStyles}
    }

    &::-ms-fill {
      background-color: ${(props) => props.color};
      opacity: 1;
      ${progressValueStyles}
    }
  `,
  ProgressText: styled.p`
    font-size: 13px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 4px;
  `,
}
