import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { TDynamicPageComponent } from 'models/common.model'
import React, { useCallback, useContext, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import catTraining from 'assets/images/training-cat.png'
import { PageId } from 'constants/pages'
import { StyledQuestion as S } from './Questions.styles'
import { saveUserAnswer } from 'root-redux/user/user-actions'

const QUESTION = 'How much time during the day can you spend training?'

const OPTION_ANSWERS = [
  '10-15 minutes',
  '15-20 minutes',
  '20-30 minutes',
  '30+ minutes',
]

export const Component: TDynamicPageComponent = () => {
  const dispatch = useDispatch()

  const { currentPageId, onNextStep, currentPageNumber } =
    useContext(FlowContext)

  const handleContinue = useCallback(
    (value) => {
      if (!currentPageId || !currentPageNumber) return

      dispatch(
        saveUserAnswer({
          question: QUESTION,
          answers: value,
          pageName: currentPageId,
          pageNumber: currentPageNumber,
        }),
      )

      onNextStep()
    },
    [currentPageId, currentPageNumber, dispatch, onNextStep],
  )

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.RADIO,
      onChange: (value: string) => {
        setTimeout(() => {
          handleContinue(value)
        }, 500)
      },
    }),
    [currentPageId],
  )

  return (
    <S.Wrapper4>
      <S.Content4>
        <S.Title7>{QUESTION}</S.Title7>
        <S.QuestionImage src={catTraining} alt="training cat" />
        <S.OptionsContainer4 data-is-multiple>
          {OPTION_ANSWERS.map((item) => (
            <S.Option3 key={item} {...optionProps} value={item}>
              <S.QuestionButton3>{item}</S.QuestionButton3>
            </S.Option3>
          ))}
        </S.OptionsContainer4>
      </S.Content4>
    </S.Wrapper4>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.CAT_TIME_2

export const CatTraining = Component
