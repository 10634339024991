import { FlowContext } from 'components/FlowContext'
import { OptionType } from 'components/option/Option'
import { StyledOption } from 'components/option/Option.styles'
import { StyledQuestionButton } from 'components/buttons/QuestionButtons.styles'
import { TDynamicPageComponent } from 'models/common.model'
import { GreenArrowButton } from 'components/buttons/arrow-button/GreenArrowButton'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PageId } from 'constants/pages'
import { saveUserAnswer } from 'root-redux/user/user-actions'
import { selectAnswers } from 'root-redux/common/common-selects'
import { getSelectedAnswers } from 'helpers/getSelectedAnswers'
import { GOALS_OPTIONS_VALUES } from 'constants/options-constants'
import { StyledQuestion as S } from './Questions.styles'

const QUESTION = 'What is your main goal?'

const OPTIONS = [
  {
    title: 'Solve behavior problems',
    value: GOALS_OPTIONS_VALUES.BEHAVIOUR_PROBLEMS,
  },
  {
    title: 'Learn new tricks and cues',
    value: GOALS_OPTIONS_VALUES.NEW_TRICKS,
  },
  {
    title: 'Understand cat psychology',
    value: GOALS_OPTIONS_VALUES.CAT_PSYCHOLOGY,
  },
  {
    title: 'Solve separation anxiety',
    value: GOALS_OPTIONS_VALUES.HOME_ADAPTATION,
  },
  {
    title: 'Make home adaptation easier',
    value: GOALS_OPTIONS_VALUES.SEPARATION_ANXIETY,
  },
  {
    title: 'Become the best cat parent ever',
    value: GOALS_OPTIONS_VALUES.BECOME_BEST_PARENT,
  },
  { title: 'Other', value: GOALS_OPTIONS_VALUES.OTHER },
]

export const Component: TDynamicPageComponent = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { onNextStep, currentPageId, currentPageNumber } =
    useContext(FlowContext)
  const savedAnswers = useSelector(selectAnswers)
  const selectedAnswers = useMemo(
    () => getSelectedAnswers(savedAnswers, currentPageId),
    [savedAnswers, currentPageId],
  )
  const [answers, setAnswers] = useState<string[]>([])

  const optionProps = useMemo(
    () => ({
      ...(currentPageId && { name: currentPageId }),
      type: OptionType.CHECKBOX,
      onChange: (value: string, isChecked: boolean) => {
        if (isChecked) {
          setAnswers([...answers, value])
        } else {
          const newValues = answers.filter((item) => item !== value)
          setAnswers(newValues)
        }
      },
    }),
    [answers, currentPageId],
  )

  const handleContinue = useCallback(() => {
    if (!currentPageId || !currentPageNumber) return

    dispatch(
      saveUserAnswer({
        question: QUESTION,
        answers,
        pageName: currentPageId,
        pageNumber: currentPageNumber,
      }),
    )

    onNextStep()
  }, [currentPageId, currentPageNumber, dispatch, onNextStep, answers])

  useEffect(() => {
    setAnswers(selectedAnswers as string[])
  }, [selectedAnswers])

  return (
    <S.Wrapper2>
      <S.Content>
        <S.Title2>What is your main goal?</S.Title2>
        <S.SubtitleText>
          {t`onboarding.common.chooseAllThatApply`}:
        </S.SubtitleText>
        <StyledOption.OptionsContainer>
          {OPTIONS.map((option) => (
            <S.Option
              key={option.value}
              {...optionProps}
              value={option.value}
              checked={answers.includes(option.value)}
            >
              <StyledQuestionButton.Wrapper data-has-check-icon>
                {option.title}
              </StyledQuestionButton.Wrapper>
            </S.Option>
          ))}
        </StyledOption.OptionsContainer>
        <S.StickyBlockWrapper>
          <GreenArrowButton
            isButtonDisabled={!answers.length}
            onClick={handleContinue}
          />
        </S.StickyBlockWrapper>
      </S.Content>
    </S.Wrapper2>
  )
}

Component.progressBar = 'controlled'
Component.hasHeader = true
Component.id = PageId.MAIN_GOAL

export const MainGoalQuestion = Component
