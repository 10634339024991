import FailedFaceSvg from 'assets/images/sprite/cat-failed.svg'
import { Button } from 'components/buttons/Button'
import React from 'react'

import { Trans, useTranslation } from 'react-i18next'
import { StyledAccountCreationFailed as S } from './AccountCreationFailed.syles'

type TProps = {
  onClose: () => void
}

export const AccountCreationFailed: React.FC<TProps> = ({ onClose }) => {
  const { t } = useTranslation()

  return (
    <S.Wrapper>
      <S.Container>
        <FailedFaceSvg height={110} />
        <S.Title>
          <Trans i18nKey="account.creationFailed" components={[<br />]} />
        </S.Title>
        <S.Subtitle>{t`account.createByOtherMethod`}</S.Subtitle>
      </S.Container>
      <Button onClick={onClose}>{t`payment.tryAgain`}</Button>
    </S.Wrapper>
  )
}
