import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js'
import { paymentApi } from 'api'
import { createProductId } from 'helpers/createProductId'
import { PaymentMethod, PaymentSystem } from 'modules/payment/constants'
import { logSuccessfulPayment } from 'modules/payment/helpers/logSuccessfulPayment'
import {
  setErrorAction,
  setIsUpsellPaidAction,
  startFetching,
  stopFetching,
} from 'root-redux/common/common-actions'
import { setPaymentSystemAction } from 'root-redux/payment/payment-actions'
import {
  selectCurrency,
  selectFullPrice,
  selectPeriodName,
  selectPeriodQuantity,
  selectPlanId,
  selectProductPrice,
  selectStripeAccount,
  selectTrialFullPrice,
} from 'root-redux/payment/payment-selects'
import { selectEmail, selectUUID } from 'root-redux/user/user-selects'
import { eventLogger, ScreenName } from 'services/eventLogger-service'

const MODULE_NAME = 'UPSELL'

export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`

export const makeUpsellAction =
  (screenName: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
    createPaymentResFromDigitalWallet?: PaymentRequestPaymentMethodEvent,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const currentPrice = selectFullPrice(state)
    const productPrice = selectProductPrice(state)
    const trialPeriodPrice = selectTrialFullPrice(state)
    const periodName = selectPeriodName(state)
    const periodQuantity = selectPeriodQuantity(state)
    const email = selectEmail(state)
    const stripeAccount = selectStripeAccount(state)
    const planId = selectPlanId(state)
    const currency = selectCurrency(state)

    dispatch(startFetching(MAKE_UPSELL))

    const productId = createProductId({
      periodName,
      periodQuantity,
      price: currentPrice,
    })

    const paymentMethod =
      createPaymentResFromDigitalWallet?.paymentMethod?.card?.wallet?.type

    eventLogger.logPurchaseStarted({
      productId,
      productName: ScreenName.UPSELL,
      priceDetails: {
        price: trialPeriodPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      paymentMethod,
      email,
      screenName,
      stripeAccount,
    })
    window.ttq.identify({ email })
    window.ttq.track('AddPaymentInfo')
    window.snaptr('track', 'ADD_BILLING')

    const upsellResponse = await paymentApi.createOneTimePayment({
      planId,
      uuid,
    })

    if (upsellResponse.success && upsellResponse.data) {
      logSuccessfulPayment({
        price: trialPeriodPrice,
        subscriptionId: upsellResponse.data.inapp.payment_id,
        paymentMethod,
        productId,
        productName: ScreenName.UPSELL,
        trialPrice: trialPeriodPrice,
        uuid,
        periodName,
        periodQuantity,
        email,
        screenName,
        isUpsell: true,
        productPrice,
        stripeAccount,
        currency,
      })

      dispatch(setPaymentSystemAction(PaymentSystem.STRIPE))
      dispatch(setIsUpsellPaidAction(true))
      dispatch(stopFetching(MAKE_UPSELL))
      return
    }

    const { error, decline_code: declineCode } = upsellResponse.data

    eventLogger.logPurchaseFailed({
      productId,
      productName: ScreenName.UPSELL,
      priceDetails: {
        price: currentPrice,
        trial: !!trialPeriodPrice,
        currency,
      },
      error: { type: error, declineCode },
      paymentMethod: PaymentMethod.CREDIT_CARD,
      screenName,
      stripeAccount,
      email,
    })

    dispatch(setErrorAction('Payment failed'))
    dispatch(stopFetching(MAKE_UPSELL))
  }
