import React, { useEffect, useRef } from 'react'
import {
  STICKY_HEADER,
  SUBSCRIPTION_SECTION_IDS,
} from 'modules/subscriptions/constants'
import { useTimerForTarget } from 'modules/subscriptions/hooks/useTimerForTarget'
import { StyledBanner as S } from './StickyBanner.styles'
import { getScrollLevel } from 'helpers/getScrollLevel'
import { eventLogger, Events, ScreenName } from 'services/eventLogger-service'
import { useSelector } from 'react-redux'
import { selectEmail } from 'root-redux/user/user-selects'

type TProp = {
  screenName?: string
}

const subscriptionButtonsIds = [
  SUBSCRIPTION_SECTION_IDS.comments,
  SUBSCRIPTION_SECTION_IDS.startMyPlanButton,
  SUBSCRIPTION_SECTION_IDS.chooseMyPlan,
  SUBSCRIPTION_SECTION_IDS.obtaining,
]
const TEN_MINUTES_IN_SECONDS = 600

export const StickyBanner: React.FC<TProp> = ({
  screenName = ScreenName.SALES,
}) => {
  const timerElementRef = useRef<HTMLSpanElement>(null)
  const email = useSelector(selectEmail)

  useEffect(() => {
    sessionStorage.removeItem(Events.VIEW_PRICE)

    const blocks = subscriptionButtonsIds.map((item) =>
      document.getElementById(item),
    )

    const observer = new IntersectionObserver(
      (entries) => {
        const el = document.getElementById(STICKY_HEADER)

        if (!el) {
          return
        }

        entries.forEach((entry) => {
          if (
            [
              SUBSCRIPTION_SECTION_IDS.comments,
              SUBSCRIPTION_SECTION_IDS.startMyPlanButton,
            ].includes(entry.target.id)
          ) {
            if (entry.isIntersecting && el.style.display !== 'none') {
              el.style.display = 'block'
              el.style.position = 'fixed'
            }
          }

          if (entry.target.id === SUBSCRIPTION_SECTION_IDS.chooseMyPlan) {
            if (entry.isIntersecting && el.style.position !== 'relative') {
              el.style.position = 'relative'
            }
          }

          if (entry.target.id === SUBSCRIPTION_SECTION_IDS.obtaining) {
            if (entry.isIntersecting && el.style.position === 'relative') {
              el.style.position = 'fixed'
            }
          }

          if (
            SUBSCRIPTION_SECTION_IDS.startMyPlanButton.includes(entry.target.id)
          ) {
            const viewPriceEventSent = sessionStorage.getItem(Events.VIEW_PRICE)

            if (viewPriceEventSent) return

            const scrollLvl = getScrollLevel()

            if (entry.isIntersecting) {
              eventLogger.logSubscriptionsViewPrice({
                method: 'scrolled',
                email,
                scrollLvl,
                screenName,
              })
              sessionStorage.setItem(Events.VIEW_PRICE, 'true')
            }
          }
        })
      },
      {
        rootMargin: '0px',
        threshold: 0.3,
      },
    )

    blocks.forEach((block) => block && observer?.observe(block as Element))
  }, [])

  useTimerForTarget(timerElementRef, TEN_MINUTES_IN_SECONDS)

  return (
    <S.HeaderWrapper id={STICKY_HEADER}>
      <S.ContentWrapper>
        Personal offer expires&nbsp;
        <strong ref={timerElementRef} />
        &nbsp;mins
      </S.ContentWrapper>
    </S.HeaderWrapper>
  )
}
